import { useState } from "react";
import useApi from "../../../services/useApi";

const useEstados = () => {
  const { services } = useApi();

  const [stateEstados, setStateEstados] = useState({
    solicitudes: [],
  });

  const loadEstados = async () => {
    const { execute, data } = await services("GET", "v1/states");
    if (execute) {
      setStateEstados({ ...stateEstados, solicitudes: data.data });
    }
  };

  return {
    loadEstados,
    stateEstados,
    setStateEstados,
  };
};

export default useEstados;
