
import Backdrop from '@mui/material/Backdrop';



export const LoaderComponent = () => {
    return (
        <Backdrop
            sx={{ color: 'fff', zIndex: (theme) => theme.zIndex.drawer + 5000 }}
            open={true}
        >
            <span className="loader"></span>
        </Backdrop>

    )
}
