import { useEffect } from "react";
import DataTable from "../../general/GlobalComponents/DataTable";
import useUsuarios from "../customHooks/useUsuarios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  onlyLetter,
  onlyNumberPhone,
  alfaNumericoSinAcentos,
} from "../../../utils/inputRules";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Add, Edit, Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import useTitulos from "../customHooks/useTitulos";
import useCamaras from "../customHooks/useCamaras";
import useComisiones from "../customHooks/useComisiones";
import useEstados from "../customHooks/useEstados";
import useRol from "../customHooks/useRol";
import useAlerts from "../../../services/useAlerts";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import { useMediaQuery } from '@mui/material';

const Usuarios = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: isMobile ? "90%" : "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
  };

  const {
    stateUsuarios,
    stateModal,
    verSolicitudes,
    handleShowModal,
    handleClose,
    handleCurp,
    handleSexo,
    handleNombre,
    handleApePaterno,
    handleApeMaterno,
    handleEmail,
    handleEmailConfirm,
    handleTelefono,
    handleRol,
    handleEstado,
    handleTitulo,
    handleProcedencia,
    handleShowModalEdit,
    stateModalEdit,
    validarDatos,
    limpiarFormulario,
    stateValidate,
    loadData,
    valorRadio,
    deleteUser,
    disableInput,
    setCurrentUsuarios,
    valorRadioEdit,
    loadDataEdit,
    handleClickBackdrop,
    editarUsuario,
    invitadoSelected,
    password,
    modalPassword,
    setModalPassword,
  } = useUsuarios();

  const { stateCamaras, loadCamaras } = useCamaras();

  const { loadComisiones, stateComisiones } = useComisiones();

  const { stateTitulos, loadTitulos } = useTitulos();

  const { loadEstados, stateEstados } = useEstados();

  const { loadRoles } = useRol();

  const { showLoader } = useAlerts();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      showLoader();
      verSolicitudes();
      loadTitulos();
      loadCamaras();
      loadComisiones();
      loadEstados();
      loadRoles();
      isMounted = false;
    }

    return () => {
      isMounted = false;
    };
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  const columns = [
    {
      name: "nombre",
      label: "Nombre",

      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "apellidoPaterno",
      label: "Apellido Paterno",
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "apeMaterno",
      label: "Apellido Materno",
      options: {
        customBodyRender: (value) => {
          // let apeMaterno = stateUsuarios.usuarios[value].apellidoMaterno;
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "estado",
      label: "Estado",
      options: {
        customBodyRender: (value) => {
          // let estado = stateUsuarios.usuarios[value].estado.estado;
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "rol",
      label: "Rol",
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
      // options: {
      //   customBodyRenderLite: (value) => {
      //     // let rol = stateUsuarios.usuarios[value].rol.rol;
      //     return <div>{value}</div>;
      //   },
      // },
    },
    {
      name: "catalago",
      label: "Procedencia",
      options: {
        customBodyRender: (value) => <div>{value}</div>,
        // let catalago = stateUsuarios.usuarios[value].catalago?.camara;
        // let catalagoC = stateUsuarios.usuarios[value].catalago?.comision;
        // if (catalago) {
        //   let catalago =
        //     stateUsuarios.usuarios[value].catalago?.camara.nombre;
        //   return <div>Cámara: {catalago}</div>;
        // } else if (catalagoC) {
        //   catalago = stateUsuarios.usuarios[value].catalago?.comision.nombre;
        //   return <div>Comisión: {catalago}</div>;
        // } else {
        //   return <div className="text-center">N/A</div>;
        // }
        // },
      },
    },
    {
      name: "telefono",
      label: "Teléfono",
      options: {
        customBodyRender: (value) => {
          // let telefono = stateUsuarios.usuarios[value].telefono;
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "correo",
      label: "Correo",
      options: {
        customBodyRender: (value) => {
          // let correo = stateUsuarios.usuarios[value].correo;
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "status",
      label: "Estatus",
      options: {
        download: false,
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "acciones",
      label: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (value) => {
          let statusUser = stateUsuarios.usuarios[value].status;

          return (
            <>
              <div className="d-flex justify-content-center">
                <Tooltip
                  disableFocusListener
                  title={
                    statusUser === "INACTIVO"
                      ? "Inhabilitar usuario"
                      : "Habilitar usuario"
                  }
                >
                  <IconButton
                    aria-label="Inhabilitar/Habilitar"
                    onClick={() => {
                      deleteUser(stateUsuarios.usuarios[value].id, statusUser);
                    }}
                  >
                    {statusUser === "ACTIVO" ? (
                      <CheckSharpIcon color="primary" />
                    ) : (
                      <ClearSharpIcon color="error" />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip disableFocusListener title="Editar usuario">
                  <IconButton
                    disabled={statusUser === "ACTIVO" ? false : true}
                    className={statusUser === "ACTIVO" ? null : "opacity-25"}
                    aria-label="Editar"
                    onClick={() => {
                      setCurrentUsuarios(
                        stateUsuarios.usuarios[value].curp,
                        stateUsuarios.usuarios[value].sexo,
                        stateUsuarios.usuarios[value].nombre.toUpperCase(),
                        stateUsuarios.usuarios[
                          value
                        ].apellidoPaterno.toUpperCase(),
                        stateUsuarios.usuarios[value].apeMaterno.toUpperCase(),
                        stateUsuarios.usuarios[value].rolId,
                        stateUsuarios.usuarios[value].tituloId,
                        stateUsuarios.usuarios[value].correo,
                        stateUsuarios.usuarios[value].telefono,
                        stateUsuarios.usuarios[value].catalago,
                        stateUsuarios.usuarios[value].id,
                        stateUsuarios.usuarios[value].catalagoId,
                        stateUsuarios.usuarios[value].catalagoOrigen
                      );
                    }}
                  >
                    <Edit color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      {modalPassword ? (
        <article className="modal-password">
          <div className="content-password d-flex flex-column">
            <h3 className="fw-bold pb-3">Usuario registrado exitosamente</h3>
            <p className="text-center">
              La contraseña del usuario es:
              <br />
              <strong className="fs-5">{password}</strong>
            </p>

            <br />
            <button
              className="cta--rojo p-2 border-0"
              onClick={() => setModalPassword(false)}
            >
              Aceptar
            </button>
          </div>
        </article>
      ) : null}

      {/* Tabla y botón */}
      <Grid className="mt-5 mb-2">
        <div className="container-fluid px-5">
          <Grid item xs={12} md={6} className="text-right-custom">
            <LoadingButton
              className="background-colorUI"
              type="submit"
              variant="contained"
              endIcon={<Add />}
              loading={false}
              sx={{
                mt: 1,
                mb: 2,
                alignItems: "center",
              }}
              onClick={() => {
                handleShowModal(true);
              }}
            >
              Añadir Usuario
            </LoadingButton>
          </Grid>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <DataTable
              title={
                <span>
                  <h5>Usuarios</h5>
                </span>
              }
              data={stateUsuarios.usuarios}
              columns={columns}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className="d-lg-none" style={{ margin: "150px" }}></Grid>

      {/* AÑADIR USUARIO */}
      {stateModal.open ? (
        <Modal
          open={stateModal.open}
          onClose={handleClose}
          disableBackdropClick
          BackdropProps={{
            onClick: handleClickBackdrop,
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <Box
              className="p-2 p-md-4 col-10 col-md-6 pt-4"
              sx={styleModal}
              style={{
                overflowY: "auto",
                maxHeight: "90vh",
              }}
            >
              <Typography id="modal-modal-title" component="h2">
                <h4
                  className="text-center fw-bold"
                  style={{ color: "#a70631", fontSize: "25px" }}
                >
                  Añadir Usuario
                </h4>
              </Typography>
              <IconButton
                className={
                  "border-0 position-absolute p-1 top-0 end-0 m-2 rounded-circle"
                }
                onClick={() => handleClose()}
              >
                <Close />
              </IconButton>
              <Typography id="modal-modal-description" sx={{ mt: 1, mr: 7 }}>
                <Grid container columns={12} spacing={2} m={1} className="mr-4">
                  <Grid item xs={12} md={7}>
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      fullWidth
                      size="small"
                      id="curp"
                      label="CURP"
                      inputProps={{ maxLength: 18 }}
                      type="text"
                      error={
                        stateModal.curp === "" && stateValidate ? true : false
                      }
                      value={stateModal.curp}
                      variant="filled"
                      onChange={(newValue) => handleCurp(newValue)}
                      // onChange={handleCurp}
                      onKeyPress={alfaNumericoSinAcentos}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      fullWidth
                      size="small"
                      id="sexo"
                      label="Sexo"
                      select
                      error={
                        stateModal.sexo === "" && stateValidate ? true : false
                      }
                      value={stateModal.sexo}
                      variant="filled"
                      onChange={handleSexo}
                    >
                      <MenuItem value={"M"}>Femenino</MenuItem>
                      <MenuItem value={"H"}>Masculino</MenuItem>
                      <MenuItem value={"X"}>No binario </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container columns={12} spacing={2} m={1}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      margin="normal"
                      InputProps={{ disableUnderline: true }}
                      size="small"
                      fullWidth
                      id="nombre"
                      label="Nombre(s)"
                      type="text"
                      error={
                        stateModal.nombre === "" && stateValidate ? true : false
                      }
                      value={stateModal.nombre}
                      variant="filled"
                      onChange={handleNombre}
                      onKeyPress={(event) => {
                        onlyLetter(event, 55);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      margin="normal"
                      InputProps={{ disableUnderline: true }}
                      size="small"
                      fullWidth
                      id="apePaterno"
                      label="Apellido Paterno"
                      type="text"
                      error={
                        stateModal.apePaterno === "" && stateValidate
                          ? true
                          : false
                      }
                      value={stateModal.apePaterno}
                      variant="filled"
                      onChange={handleApePaterno}
                      onKeyPress={(event) => {
                        onlyLetter(event, 55);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      margin="normal"
                      InputProps={{ disableUnderline: true }}
                      fullWidth
                      size="small"
                      id="apeMaterno"
                      label="Apellido Materno"
                      type="text"
                      error={
                        stateModal.apeMaterno === "" && stateValidate
                          ? true
                          : false
                      }
                      value={stateModal.apeMaterno}
                      variant="filled"
                      onChange={handleApeMaterno}
                      onKeyPress={(event) => {
                        onlyLetter(event, 55);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container columns={12} spacing={2} m={1}>
                  {/* ROL DE USUARIO */}
                  <Grid item xs={12} md={4}>
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      size="small"
                      fullWidth
                      id="rol"
                      label="Rol"
                      select
                      error={
                        stateModal.rolUsuario === 0 && stateValidate
                          ? true
                          : false
                      }
                      value={stateModal.rolUsuario}
                      variant="filled"
                      onChange={handleRol}
                    >
                      {/* <MenuItem value={1}>Administrador</MenuItem> */}
                      <MenuItem value={2}>Moderador</MenuItem>
                      <MenuItem value={3}>Participante</MenuItem>
                      <MenuItem value={4}>Invitado</MenuItem>
                      {/* {stateRoles.solicitudes.map((rol) => (
                        <MenuItem value={rol.id}>{rol.rol}</MenuItem>
                      ))} */}
                    </TextField>
                  </Grid>
                  {/* TITULO */}
                  <Grid item xs={12} md={4}>
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      size="small"
                      fullWidth
                      id="titulo"
                      label="Título"
                      select
                      error={
                        stateModal.tituloUsuario === "" && stateValidate
                          ? true
                          : false
                      }
                      value={stateModal.tituloUsuario}
                      variant="filled"
                      onChange={handleTitulo}
                    >
                      {stateTitulos.solicitudes.map((item) => (
                        <MenuItem value={item.id}>{item.nombre}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {/* ESTADO */}
                  <Grid item xs={12} md={4}>
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      size="small"
                      fullWidth
                      id="estado"
                      label="Estado"
                      select
                      error={
                        stateModal.estadoUsuario === "" && stateValidate
                          ? true
                          : false
                      }
                      value={stateModal.estadoUsuario}
                      variant="filled"
                      onChange={handleEstado}
                    >
                      {stateEstados.solicitudes.map((estado) => (
                        <MenuItem value={estado.id}>{estado.estado}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container columns={12} spacing={2} m={1}>
                  {/* TELEFONO */}
                  <Grid item xs={12} md={4}>
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      id="telefono"
                      fullWidth
                      label="Celular/Teléfono"
                      error={
                        stateModal.telefono === "" && stateValidate
                          ? true
                          : false
                      }
                      value={stateModal.telefono}
                      variant="filled"
                      onChange={handleTelefono}
                      onKeyPress={onlyNumberPhone}
                    />
                  </Grid>
                  {/* CORREO ELECTRONICO */}
                  <Grid item xs={12} md={4}>
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      id="email"
                      fullWidth
                      label="Correo Electrónico"
                      error={
                        stateModal.email === "" && stateValidate ? true : false
                      }
                      inputProps={{ maxLength: 254 }}
                      value={stateModal.email}
                      variant="filled"
                      onChange={handleEmail}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      fullWidth
                      id="emailConfirm"
                      label="Confirmar Correo Electrónico"
                      error={
                        stateModal.emailConfirm === "" && stateValidate
                          ? true
                          : false
                      }
                      value={stateModal.emailConfirm}
                      variant="filled"
                      onChange={handleEmailConfirm}
                    />
                  </Grid>
                </Grid>
                <Grid container columns={12} spacing={2} m={1}>
                  {/* PROCEDENCIA */}
                  <Grid item xs={12} md={4} className="mr-4">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        onChange={(e) => {
                          valorRadio(e.target.value);
                        }}
                        value="chamber"
                        control={<Radio />}
                        label="Cámaras"
                        disabled={invitadoSelected}
                      />
                      <FormControlLabel
                        onChange={(e) => {
                          valorRadio(e.target.value);
                        }}
                        value="commission"
                        control={<Radio />}
                        label="Comisiones"
                        disabled={invitadoSelected}
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12} md={4} className="mr-4">
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      fullWidth
                      size="small"
                      id="procedencia"
                      label="Procedencia"
                      select
                      value={stateModal.procedenciaUsuario}
                      variant="filled"
                      onChange={handleProcedencia}
                      disabled={disableInput || invitadoSelected}
                    >
                      {/* Cargando datos */}
                      {loadData
                        ? stateCamaras.solicitudes.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.nombre}
                            </MenuItem>
                          ))
                        : loadData !== null
                        ? stateComisiones.solicitudes.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.nombre}
                            </MenuItem>
                          ))
                        : null}
                    </TextField>
                  </Grid>
                </Grid>

                <Grid container columns={12} spacing={2} m={1}>
                  <Grid item xs={6} md={6} className="text-right-custom">
                    <button
                      className="cta cta--gris cta--medium mt-4"
                      style={{ width: "199px" }}
                      onClick={() => {
                        handleShowModal(false);
                        limpiarFormulario();
                      }}
                    >
                      Cancelar
                    </button>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <button
                      type="submit"
                      variant="contained"
                      className="cta cta--rojo cta--medium mt-4"
                      onClick={() => {
                        validarDatos();
                      }}
                    >
                      Guardar Cambios
                    </button>
                  </Grid>
                </Grid>
              </Typography>
            </Box>
          </div>
        </Modal>
      ) : null}

      {/* EDITAR USUARIO */}
      {stateModalEdit.open ? (
        <Modal
          open={stateModalEdit.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <Box
              className="p-2 p-md-4 col-10 col-md-6 pt-4"
              sx={styleModal}
              style={{ overflowY: "auto", maxHeight: "90vh" }}
            >
              <Typography id="modal-modal-title" component="h2">
                <h4
                  className="text-center fw-bold"
                  style={{ color: "#a70631", fontSize: "25px" }}
                >
                  Editar usuario
                </h4>
              </Typography>
              <IconButton
                className={
                  "border-0 position-absolute p-1 top-0 end-0 m-2 rounded-circle"
                }
                onClick={() => handleClose()}
              >
                <Close />
              </IconButton>
              <Typography id="modal-modal-description" sx={{ mt: 1, mr: 7 }}>
                <Grid container columns={12} spacing={2} m={1} className="mr-4">
                  <Grid item xs={12} md={7}>
                    {/* CURP EDITAR */}
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      fullWidth
                      size="small"
                      id="curp"
                      label="CURP"
                      inputProps={{ maxLength: 18 }}
                      type="text"
                      error={
                        stateModal.curp === "" && stateValidate ? true : false
                      }
                      value={stateModal.curp}
                      variant="filled"
                      onChange={(newValue) => handleCurp(newValue)}
                      // onChange={handleCurp}
                      onKeyPress={alfaNumericoSinAcentos}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    {/* GENERO EDITAR */}
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      fullWidth
                      size="small"
                      id="sexo"
                      label="Sexo"
                      select
                      error={
                        stateModal.sexo === "" && stateValidate ? true : false
                      }
                      value={stateModal.sexo}
                      variant="filled"
                      onChange={handleSexo}
                    >
                      <MenuItem value={"M"}>Femenino</MenuItem>
                      <MenuItem value={"H"}>Masculino</MenuItem>
                      <MenuItem value={"X"}>No binario </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container columns={12} spacing={2} m={1}>
                  {/* NOMBRE EDITAR */}
                  <Grid item xs={12} md={4}>
                    <TextField
                      margin="normal"
                      InputProps={{ disableUnderline: true }}
                      size="small"
                      fullWidth
                      id="nombre"
                      label="Nombre(s)"
                      type="text"
                      error={
                        stateModal.nombre === "" && stateValidate ? true : false
                      }
                      value={stateModal.nombre}
                      variant="filled"
                      onChange={handleNombre}
                      onKeyPress={(event) => {
                        onlyLetter(event, 55);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {/* APELLIDO PATERNO EDITAR */}
                    <TextField
                      margin="normal"
                      InputProps={{ disableUnderline: true }}
                      size="small"
                      fullWidth
                      id="apePaterno"
                      label="Apellido Paterno"
                      type="text"
                      error={
                        stateModal.apePaterno === "" && stateValidate
                          ? true
                          : false
                      }
                      value={stateModal.apePaterno}
                      variant="filled"
                      onChange={handleApePaterno}
                      onKeyPress={(event) => {
                        onlyLetter(event, 55);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {/* APELLIDO MATERNO EDITAR */}
                    <TextField
                      margin="normal"
                      InputProps={{ disableUnderline: true }}
                      fullWidth
                      size="small"
                      id="apeMaterno"
                      label="Apellido Materno"
                      type="text"
                      error={
                        stateModal.apeMaterno === "" && stateValidate
                          ? true
                          : false
                      }
                      value={stateModal.apeMaterno}
                      variant="filled"
                      onChange={handleApeMaterno}
                      onKeyPress={(event) => {
                        onlyLetter(event, 55);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container columns={12} spacing={2} m={1}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      size="small"
                      fullWidth
                      id="rol"
                      label="Rol"
                      select
                      error={
                        stateModal.rolUsuario === 0 && stateValidate
                          ? true
                          : false
                      }
                      value={stateModal.rolUsuario}
                      variant="filled"
                      onChange={handleRol}
                    >
                      {/* <MenuItem value={1}>Administrador</MenuItem> */}
                      <MenuItem value={2}>Moderador</MenuItem>
                      <MenuItem value={3}>Participante</MenuItem>
                      <MenuItem value={4}>Invitado</MenuItem>
                      {/* {stateRoles.solicitudes.map((rol) => (
                        <MenuItem value={rol.id}>{rol.rol}</MenuItem>
                      ))} */}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {/* TITULO DEL USUARIO EDITAR */}
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      size="small"
                      fullWidth
                      id="titulo"
                      label="Título"
                      select
                      error={
                        stateModal.tituloUsuario === "" && stateValidate
                          ? true
                          : false
                      }
                      value={stateModal.tituloUsuario}
                      variant="filled"
                      onChange={handleTitulo}
                    >
                      {stateTitulos.solicitudes.map((item) => (
                        <MenuItem value={item.id}>{item.nombre}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {/* TELEFONO DEL USUARIO EDITAR*/}
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      id="telefono"
                      fullWidth
                      label="Celular/Teléfono"
                      error={
                        stateModal.telefono === "" && stateValidate
                          ? true
                          : false
                      }
                      value={stateModal.telefono}
                      variant="filled"
                      onChange={handleTelefono}
                      onKeyPress={onlyNumberPhone}
                    />
                  </Grid>
                </Grid>

                <Grid container columns={12} spacing={2} m={1}>
                  {/* CORREO DEL USUARIO EDITAR */}
                  <Grid item xs={12} md={4}>
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      id="email"
                      fullWidth
                      label="Correo Electrónico"
                      error={
                        stateModal.email === "" && stateValidate ? true : false
                      }
                      inputProps={{ maxLength: 254 }}
                      value={stateModal.email}
                      variant="filled"
                      onChange={handleEmail}
                    />
                  </Grid>
                  {/* PROCEDENCIA DEL USUARIO EDITAR */}
                  <Grid item xs={12} md={4} className="mr-4">
                    {/* RADIO BUTTON DE PROCEDENCIA DE USUARIO EDITAR*/}
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      defaultValue={stateModal.origen}
                    >
                      <FormControlLabel
                        onChange={(e) => {
                          valorRadioEdit(e.target.value);
                        }}
                        value="chamber"
                        control={<Radio />}
                        label="Cámaras"
                        disabled={stateModal.rolUsuario === 4}
                      />
                      <FormControlLabel
                        onChange={(e) => {
                          valorRadioEdit(e.target.value);
                        }}
                        value="commission"
                        control={<Radio />}
                        label="Comisiones"
                        disabled={stateModal.rolUsuario === 4}
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12} md={4} className="mr-4">
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      fullWidth
                      size="small"
                      id="procedencia"
                      label="Procedencia"
                      select
                      value={stateModal.procedenciaUsuario}
                      variant="filled"
                      onChange={handleProcedencia}
                      disabled={stateModal.rolUsuario === 4}
                    >
                      {/* Cargando datos */}
                      {loadDataEdit
                        ? stateCamaras.solicitudes.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.nombre}
                            </MenuItem>
                          ))
                        : loadDataEdit !== null
                        ? stateComisiones.solicitudes.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.nombre}
                            </MenuItem>
                          ))
                        : null}
                    </TextField>
                  </Grid>
                </Grid>

                <Grid container columns={12} spacing={2} m={1}>
                  <Grid item xs={6} md={6} className="text-right-custom">
                    <button
                      className="cta cta--gris cta--medium mt-4"
                      style={{ width: "199px" }}
                      onClick={() => {
                        handleShowModalEdit(false);
                        limpiarFormulario();
                      }}
                    >
                      Cancelar
                    </button>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <button
                      type="submit"
                      variant="contained"
                      className="cta cta--rojo cta--medium mt-4"
                      onClick={() => {
                        editarUsuario();
                      }}
                    >
                      Guardar Cambios
                    </button>
                  </Grid>
                </Grid>
              </Typography>
            </Box>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default Usuarios;
