import { Navigate } from "react-router-dom";

// Este componente verifica si el usuario está autenticado y tiene los permisos adecuados
const PrivateRoutes = ({ children, allowedRoles }) => {
  const token = localStorage.getItem("token");
  const rolUser = localStorage.getItem("rol");

  // Verificar si el usuario está autenticado
  if (!token) {
    return <Navigate to="/login" replace={true} />;
  }

  // Verificar si el usuario tiene los permisos adecuados - LUIS ADRIAN
  if (allowedRoles && rolUser && !allowedRoles.includes(rolUser)) {
    return <Navigate to="/" replace={true} />;
  }

  return children;
};

export default PrivateRoutes;
