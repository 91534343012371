import { useState } from "react";
import useApi from "../../../services/useApi";
import useAlerts from "../../../services/useAlerts";

const UseMiUsuario = () => {
  const nombre = localStorage.getItem("nombre");
  const apellidoPaterno = localStorage.getItem("apellidoPaterno");
  const apellidoMaterno = localStorage.getItem("apellidoMaterno");
  const id = localStorage.getItem("id");

  const [stateModal, setStateModal] = useState({
    open: false,
    openEdit: false,
    actual: "",
    password: "",
    confirmNueva: "",
  });

  const handleShowModal = (action) => {
    setStateModal({
      ...stateModal,
      open: action,
      actual: "",
      password: "",
      confirmNueva: "",
    });
  };

  const handleActual = (e) => {
    setStateModal({ ...stateModal, actual: e.target.value });
  };

  const handleNueva = (e) => {
    setStateModal({ ...stateModal, password: e.target.value });
  };

  const handleConfirmNueva = (e) => {
    setStateModal({ ...stateModal, confirmNueva: e.target.value });
  };

  const { services } = useApi();

  const { showAlertConfirm, showToast, showLoader } = useAlerts();

  const validarChange = async () => {
    const { password, confirmNueva } = stateModal;

    if (password.trim() !== "" && confirmNueva.trim() !== "") {
      //&& actual.trim() != ""

      if (password.length >= 8 && password.length <= 12) {
        if (password.trim() === confirmNueva.trim()) {
          showLoader();
          const { execute } = await services(
            "PUT",
            `v1/users/update-password/${id}`,
            { password }
          );

          if (execute) {
            handleShowModal(false);
            limpiar();
            showAlertConfirm(
              "success",
              "Operación exitosa",
              "Contraseña actualizada correctamente"
            );
          } else {
            showAlertConfirm("info", "", "Ocurrió un error");
          }
        } else {
          showAlertConfirm(
            "info",
            "Información",
            "Las contraseñas no coinciden."
          );
        }
      } else {
        showAlertConfirm(
          "info",
          "Información",
          "La contraseña debe tener al menos 8 caracteres y menos de 12."
        );
      }
    } else {
      showToast(
        "info",
        "Todos los campos son obligatorios",
        "bottom-end",
        1000
      );
    }
  };

  const limpiar = () => {
    setStateModal({
      actual: "",
      password: "",
      confirmNueva: "",
    });
  };

  return {
    stateModal,
    setStateModal,
    handleShowModal,
    handleActual,
    handleNueva,
    handleConfirmNueva,
    nombre,
    apellidoMaterno,
    apellidoPaterno,
    validarChange,
    limpiar,
  };
};

export default UseMiUsuario;
