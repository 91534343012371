import { useState } from "react";
import useApi from "../../../services/useApi";
import useAlerts from "../../../services/useAlerts";
import { useNavigate } from "react-router-dom";

const useLogin = () => {
  const navigate = useNavigate();

  const { services } = useApi();

  const { showToast, showAlert } = useAlerts();

  const [stateLogin, setStateLogin] = useState({
    email: "",
    password: "",
    sendFlag: false,
  });
  const handleStateEmail = (e) => {
    setStateLogin({ ...stateLogin, email: e.target.value });
  };
  const handleStatePass = (e) => {
    setStateLogin({ ...stateLogin, password: e.target.value });
  };

  const [load, setLoad] = useState(false);
  const [loadLogin, setLoadLogin] = useState(false);

  const sendData = async (email, password) => {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const valido = email.match(regexEmail);

    const formData = new FormData();

    formData.set("correo", email);
    formData.set("password", password);

    if (valido) {
      setLoad(true);
      setLoadLogin(true);
      services("POST", "auth/login", formData)
        .then((response) => {
          const { execute, data } = response;

          if (execute) {
            Object.entries(data.data).forEach(([key, value]) => {
              if (key !== "rol") localStorage.setItem(key, value);
              else {
                localStorage.setItem("rol", value.rol);
                localStorage.setItem("roles_id", value.id);
              }
            });
          }
        })
        .finally(() => {
          setTimeout(() => {
            navigate("/reuniones");
            return true;
          }, 1000);
        });
    } else {
      setLoadLogin(false);
      showAlert("info", "Información", "Ingrese un correo válido.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevenir el comportamiento predeterminado del formulario
    // setLoad(true);
    setLoadLogin(true);
    validateLogin(stateLogin.email, stateLogin.password);
  };

  const validateLogin = async () => {
    const { email, password } = stateLogin;

    setLoadLogin(true);

    if (email.trim() !== "" && password.trim() !== "") {
      await sendData(email, password);
    } else {
      setStateLogin({ ...stateLogin, sendFlag: true });
      showToast(
        "info",
        "El correo y/o contraseña son requeridos.",
        "bottom-end",
        1000
      );
      setLoadLogin(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setLoadLogin(true);
      event.preventDefault();
      if (!loadLogin) {
        validateLogin();
      }
    }
  };

  return {
    stateLogin,
    setStateLogin,
    handleStateEmail,
    handleStatePass,
    validateLogin,
    sendData,
    load,
    setLoad,
    navigate,
    handleSubmit,
    loadLogin,
    handleKeyDown,
  };
};
export default useLogin;
