import { useEffect } from "react";
import pusherConfig from "../../../services/useWebSockets";

const PruebaSockets = () => {
  const pusherChannel = process.env.REACT_APP_PUSHER_CHANNEL;
  const pusherEvent = process.env.REACT_APP_PUSHER_EVENT;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const channel = pusherConfig.subscribe(pusherChannel);
    channel.bind(pusherEvent, function (data) {
      alert(JSON.stringify(data));
    });
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  return <div>{/* Your component JSX */}</div>;
};

export default PruebaSockets;
