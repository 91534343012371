import { useState } from "react";
import useApi from "../../../services/useApi";
import useAlerts from "../../../services/useAlerts";
import axios from "axios";

const useBitacora = () => {
  const [stateModal, setStateModal] = useState({
    open: false,
    openEdit: false,
    id: "",
    nombre: "",
    nombreError: false,
    openArchivo: false,
    archivo: null,
    uuid: "",
    minuta: false,
  });
  const { services } = useApi();

  const [stateModalEdit, setStateModalEdit] = useState({
    open: false,
    openEdit: false,
  });

  const { showAlertConfirm, showLoader, showToast } = useAlerts();

  const handleShowModal = (action) => {
    setStateModal({ ...stateModal, open: action, nombre: "" });
  };

  const handleNombre = (e) => {
    setStateModal({ ...stateModal, nombre: e.target.value });
  };

  const [stateBitacora, setStateBitacora] = useState({
    reuniones: [],
  });

  const [modal64, setModal64] = useState({ showModal: false, file: "" });

  const handleShowPDF = (action, file) => {
    setModal64({ ...modal64, showModal: action, file: file });
  };

  const [fileLoad] = useState(false);

  /* Visualizar Minuta de la reunion */
  const showMinuta = async (uuid) => {
    setStateModal({ ...stateModal, minuta: true });
    //acta de sesión
    showLoader();
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
      responseType: "blob",
    };

    await axios
      .get(
        `${process.env.REACT_APP_URLAPI}v1/meetings/download-minutes/${uuid}`,
        config
      )
      .then((response) => {
        var url = URL.createObjectURL(response.data);
        handleShowPDF(true, url);
        showToast(
          "success",
          "Archivo descargado exitosamente",
          "bottom-end",
          1000
        );
      })
      .catch((error) => {
        showAlertConfirm("error", `No se ha podido procesar la solicitud.`);
      });
  };

  /* Visualizar el archivo Cargado */
  const showArchivo = async (uuid) => {
    setStateModal({ ...stateModal, minuta: false });
    showLoader();
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
      responseType: "blob",
    };

    await axios
      .get(
        `${process.env.REACT_APP_URLAPI}v1/binnacles/${uuid}/download`,
        config
      )
      .then((response) => {
        var url = URL.createObjectURL(response.data);
        handleShowPDF(true, url);
        showToast(
          "success",
          "Archivo descargado exitosamente",
          "bottom-end",
          1000
        );
      })
      .catch((error) => {
        showAlertConfirm("error", `No se ha podido procesar la solicitud.`);
      });
  };

  /* Obtener reuniones con status CELEBRADA */
  const verBitacora = async () => {
    const { execute, data } = await services("GET", "v1/binnacles");

    if (execute) {
      setStateBitacora({ ...stateBitacora, reuniones: data.data });
      showToast("success", "Carga de información completa", "bottom-end", 2000);
    }
  };

  const handleClickBackdrop = (event) => {
    event.stopPropagation();
  };

  const handleShowArchivo = (action, uuid) => {
    setStateModal({ ...stateModal, openArchivo: action, uuid: uuid });
  };

  const [nombreArchivo, setNombreArchivo] = useState("");
  const [hideLegend, setHideLegend] = useState(false);

  // CARGAR ARCHIVO MINUTA
  const handleArchivo = (e) => {
    const selectedFile = e.target.files[0];
    const maxSize = 25 * 1024 * 1024;

    if (selectedFile) {
      if (selectedFile && selectedFile.type === "application/pdf") {
        if (selectedFile.size <= maxSize) {
          setStateModal({
            ...stateModal,
            archivo: selectedFile,
          });
          setNombreArchivo(selectedFile.name);
          setHideLegend(true);
        } else {
          showAlertConfirm(
            "info",
            "Archivo demasiado grande",
            "El archivo supera el límite de 25MB"
          );
        }
      } else {
        showAlertConfirm(
          "info",
          "Información",
          "Por favor ingresa un archivo en formato PDF."
        );
      }
    }
  };

  const mandarMinuta = async () => {
    const { archivo, uuid } = stateModal;
    const formData = new FormData();

    formData.set("file", archivo);

    // console.log(uuid);

    const { execute } = await services(
      "POST",
      `v1/binnacles/${uuid}/upload`,
      formData,
      true
    );

    if (execute) {
      await showAlertConfirm("success", "Archivo cargado correctamente");
      limpiarFormularioMinuta();
    }
  };

  const limpiarFormularioMinuta = () => {
    setStateModal({
      ...stateModal,
      archivo: null,
      uuid: "",
      openArchivo: false,
    });
    setNombreArchivo("");
    if (hideLegend) {
      setHideLegend(false);
    }
  };

  return {
    stateModal,
    setStateModal,
    handleShowModal,
    handleNombre,
    stateModalEdit,
    verBitacora,
    stateBitacora,
    showMinuta,
    modal64,
    handleShowPDF,
    fileLoad,
    handleArchivo,
    handleShowArchivo,
    handleClickBackdrop,
    nombreArchivo,
    hideLegend,
    mandarMinuta,
    limpiarFormularioMinuta,
    showArchivo,
    setStateModalEdit,
  };
};
export default useBitacora;
