import Pusher from "pusher-js";
import Echo from "laravel-echo";

const puerto = process.env.REACT_APP_PUERTO;
const endpointEvn = process.env.REACT_APP_ECHO;
const wsHostEvn = process.env.REACT_APP_WH;
const keyEvn = process.env.REACT_APP_PUSHER_KEY;
const clusterEvn = process.env.REACT_APP_PUSHER_CLUSTER;

const pusher = (token) => {
  const pusherX = new Echo({
    broadcaster: "pusher",
    key: keyEvn,
    wsHost: wsHostEvn,
    // wsHost: 'tmec-broathcast-dev.michoacan.gob.mx',
    wsPort: puerto,
    // wsPort: '80'
    forceTLS: false,
    disableStats: true,
    encrypted: true,
    cluster: clusterEvn,
    // cluster: "eu",
    channelAuthorization: {
      // endpoint: "http://tmec-api-qa.michoacan.gob.mx/api/broadcasting/auth",
      // endpoint: "http://tmec-api-dev.michoacan.gob.mx/api/broadcasting/auth",
      endpoint: endpointEvn,
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    },
  });
  return pusherX;
};

window.Pusher = Pusher;

// Pusher.log = (message) => {
//   if (window.console && window.console.log) {
//     window.console.log(message);
//   }
// };

export default pusher;
