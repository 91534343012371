import React from "react";
import MUIDataTable from "mui-datatables";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const root = document.documentElement;
const colorUI = getComputedStyle(root).getPropertyValue("--colorUI").trim();

const getMuiTheme = () =>
  createTheme({
    components: {
      MUIDataTable: {
        styleOverrides: {
          headRow: {
            backgroundColor: `${colorUI} !important`,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: `${colorUI} !important`,
            color: "white",
          },
        },
      },
    },
  });

export default function DataTable({ data, columns, title, more = {} }) {
  let options = {
    ...more,
    print: false,
    viewColumns: true,
    filter: false,
    selectableRows: "none",
    tableBodyHeight: "auto",
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    textLabels: {
      body: {
        noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
        toolTip: "Clasificar",
        columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",

        viewColumns: "Ver columnas",
        filterTable: "Filtrar tabla",
      },
      filter: {
        all: "Todo",
        title: "Filtros",
        reset: "Restaurar",
      },
      viewColumns: {
        title: "Ver columnas",
        titleAria: "Mostrar/Ocultar columnas en la tabla",
      },
      selectedRows: {
        text: "columna(s) selecciona(as)",
        delete: "Eliminar",
        deleteAria: "Eliminar filas seleccionadas",
      },
    },
    responsive: "standard",
    downloadOptions: {
      filename: "TMEC.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
      utf8WithBom: true,
    },
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid className="container-fluid mt-5 px-5 ">
        <Grid item xs={12} >
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              style={{ Overflow: "hidden" }}
              title={title}
              data={data}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
    </Box>
  );
}
