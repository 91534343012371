import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import useMenu from "./customHooks/useMenu";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import logoConago from "../../assets/images/CONACO-TMEC HRZ.png";
import {
  ExitToApp,
  PeopleAlt,
  Source,
  SnippetFolder,
  FolderSpecial,
  GroupAdd,
  PlaylistAddCheck,
  AccountBox,
} from "@mui/icons-material";
import useAlerts from "../../services/useAlerts";
import { useLocation } from "react-router-dom";
import { shouldShowMenu } from "./headerComponent";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const {
    navigate,
    toggleDrawer,
    settings,
    setItemsMenu,
    useEffect,
    globalState,
    setGlobalState,
  } = useMenu();

  const location = useLocation();

  const { showAlertConfirm } = useAlerts();

  const result = shouldShowMenu(location.pathname);

  const icons = {
    PeopleAlt: <PeopleAlt />,
    Source: <Source />,
    SnippetFolder: <SnippetFolder />,
    FolderSpecial: <FolderSpecial />,
    GroupAdd: <GroupAdd />,
    PlaylistAddCheck: <PlaylistAddCheck />,
    AccountBox: <AccountBox />,
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setItemsMenu();
    }
    return () => {
      isMounted = false;
    };
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  return (
    <>
      <div className="completeScreen" onClick={handleDrawerClose} />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Toolbar>
          <IconButton
            className={`p-4 rounded-3 ${result ? "mt-4" : null} menu-index`}
            position="absolute"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ ...(open && { display: "none" }), height: 2 }}
          >
            <MenuIcon style={{ fontSize: "2.5rem" }} />
          </IconButton>
        </Toolbar>

        <Drawer
          position="absolute"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            zIndex: (theme) => theme.zIndex.drawer + 2,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
          onClose={toggleDrawer(false)}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <div className="brand  flex-column">
            <img className="menu_logo" src={logoConago} alt="Comisión T-MEC" />
          </div>
          <Divider />
          <List>
            {settings.items.map((ventana) => (
              <ListItem key={ventana.name} disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(ventana.route);
                    setGlobalState({
                      ...globalState,
                      navBarOpen: false,
                    });
                    handleDrawerClose(); // Cerrar menu cada vez que se accede a un apartado
                  }}
                >
                  <ListItemIcon>{icons[ventana.icon]}</ListItemIcon>
                  <ListItemText primary={ventana.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <div className="text-center pt-3">
            <b className="fs-5 text-center text-secondary">{settings.rol}</b>
          </div>
          <Grid container columns={12} spacing={1}>
            <Grid item xs={12} md={12} className="text-center-custom mt-10">
              <Tooltip
                className="pt-4"
                disableFocusListener
                title="Cerrar Sesión"
              >
                <IconButton
                  aria-label="Cerrar Sesión"
                  onClick={async () => {
                    if (result) {
                      navigate("/");
                      localStorage.clear();
                      setGlobalState({
                        ...globalState,
                        navBarOpen: !globalState.navBarOpen,
                        hamburger: false,
                      });
                    } else if (
                      window.location.pathname.split("/")[1] === "reunion"
                    ) {
                      const confirmation = await showAlertConfirm(
                        "question",
                        "Confirmación",
                        "¿Tiene una reunión en ejecución, estás seguro de que desea cerrar sesión?",
                        true
                      );

                      if (confirmation) {
                        navigate("/");
                        localStorage.clear();
                        setGlobalState({
                          ...globalState,
                          navBarOpen: !globalState.navBarOpen,
                          hamburger: false,
                        });
                      }
                    }
                  }}
                >
                  <span className="fs-6 text-red">Salir</span>
                  <ExitToApp color="error" />
                </IconButton>
                <br />
              </Tooltip>
            </Grid>
          </Grid>
        </Drawer>
      </Box>

      {/* Backdrop modal */}
      {open ? (
        <div onClick={handleDrawerClose} className="modal-backdrop"></div>
      ) : null}
    </>
  );
}
