import { useState } from "react";
import useApi from "../../../services/useApi";

const useRol = () => {
  const { services } = useApi();

  const [stateRoles, setStateRoles] = useState({
    solicitudes: [],
  });

  const loadRoles = async () => {
    const { execute, data } = await services("GET", "v1/roles");
    if (execute) {
      setStateRoles({ ...stateRoles, solicitudes: data.data });
    }
  };

  return {
    loadRoles,
    stateRoles,
    setStateRoles,
  };
};

export default useRol;
