import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import avisoPrivacidad from "../../assets/aviso-privacidad-TMEC.pdf";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Footer() {
  return (
    <>
      <div className="push footer"></div>
      <footer id="footer" className=" border-top--rojo p-4 fondo mt-5 ">
        <div className="container">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Item>
                <div className="flex-column align-items-center justify-content-center">
                  <div className="text-gray mb-1 ">
                    <b>Contacto:</b> <span>Luis López Sánchez</span>
                  </div>
                  <div className="text-gray text-center">
                    <a
                      className="text-colorUI text-decoration-underline"
                      href="mailto:luis.lopezp@yucatan.gob.mx"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      luis.lopezp@yucatan.gob.mx
                    </a>
                  </div>
                  <div className="text-gray text-colorUI ">
                    <strong className="text-gray">Tel:</strong>{" "}
                    <a
                      className="text-colorUI text-decoration-underline"
                      href="tel:9992003751"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      +52 999 200 3751
                    </a>
                  </div>
                </div>
              </Item>
            </Grid>
            <Grid item xs={12} md={6}>
              <Item>
                <div className="d-lg-flex flex-column align-items-center justify-content-center">
                  <div className="text-gray mb-1">
                    <b>Comisión T-MEC:</b>
                  </div>
                  <div className="text-gray">sedeco.michoacan.gob.mx/TMEC</div>
                  <div>
                    <a
                      className="d-flex align-items-center gap-1 text-colorUI text-decoration-underline justify-content-center"
                      href={avisoPrivacidad}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Aviso de Privacidad
                      <OpenInNewIcon style={{ fontSize: "small" }} />
                    </a>
                  </div>
                </div>
              </Item>
            </Grid>
          </Grid>
        </div>
      </footer>
    </>
  );
}

export default Footer;
