import Pusher from "pusher-js";

const pusherKey = process.env.REACT_APP_PUSHER_KEY;
const pusherCluster = process.env.REACT_APP_PUSHER_CLUSTER;

const setPusherConfig = (token) => {
  const pusher = new Pusher(pusherKey, {
    cluster: pusherCluster,
    channelAuthorization: {
      endpoint: `http://10.8.7.97:8083/api/broadcasting/auth`,
      // endpoint: `http://10.8.7.97:8098/api/broadcasting/auth`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    },
  });
  return pusher;
};

export default setPusherConfig;
