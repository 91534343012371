import Swal from "sweetalert2";
import imageTmec from "../assets/images/roundedConago.png";
// import { all } from "axios";

const useAlerts = () => {
  const showAlert = (icon, title, text) => {
    Swal.fire({
      allowOutsideClick: false,
      icon: icon,
      iconColor: "#F1948A",
      title: title,
      text: text,
      confirmButtonText: "Aceptar",
      confirmButtonColor: "#a70631",
    });
  };

  //-------------------------------------------Alerta Toast------------------------------------------//
  const showToast = (icon, text, position, timer) => {
    const alertToast = {
      toast: true,
      position,
      showConfirmButton: false,
      timer,
      timerProgressBar: true,
    };
    if (icon !== "success") alertToast["iconColor"] = "#F1948A";
    Swal.mixin(alertToast).fire({
      icon,
      text,
    });
  };

  //-------------------------------------------Alerta Confirm-----------------------------------------//
  const showAlertConfirm = (icon, title, text, multiButton = false) => {
    const iconColor = icon === "info" ? "#F1948A" : "";

    const objectAlert = {
      icon,
      iconColor,
      title,
      text,
      confirmButtonText: "Aceptar",
      confirmButtonColor: "#a70631",
      allowOutsideClick: false,
      timer: 4500,
      customClass: {
        container: "swal2-container",
      },
    };

    if (multiButton) {
      objectAlert["showCancelButton"] = true;
      objectAlert["cancelButtonText"] = "Cancelar";
      objectAlert["cancelButtonColor"] = "#ABB2B9";
      objectAlert.reverseButtons = true;
    }

    return new Promise((resolve) => {
      Swal.fire(objectAlert).then((result) => {
        resolve(result.isConfirmed ? true : false);
      });
    });
  };

  //-------------------------------------------Alerta Validaciones-----------------------------------//
  const showValidations = (errors) => {
    let html = "<div><ul>";
    for (const [, value] of Object.entries(errors))
      html += `<li style="text-align:left">${value[0]}</li>`;
    html += "</ul></div>";
    void Swal.fire({
      icon: "info",
      iconColor: "#F1948A",
      title: "Información",
      confirmButtonText: "Aceptar",
      confirmButtonColor: "#a70631",
      allowOutsideClick: false,
      html,
    });
  };

  //-------------------------------------------Alerta Loader-----------------------------------------//

  const showLoader = () => {
    new Swal({
      html: `<img src="${imageTmec}" style="max-width: 10%;"><br><br><b>Procesando solicitud...</b>`,
      allowOutsideClick: false,
    });
    // Swal.showLoading();

    return new Promise(() => {
      Swal.showLoading();
    });
  };

  //-------------------------------------------Alerta copy------------------------------------------//
  const showAlertCopy = (
    icon,
    title,
    text,
    confirmButtonText,
    multiButton = false
  ) => {
    const iconColor = icon === "info" ? "#F1948A" : "";

    const objectAlert = {
      icon,
      iconColor,
      title,
      text,
      confirmButtonText,
      confirmButtonColor: "#a70631",
      allowOutsideClick: false,
      customClass: {
        container: "swal2-container",
      },
    };

    if (multiButton) {
      objectAlert["showCancelButton"] = true;
      objectAlert["cancelButtonText"] = "Cancelar";
      objectAlert["cancelButtonColor"] = "#ABB2B9";
      objectAlert.reverseButtons = true;
    }

    return new Promise((resolve) => {
      Swal.fire(objectAlert).then((result) => {
        resolve(
          result.isConfirmed ? navigator.clipboard.writeText(text) : false
        );
      });
    });
  };

  return {
    showAlert,
    showToast,
    showValidations,
    showAlertConfirm,
    showLoader,
    showAlertCopy,
  };
};
export default useAlerts;
