import LockResetIcon from "@mui/icons-material/LockReset";
import React, { useState } from "react";
import Cuenta from "@mui/icons-material/AccountCircle";
import UseMiUsuario from "../../auth/customHooks/useMiUsuario";
import {
  Button,
  Grid,
  TextField,
  Container,
  Card,
  CardContent,
  Box,
  CssBaseline,
  IconButton,
} from "@mui/material";
import { Close, Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import Modal from "@mui/material/Modal";

const miUsuario = () => {
  const {
    stateModal,
    handleShowModal,
    handleNueva,
    handleConfirmNueva,
    nombre,
    apellidoMaterno,
    apellidoPaterno,
    validarChange,
    limpiar,
  } = UseMiUsuario();

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [validation, setValidation] = useState(true)

  const  comprobarContraseña =(el) => {
    const clave = el.target.value;
    const validar = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-.]).{8,}$/.test(clave)
    console.log(validar, clave, el)
    

    if(validar ){
      setValidation(false)
    }else{
      setValidation(true)
    }
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Card
            sx={{
              marginTop: 16,
              marginBottom: 15,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <b className="mb-1 fs-4">
                  Información del Usuario
                  <Cuenta />
                </b>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Nombre(s)"
                      name="nombre"
                      InputProps={{
                        readOnly: true,
                      }}
                      value={nombre}
                      // onChange={}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      type="text"
                      fullWidth
                      margin="normal"
                      id="apePaterno"
                      label="Apellido Paterno"
                      InputProps={{
                        readOnly: true,
                      }}
                      value={apellidoPaterno}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      type="text"
                      fullWidth
                      margin="normal"
                      id="apeMaterno"
                      label="Apellido Materno"
                      InputProps={{
                        readOnly: true,
                      }}
                      value={apellidoMaterno}
                    />
                  </Grid>
                </Grid>
                <div>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Button
                        className="background-colorUI"
                        onClick={() => handleShowModal(true)}
                        variant="contained"
                        endIcon={<LockResetIcon />}
                        sx={{
                          mt: 1,
                          alignItems: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Cambiar Contraseña
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {stateModal.open ? (
        <Modal
          open={stateModal.open}
          onClose={() => handleShowModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={styleModal}
            className="p-3 p-md-5 col-10 col-md-6 pt-4"
            component="form"
            noValidate
          >
            <h4 className="text-colorUI" style={{ fontSize: "20px" }}>
              Cambiar Contraseña
            </h4>
            <IconButton
              className={
                "border-0 position-absolute p-1 top-0 end-0 m-2 rounded-circle"
              }
              onClick={() => {
                handleShowModal(false);
                limpiar();
              }}
            >
              <Close />
            </IconButton>
            <Grid container columns={12} spacing={1}>
              <Grid item xs={12}>
                <TextField
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                  size="small"
                  id="nueva"
                  label="Contraseña Nueva"
                  type="text"
                  value={stateModal.nueva}
                  variant="filled"
                  onChange={handleNueva}
                  helperText={!validation ? '' : 'La contraseña debe contener 8 caracteres, Una mayúscula, un numero y un caracter especial. '}
                  onKeyUp={(e) => comprobarContraseña(e)}
                  error={validation}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                  size="small"
                  id="confirmNueva"
                  label="Confirmar contraseña"
                  type="text"
                  value={stateModal.confirmNueva}
                  variant="filled"
                  onChange={handleConfirmNueva}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6} className="text-right-custom">
                <LoadingButton
                  variant="contained"
                  endIcon={<Close />}
                  loading={false}
                  sx={{
                    mt: 3,
                    mb: 2,
                    mr: 1,
                    ml: 1,
                    alignItems: "center",
                    backgroundColor: "#ABB2B9",
                  }}
                  onClick={() => {
                    handleShowModal(false);
                    limpiar();
                  }}
                >
                  Cancelar
                </LoadingButton>
              </Grid>
              <Grid item xs={6} md={6} className="text-left-custom ">
                <LoadingButton
                  className="background-colorUI"
                  variant="contained"
                  endIcon={<Save />}
                  loading={false}
                  sx={{
                    mt: 3,
                    mb: 2,
                    ml: 1,
                    mr: 1,
                    alignItems: "center",
                  }}
                  onClick={() => validarChange()}
                >
                  Guardar
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      ) : null}
    </Container>
  );
};
export default miUsuario;
