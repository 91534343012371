import Axios from "axios";

//Custom Hooks
import Swal from "sweetalert2";
import useAlerts from "./useAlerts";

const useApi = () => {
  const { showValidations, showAlert} =
    useAlerts();

  const config = (formData) => {
    let token = window.localStorage.getItem("token");
    if (token) {
      return {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": formData ? "multipart/form-data" : "application/json",
        },
      };
    } else {
      return {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      };
    }
  };

  const axios = Axios.create({
    baseURL: process.env.REACT_APP_URLAPI,
  });

  const services = (method, service, body, formData = false) => {
    switch (method) {
      case "GET":
        return axios
          .get(service, config(formData))
          .then((r) => {
            return { execute: true, data: r.data };
          })
          .catch((err) => {
            return response(err.response);
          });

      case "POST":
        return axios

          .post(service, body, config(formData))
          .then((r) => {
            return { execute: true, data: r.data };
          })
          .catch((err) => {
            return response(err.response);
          });

      case "PUT":
        return axios
          .put(service, body, config())
          .then((r) => {
            return { execute: true, data: r.data };
          })
          .catch((err) => {
            return response(err.response);
          });

      case "DELETE":
        return axios
          .delete(service, config())
          .then((r) => {
            return { execute: true, data: r.data };
          })
          .catch((err) => {
            return response(err.response);
          });

      default:
        break;
    }
  };

  const response = (r) => {
    const { status: httpStatus, data } = r;

    switch (httpStatus) {
      case 200:
      case 201:
        return { execute: true, data: data };
      case 400:
        return { execute: true, data: data };

      case 429:
      case 401:
      case 403:
        if (
          window.location.pathname !== "/login" &&
          window.location.pathname !== "/"
        ) {
          // showToast(
          //   "info",
          //   '"Tu sesión ha expirado. Vuelve a iniciar sesión.',
          //   "bottom-end",
          //   1000
          // );
          Swal.fire({
            title: "Tu sesión ha expirado. Vuelve a iniciar sesión.",
            icon: "warning",
            confirmButtonColor: "#A70631",
            confirmButtonText: "Aceptar",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.replace("/login");
              window.localStorage.clear();
            }
          });
        } else {
          showAlert("info", "Información", "Credenciales incorrectas.");
        }

        return { execute: false, data: null };

      /*   case 403:
        return { execute: true, data: null }; */

      case 422:
        const { errores } = data;
        showValidations(errores);
        return { execute: false, data: errores };

      case 404:
        return { execute: false, data: data };

      case 500:
        showAlert(
          "error",
          "Error",
          "Lo sentimos, ha ocurrido un error interno en el servidor."
        );
        return { execute: false, data: null };

      default:
        return { status: r.status, error: r.data.error };
    }
  };

  return {
    services,
  };
};
export default useApi;
