import { useEffect } from "react";
import DataTable from "../../general/GlobalComponents/DataTable";
import useBitacora from "../customHooks/useBitacora";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import { Close, Save, RemoveRedEye } from "@mui/icons-material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { LoadingButton } from "@mui/lab";
import useAlerts from "../../../services/useAlerts";
import UploadIcon from "@mui/icons-material/Upload";
import { useMediaQuery } from "@mui/material";

const Bitacora = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  const styleModalReagend = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: isMobile ? "90%" : "25%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
  };

  const styleModalPDF = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: isMobile ? "90%" : "60%",
    height: "80%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
  };

  const InputFile = styled.input``;
  // const ITEM_HEIGHT = 48;
  // const ITEM_PADDING_TOP = 8;
  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  //       width: 250,
  //     },
  //   },
  // };
  const rolUser = localStorage.getItem("rol");

  const {
    stateModal,
    verBitacora,
    showMinuta,
    stateBitacora,
    modal64,
    handleShowPDF,
    handleArchivo,
    handleShowArchivo,
    nombreArchivo,
    hideLegend,
    mandarMinuta,
    limpiarFormularioMinuta,
    showArchivo,
  } = useBitacora();

  const { showLoader } = useAlerts();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let isMounted = true;
    showLoader();
    if (isMounted) {
      verBitacora();
      isMounted = false;
    }

    return () => {
      isMounted = false;
    };
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  const columns = [
    {
      name: "nombre",
      label: "Nombre",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "left" }}>Reunión {value}</div>
        ),
      },
    },
    {
      name: "tipo",
      label: "Tipo de reunión",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "left" }}>Reunión {value.tipo}</div>
        ),
      },
    },
    {
      name: "fecha",
      label: "Fecha",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "left" }}>{value}</div>
        ),
      },
    },
    {
      name: "hora",
      label: "Hora",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "left" }}>{value}</div>
        ),
      },
    },
    {
      name: "estatus",
      label: "Estatus",
      options: {
        download: false,
        customBodyRender: (value) => (
          <div style={{ textAlign: "left" }}>{value.estatus}</div>
        ),
      },
    },
    {
      name: "acciones",
      label: "ACCIONES ",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <Tooltip
                disableFocusListener
                title="Visualizar Resumen de Acuerdos de Sesión"
              >
                <IconButton
                  aria-label="Visualizar"
                  onClick={() => {
                    showMinuta(stateBitacora.reuniones[dataIndex].uuid);
                  }}
                >
                  <InsertDriveFileIcon color="success" />
                </IconButton>
              </Tooltip>
              {rolUser === "Participante" || rolUser === "Invitado" ? null : (
                <Tooltip disableFocusListener title="Cargar Acta de Sesión">
                  <IconButton
                    aria-label="Visualizar"
                    onClick={() =>
                      handleShowArchivo(
                        true,
                        stateBitacora.reuniones[dataIndex].uuid
                      )
                    }
                  >
                    <UploadIcon sx={{ color: "#37474f" }} />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip disableFocusListener title="Visualizar Acta de Sesión">
                <IconButton
                  aria-label="Visualizar"
                  onClick={() => {
                    showArchivo(stateBitacora.reuniones[dataIndex].uuid);
                  }}
                >
                  <RemoveRedEye sx={{ color: "#37474f" }} />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      <Grid className="container mt-5 mb-2">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <h3 className="text-center fw-bold pt-4 pt-md-0">
              Bitácora de reuniones
            </h3>
            <DataTable
              title={
                <span>
                  <h5>Bitácora</h5>
                </span>
              }
              data={stateBitacora.reuniones}
              columns={columns}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* MODAL PDF */}
      {modal64.showModal ? (
        <Modal
          open={() => handleShowPDF(true)}
          onClose={() => handleShowPDF(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="p-2 p-md-4 col-10 col-md-6 pt-4"
            sx={styleModalPDF}
            component="form"
            // onSubmit={handleSubmit}
            noValidate
          >
            <h4 style={{ color: "#a70631", fontSize: "20px" }}>
              {stateModal.minuta
                ? "Resumen de Acuerdos de Sesión"
                : "Acta de Sesión"}
            </h4>
            <IconButton
              className={
                "border-0 position-absolute p-1 top-0 end-0 m-2 rounded-circle"
              }
              onClick={() => {
                handleShowPDF(false);
              }}
            >
              <Close />
            </IconButton>
            <iframe
              src={modal64.file}
              loading="lazy"
              type="application/pdf"
              frameborder="0"
              scrolling="auto"
              style={{ width: "100%", height: "100%" }}
              title={`${modal64.file}`}
            ></iframe>
            <Grid containter>
              <Grid item xs={12} md={12} className="text-center-custom">
                <LoadingButton
                  variant="contained"
                  endIcon={<Close />}
                  loading={false}
                  sx={{
                    mt: 3,
                    mb: 2,
                    mr: 1,
                    ml: 1,
                    alignItems: "center",
                    backgroundColor: "#a70631",
                  }}
                  onClick={() => {
                    handleShowPDF(false);
                  }}
                >
                  Cerrar
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      ) : null}
      <Grid className="d-lg-none" style={{ margin: "150px" }}></Grid>
      {stateModal.openArchivo ? (
        <Modal
          open={stateModal.openArchivo}
          onClose={() => {
            handleShowArchivo(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="p-2 p-md-4 col-10 col-md-6 pt-4"
            sx={styleModalReagend}
            component="form"
            noValidate
          >
            <h4 style={{ color: "#a70631", fontSize: "20px" }}>
              Cargar Acta de Sesión
            </h4>
            <IconButton
              className={
                "border-0 position-absolute p-1 top-0 end-0 m-2 rounded-circle"
              }
              onClick={() => {
                // handleShowArchivo(false);
                limpiarFormularioMinuta();
              }}
            >
              <Close />
            </IconButton>
            <Grid className="ps-4 py-4" item xs={12} md={6}>
              <div className="d-flex align-items-center">
                <div>
                  <InputFile
                    accept=".pdf"
                    className="input-file"
                    id="archivo"
                    type="file"
                    onChange={handleArchivo}
                  />
                  <label htmlFor="archivo">
                    {nombreArchivo
                      ? `Archivo seleccionado: ${nombreArchivo}`
                      : "Seleccione el archivo"}
                  </label>
                </div>
              </div>
              {hideLegend ? null : (
                <p className="pt-3 m-0 fs-7 text-danger">
                  El archivo no debe superar los 25MB
                </p>
              )}
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6} className="text-right-custom ">
                <LoadingButton
                  variant="contained"
                  endIcon={<Close />}
                  loading={false}
                  sx={{
                    mt: 3,
                    mb: 2,
                    mr: 1,
                    ml: 1,
                    alignItems: "center",
                    backgroundColor: "#ABB2B9",
                  }}
                  onClick={() => {
                    // handleShowArchivo(false);
                    limpiarFormularioMinuta();
                  }}
                >
                  Cancelar
                </LoadingButton>
              </Grid>
              <Grid item xs={12} md={6} className="text-left-custom ">
                <LoadingButton
                  variant="contained"
                  endIcon={<Save />}
                  loading={false}
                  sx={{
                    mt: 3,
                    mb: 2,
                    mr: 1,
                    ml: 1,
                    alignItems: "center",
                    backgroundColor: "#a70631",
                  }}
                  onClick={() => {
                    mandarMinuta();
                  }}
                >
                  Guardar
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      ) : null}{" "}
    </>
  );
};

export default Bitacora;
