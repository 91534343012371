import "./style/App.css";
import "./style/estilosMovil.css";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import { useState } from "react";
import { GlobalContextProvider } from "./context/globalContext";
import { NotificacionContextProvider } from "./context/notificationContext";
import NotificationUi from "./components/iu/notificationUI";
import Login from "./components/auth/views/loginView";
import HeaderComponent from "./components/general/headerComponent";
import FooterComponent from "./components/general/footerComponent";
import PrivateRoutes from "./components/privateRoutes";
import Usuarios from "./components/administrador/views/usuariosView";
import Reuniones from "./components/administrador/views/reunionesView";
import ReunionesUser from "./components/auth/views/reunionUserView";
import ForgetPassword from "./components/auth/views/forgotPasswordView";
// eslint-disable-next-line
import { useParams } from "react-router-dom";
import NotFound from "./components/auth/views/notFound";
import Camaras from "./components/administrador/views/camarasView";
import SocketsPrueba from "./components/administrador/views/pruebaSockets.jsx";
import Comisiones from "./components/administrador/views/comisionesView";
import Titulos from "./components/administrador/views/titulosView";
import MiUsuario from "./components/auth/views/miUsuarioView";
import ReunionView from "./components/administrador/views/newReunionView";
import Bitacora from "./components/administrador/views/bitacoraView";

function App() {
  // eslint-disable-next-line
  const { parametro } = useParams();
  // eslint-disable-next-line
  const [login, setLogin] = useState(localStorage.getItem("token"));

  const RouterApp = () => {
    let routes = useRoutes([
      { path: "/", element: <Login login={login} /> },
      { path: "/login", element: <Login login={login} /> },
      {
        path: "/usuarios",
        element: (
          <PrivateRoutes allowedRoles={["Administrador"]}>
            <Usuarios />
          </PrivateRoutes>
        ),
      },
      {
        path: "/reuniones",
        element: (
          <PrivateRoutes>
            <Reuniones />
          </PrivateRoutes>
        ),
      },
      {
        path: "/reunionesUser",
        element: (
          <PrivateRoutes>
            <ReunionesUser />
          </PrivateRoutes>
        ),
      },
      {
        path: "/camaras",
        element: (
          <PrivateRoutes allowedRoles={["Administrador"]}>
            <Camaras />
          </PrivateRoutes>
        ),
      },
      {
        path: "/comisiones",
        element: (
          <PrivateRoutes allowedRoles={["Administrador"]}>
            <Comisiones />
          </PrivateRoutes>
        ),
      },
      {
        path: "/titulos",
        element: (
          <PrivateRoutes allowedRoles={["Administrador"]}>
            <Titulos />
          </PrivateRoutes>
        ),
      },
      {
        path: "/bitacora",
        element: (
          <PrivateRoutes>
            <Bitacora />
          </PrivateRoutes>
        ),
      },
      {
        path: "/miUsuario",
        element: (
          <PrivateRoutes>
            <MiUsuario />
          </PrivateRoutes>
        ),
      },
      {
        path: "/reunion/:parametro",
        element: (
          <PrivateRoutes>
            {/* <BottomAppBar /> */}
            <ReunionView />
          </PrivateRoutes>
        ),
      },
      { path: "/restablecerContrasena", element: <ForgetPassword /> },
      {
        path: `/restablecerContrasena/:parametro`,
        element: <ForgetPassword />,
      },
      { path: "*", element: <NotFound /> },
      { path: "/socket", element: <SocketsPrueba /> },
    ]);
    return routes;
  };

  return (
    <div className="App">
      <GlobalContextProvider>
        <NotificacionContextProvider>
          <Router>
            <HeaderComponent />
            <NotificationUi />
            {/* <LoaderProvider> */}
            <RouterApp />
            {/* </LoaderProvider> */}
            <FooterComponent />
          </Router>
        </NotificacionContextProvider>
      </GlobalContextProvider>
    </div>
  );
}

export default App;
