import { useRef, useState } from "react";
import useApi from "../../../services/useApi";
import useAlerts from "../../../services/useAlerts";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import setPusherConfig from "../../../services/useWebSockets";
import axios from "axios";

const useReuniones = () => {
  const [stateUsuariosInvite, setStateUsuariosInvite] = useState({
    usuariosInvite: [],
  });

  const navigate = useNavigate();
  const [stateModal, setStateModal] = useState({
    open: false,
    openEdit: false,
    openReagendar: false,
    openAddAsunto: false,
    openChangeStatus: false,
    nombreReunion: "",
    nombreReunionError: false,
    fechaReunion: null,
    horaReunion: null,
    fechaReunionError: false,
    estatusReunion: "",
    estatusReunionError: false,
    ordenDia: null,
    uuid: "",
    asuntos: [""],
    asuntosEdit: [""],
    usuarios: [],
    newAsunto: "",
    editAsuntoNew: "",
    infoReunion: {},
    statusReunion: 4,
    newusuarios: [],
    tipoReunion: "",
  });

  const [stateData, setStateData] = useState({
    initialReuniones: [
      { nombre: "Reunión Agendada", fecha: "01/01/2024", estatus: "Agendada" },
      {
        nombre: "Reunión Aceptada",
        fecha: "01/01/2024",
        estatus: "Finalizada",
      },
      { nombre: "Reunión LIX", fecha: "01/01/2024", estatus: "Cancelada" },
      { nombre: "Reunión Agendada", fecha: "01/01/2024", estatus: "Agendada" },
      {
        nombre: "Reunión Aceptada",
        fecha: "01/01/2024",
        estatus: "Finalizada",
      },
      { nombre: "Reunión LIX", fecha: "01/01/2024", estatus: "Cancelada" },
      { nombre: "Reunión Agendada", fecha: "01/01/2024", estatus: "Agendada" },
      {
        nombre: "Reunión Aceptada",
        fecha: "01/01/2024",
        estatus: "Finalizada",
      },
      { nombre: "Reunión LIX", fecha: "01/01/2024", estatus: "Cancelada" },
    ],
    filterReuniones: [
      { nombre: "Reunión Agendada", fecha: "01/01/2024", estatus: "Agendada" },
      { nombre: "Reunión LIX", fecha: "01/01/2024", estatus: "Finalizada" },
      { nombre: "Reunión LIX", fecha: "01/01/2024", estatus: "Cancelada" },
    ],
  });

  const { showAlertConfirm, showLoader, showToast } = useAlerts();

  // const {
  //   getUsuariosInvite,
  //   // getUsuariosId,
  //   stateUsuariosInvite,
  //   idUsersInvite,
  // } = useUsuarios();
  /* const [archivo, setArchivo] = useState(null); */

  const [nombreArchivo, setNombreArchivo] = useState("");

  // VALIDAR CAMPOS VACIOS EN LOS INPUTS DEL MODAL AÑADIR REUNIONES
  const [stateValidate, setStateValidate] = useState(false);

  // ASUNTOS DINAMICOS
  const [asuntos, setAsuntos] = useState([{ id: 1, value: "" }]);

  // ALMACENAR REUNION - SE USA PARA TRAER LOS ASUNTOS DE CADA REUNION Y MAPEARLOS
  const [stateReunion, setStateReunion] = useState({
    dataReunion: {},
    status: "",
    asuntosEdit: [""],
    idAsunto: null,
    idReunion: null,
    asuntos: [],
    newAsunto: "",
    usuariosInvitados: [],
    tipo: "",
  });

  // AÑADIR NUEVO ASUNTO
  const handleAddAsuntoAdd = () => {
    setStateModal((prevState) => {
      const arrayCopy = [...prevState.asuntos];
      arrayCopy.push("");
      return { ...prevState, asuntos: arrayCopy };
    });
  };

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [alertMeetingExcecute, setAlertMeetingExcecute] = useState(false);

  const handleAddAsunto = () => {
    setStateReunion((prevState) => {
      prevState.asuntos.push("");
      return {
        ...prevState,
      };
    });
  };

  /* HANDLE PARA EDITAR ASUNTOS EN EL MODAL */
  const handleAsuntoChange = (asunto, index) => {
    setStateReunion((prevState) => {
      const asuntosEditCopy = [...prevState.asuntosEdit];
      asuntosEditCopy[index] = asunto;

      const updatedAsuntos = prevState.dataReunion.asuntos.map((item, i) => {
        if (i === index) {
          return { ...item, asunto: asunto };
        }
        return item;
      });

      return {
        ...prevState,
        asuntosEdit: asuntosEditCopy,
        dataReunion: {
          ...prevState.dataReunion,
          asuntos: updatedAsuntos,
        },
      };
    });
  };

  const handleAsuntoChangeAdd = (asunto, index) => {
    stateModal.asuntos[index] = asunto;
    setStateModal({ ...stateModal });
  };

  const handleRemoveAsuntoAdd = (index) => {
    const arrayFilterCopy = stateModal.asuntos.filter(
      (elemento, indice) => indice !== index
    );

    setStateModal((prevState) => {
      return { ...prevState, asuntos: arrayFilterCopy };
    });
  };

  const handleRemoveAsunto = (index) => {
    const arrayFilterCopy = stateReunion.dataReunion.asuntos.filter(
      (elemento, indice) => indice !== index
    );

    setStateReunion((prevState) => {
      return {
        ...prevState,
        dataReunion: { ...prevState.dataReunion, asuntos: arrayFilterCopy },
      };
    });
  };

  const modalOpen = (id) => {
    setStateModal({ ...stateModal, open: true });
  };

  const handleShowModal = (action) => {
    setStateModal({ ...stateModal, open: action });
  };

  const handleEstatusReunion = (e) => {
    setStateModal({ ...stateModal, estatusReunion: e.target.checked });
  };

  const handleClose = () => {
    setStateModal({
      ...stateModal,
      open: false,
      usuarios: [],
      newusuarios: [],
    });
  };

  const handleNombreReunion = (e) => {
    setStateModal({ ...stateModal, nombreReunion: e.target.value });
  };

  const handleTipoReunion = (e) => {
    setStateModal({ ...stateModal, tipoReunion: e.target.value });
  };

  const handleNombreReunionEdit = (e) => {
    setStateReunion({
      ...stateReunion,
      dataReunion: {
        ...stateReunion.dataReunion,
        nombre: e.target.value,
      },
    });
  };
  // const handleTipoReunionEdit = (e) => {
  //   setStateReunion({
  //     ...stateReunion,
  //     dataReunion: {
  //       ...stateReunion.dataReunion,
  //       tipo: e.target.value,
  //     },
  //   });
  // };

  const handleTipoReunionEdit = (e) => {
    setStateReunion({ ...stateReunion, tipo: e.target.value });
  };

  const handleAsuntos = (e) => {
    setStateModal({ ...stateModal, asuntos: e.target.value });
  };

  const handleComment = (e) => {
    setStateMessages({ ...stateMessages, comentario: e.target.value });
  };

  const handleAcuerdos = (e) => {
    setStateAcuerdos({
      ...stateAcuerdos,
      acuerdo: e.target.value,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addComment();
    }
  };

  // STATE PARA OBTENER EL UUID REUNION - LUIS ADRIAN
  const [stateReunionChat, setStateReunionChat] = useState({
    estadoReunion: "",
    uuidReunion: "",
    hora: "",
    id: null,
    suscrito: undefined,
    idEstatus: 0,
  });

  // Inhabilitar fechas anteriores a la actual
  const currentDate = dayjs();

  const handleFechaReunion = (date) => {
    if (!date) {
      return;
    }
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    const formattedTime = dayjs(date).format("HH:mm");

    setStateModal({
      ...stateModal,
      fechaReunion: formattedDate,
      horaReunion: formattedTime,
    });
  };

  // OBTENER REUNIONES
  const verReuniones = async () => {
    const { execute, data } = await services("GET", "v1/meetings");
    if (execute) {
      setStateReuniones({ ...stateReuniones, solicitudes: data.data });
      // console.log(data.data);
      showToast("success", "Carga de información completa", "bottom-end", 1000);
    }
  };

  const handleShowReagendar = (action, uid) => {
    setStateModal({ ...stateModal, openReagendar: action, uuid: uid });
  };

  // REAGENDAR UNA REUNIÓN
  const reagendarReunion = async () => {
    const formData = new FormData();

    formData.set("uuid", stateModal.uuid);
    formData.set("fecha", stateModal.fechaReunion);
    formData.set("hora", stateModal.horaReunion);

    showLoader();

    const { execute } = await services(
      "PUT",
      "v1/meetings/rescheduleMeeting",
      formData
    );

    if (execute) {
      await showAlertConfirm("success", "Reunión reagendada exitosamente");
      await setStateModal({ ...stateModal, openReagendar: false });
      await verReuniones();
      await reunionDia();
    }
  };

  const getUsuariosInvite = async () => {
    const { execute, data } = await services("GET", "v1/users/invite");
    if (execute) {
      setStateUsuariosInvite({
        ...stateUsuariosInvite,
        usuariosInvite: data.data,
      });
      showToast("success", "Carga de información completa", "bottom-end", 2000);
    }
  };

  const { services } = useApi();

  const [stateReuniones, setStateReuniones] = useState({
    solicitudes: [],
  });

  const [hideLegend, setHideLegend] = useState(false);

  /* CARGAR ARCHIVO */
  const handleArchivo = (event) => {
    const selectedFile = event.target.files[0];
    const maxSize = 25 * 1024 * 1024;

    if (selectedFile) {
      if (selectedFile.type === "application/pdf") {
        if (selectedFile.size <= maxSize) {
          setStateModal({
            ...stateModal,
            ordenDia: selectedFile,
          });
          setNombreArchivo(selectedFile.name);
          setHideLegend(true);
        } else {
          showAlertConfirm(
            "info",
            "Archivo demasiado grande",
            "El archivo supera el límite de 25MB"
          );
        }
      } else {
        showAlertConfirm(
          "info",
          "Información",
          "Por favor ingresa un archivo en formato PDF"
        );
      }
    }
  };

  const handleClickBackdrop = (event) => {
    event.stopPropagation();
  };

  const handleChange = (value) => {
    setStateModal((prevState) => {
      return {
        ...prevState,
        usuarios: typeof value === "string" ? value.split(",") : value,
      };
    });
  };

  // LIMPIAR FORMULARIO
  const limpiarFormulario = () => {
    setStateModal({
      stateModal,
      nombreReunion: "",
      fechaReunion: null,
      horaReunion: null,
      ordenDia: null,
      asuntos: [""],
      usuarios: [],
      tipoReunion: "",
    });
    if (hideLegend) {
      setHideLegend(false);
    }
    setNombreArchivo("");
  };

  const limpiarFormularioEdit = () => {
    setStateModal({
      ...stateModal,
      ordenDia: null,
      asuntos: [""],
      usuarios: [],
      newusuarios: [],
      tipoReunion: "",
    });
    if (hideLegend) {
      setHideLegend(false);
    }
    setNombreArchivo("");
  };

  // VALIDAR DATOS GENERALES DE LA VISTA MODAL DE CREAR REUNION
  const validarDatos = async () => {
    const {
      nombreReunion,
      fechaReunion,
      horaReunion,
      ordenDia,
      asuntos,
      usuarios,
      tipoReunion,
    } = stateModal;
    setStateValidate(true);

    let counter = 0;
    // eslint-disable-next-line
    asuntos.map((element) => {
      if (element.trim() === "") {
        counter++;
      }
    });

    // Validacion nombre de la reunion
    if (nombreReunion === "") {
      showAlertConfirm(
        "info",
        "Información",
        "Por favor ingresa el nombre de la reunión."
      );
    } else if (fechaReunion === null) {
      showAlertConfirm(
        "info",
        "Información",
        "Por favor ingresa la fecha y hora de la reunión"
      );
    } else if (counter > 0) {
      showAlertConfirm(
        "info",
        "Información",
        "Por favor es necesario ingresar todos los asuntos"
      );
    } else if (ordenDia === null) {
      showAlertConfirm(
        "info",
        "Información",
        "La orden del día es obligatoria"
      );
    } else if (tipoReunion === null) {
      showAlertConfirm(
        "info",
        "Información",
        "El tipo de reunión es obligatorio"
      );
    } else {
      await sendData(
        nombreReunion,
        fechaReunion,
        horaReunion,
        ordenDia,
        asuntos,
        usuarios,
        tipoReunion
      );

      setStateModal({
        stateModal,
        nombreReunion: "",
        fechaReunion: null,
        horaReunion: null,
        ordenDia: null,
        asuntos: [""],
        usuarios: [],
        tipoReunion: "",
        // newusuarios: [],
      });
      setNombreArchivo("");
    }
  };

  //FILTRAR ROWS DESDE ITEMS FUERA DE LA TABLA
  const filterRows = (typeFilter) => {
    let estatusFilter = {
      1: "Agendada",
      2: "Finalizada",
      3: "Cancelada",
    };

    let rowsFilter =
      typeFilter !== 0
        ? stateData.initialReuniones.filter(
            (row) => row.estatus === estatusFilter[typeFilter]
          )
        : stateData.initialReuniones;

    setStateData({ ...stateData, filterReuniones: rowsFilter });
  };

  // REGISTRAR UNA NUEVA REUNION
  const sendData = async (
    nombre,
    fecha,
    hora,
    ordenDia,
    asuntos,
    usuarios,
    tipoReunion
  ) => {
    const formData = new FormData();

    formData.set("nombre", nombre);
    formData.set("fecha", fecha);
    formData.set("hora", hora);
    formData.set("ordenDia", ordenDia);
    formData.set("tipo", tipoReunion); //aqui cambio

    // eslint-disable-next-line
    asuntos.map((content, index) => {
      formData.set(`asuntos[${index}][asunto]`, content);
    });
    // eslint-disable-next-line
    usuarios.map((content, index) => {
      formData.set(`usuarios[${index}][id]`, content);
    });

    showLoader();

    const { execute } = await services("POST", "v1/meetings", formData, true);

    if (execute) {
      await showAlertConfirm("success", "Reunión creada exitosamente");
      await getUsuariosInvite();
      verReuniones();
      reunionDia();
    }
  };

  const downloadOrdenDia = async (uuid) => {
    showLoader();
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
      responseType: "blob",
    };

    await axios
      .get(
        `${process.env.REACT_APP_URLAPI}v1/meetings/download-order/${uuid}`,
        config
      )
      .then((response) => {
        showToast(
          "success",
          "Archivo descargado exitosamente",
          "bottom-end",
          1000
        );
        var a = document.createElement("a");
        var url = URL.createObjectURL(response.data);

        a.href = url;
        a.download = `${uuid}.pdf`;
        a.click();
      })
      .catch((error) => {});
  };

  const [modal64, setModal64] = useState({ showModal: false, file: "" });
  const [loading, setLoading] = useState(true);

  const showPDF = async (uuid) => {
    showLoader();
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
      responseType: "blob",
    };

    await axios
      .get(
        `${process.env.REACT_APP_URLAPI}v1/meetings/download-order/${uuid}`,
        config
      )
      .then((response) => {
        setLoading(false);
        // var a = document.createElement("a");
        var url = URL.createObjectURL(response.data);

        // a.href = url;
        // a.download = "file.pdf";
        // a.click();
        handleShowPDF(true, url);
        showToast(
          "success",
          "Archivo descargado exitosamente",
          "bottom-end",
          1000
        );
      })
      .catch((error) => {
        setLoading(false);

        showAlertConfirm("error", `No se ha podido procesar la solicitud.`);
        // Swal.fire({
        //   title: "Error al Crear!",
        //   icon: "error",
        //   text: "",
        //   showCancelButton: false,
        //   confirmButtonColor: "#3085d6",
        //   confirmButtonText: "Ok",
        // });
      });
  };

  const handleShowPDF = (action, file) => {
    setModal64({ ...modal64, showModal: action, file: file });
  };

  // const [modal64, setModal64] = useState({ showModal: false, file: "" });

  // const showPDF = async (uuid) => {
  //   showLoader();
  //   const { execute, data } = await services(
  //     "GET",
  //     `v1/meetings/download-order/${uuid}`
  //   );

  //   if (execute) {
  //     await showToast(
  //       "success",
  //       "Archivo cargado correctamente",
  //       "bottom-end",
  //       1000
  //     );
  //     const { data: file } = data;
  //     const src = "data:application/pdf;base64," + file.pdf;
  //     handleShowPDF(true, src);
  //   }
  // };

  // const handleShowPDF = (action, file) => {
  //   setModal64({ ...modal64, showModal: action, file: file });
  // };

  const [stateModalEdit, setStateModalEdit] = useState({
    open: false,
    openEdit: false,
  });

  const handleShowModalEdit = async (action, buttonAction, uuidReunion) => {
    if (buttonAction === "cancelar") {
      setStateModalEdit({
        ...stateModalEdit,
        open: action,
      });
      // setStateModal({ usuarios: [], newusuarios: [] });
      limpiarFormularioEdit();
    } else {
      await obtenerReunion(uuidReunion).then((response) => {
        setStateModalEdit({
          ...stateModalEdit,
          open: action,
        });
        alAbrirModal(response);
      });
    }
  };

  const alAbrirModal = (dataReunion) => {
    const usuariosInvitados = dataReunion.usuariosInvitados.map(
      (inv) => inv.id
    );
    const newArreglo = stateUsuariosInvite.usuariosInvite.map((user) => {
      return `${user.id} - ${user.nombre} ${user.apellidoPaterno} ${user.apellidoMaterno}`;
    });
    const usuariosNoInvitados = stateUsuariosInvite.usuariosInvite
      .filter((user) => !usuariosInvitados.includes(user.id))
      .map((user) => {
        return `${user.id} - ${user.nombre} ${user.apellidoPaterno} ${user.apellidoMaterno}`;
      });

    setStateModal({
      ...stateModal,
      newusuarios: newArreglo, //TODOS INVITADOS
      usuarios: usuariosNoInvitados, //LOS INVITADOS
    });
  };

  const validarDatosEditar = async () => {};

  // EDITAR REUNION
  const editarReunion = async () => {
    // console.log("HOLAS");

    const { dataReunion, tipo } = stateReunion;
    const { nombre, uuid } = dataReunion;
    const { ordenDia } = stateModal;

    let counter = 0;

    // console.log(stateModal);

    // eslint-disable-next-line
    dataReunion.asuntos.map((element) => {
      if (element === "" || element.asunto === "") {
        counter++;
      }
    });

    if (nombre === "") {
      showAlertConfirm(
        "info",
        "Información",
        "Por favor ingresa el nombre de la reunión."
      );
    } else if (counter > 0) {
      showAlertConfirm(
        "info",
        "Información",
        "Los asuntos no pueden ir vacios"
      );
    } else if (tipo === "") {
      showAlertConfirm(
        "info",
        "Información",
        "Por favor ingresa el tipo de reunión."
      );
    } else {
      const formData = new FormData();
      formData.set("nombre", nombre);
      formData.set("tipo", tipo);

      if (ordenDia != null) {
        formData.set("ordenDia", ordenDia);
      }

      // if (stateModal.usuarios.length === 0) {
      //   idUsersInvite.idUsers.map((content, index) => {
      //     formData.set(`usuarios[${index}][id]`, content);
      //   });
      // } else {

      //stateUsuariosInvite.usuariosInvite;
      //stateReunion.usuariosInvitados
      /*stateModal.usuarios.map((content, index) => {
        formData.set(`usuarios[${index}][id]`, content);
      });*/
      // }
      if (stateModal.usuarios.length === 0) {
        formData.set(`usuarios[]`, []);
      }

      stateModal.usuarios.forEach((subelement) => {
        let idSub = subelement.split(" ")[0];
        // eslint-disable-next-line
        stateUsuariosInvite.usuariosInvite.filter((xd, index) => {
          // eslint-disable-next-line
          if (xd.id == idSub) {
            formData.set(`usuarios[${index}][id]`, xd.id);
          }
        });
      });
      // stateUsuariosInvite.usuariosInvite.map((element) => {
      //   console(
      //     stateModal.newusuarios.filter((subelement) => )
      //   );
      // });
      // stateUsuariosInvite.usuariosInvite.map((usuario, index) => {
      //   console.log(stateReunion.usuariosInvitados, "Invitados");
      //   if (
      //     stateReunion.usuariosInvitados.find((user) => user.id != usuario.id)
      //   ) {
      //     formData.set(`usuarios[${index}][id]`, usuario.id);
      //   }
      // });
      // eslint-disable-next-line
      dataReunion.asuntos.map((content, index) => {
        formData.set(`asuntos[${index}][asunto]`, content.asunto);
      });

      formData.forEach((valor, llave) => {
        // console.log(llave, valor);
      });

      showLoader();
      const { execute, data } = await services(
        "POST",
        `v1/meetings/${uuid}`,
        formData,
        true
      );

      if (execute) {
        handleShowModalEdit(false, "cancelar");
        await showAlertConfirm("success", "Reunión actualizada exitosamente");
        await verReuniones();
        // limpiarFormularioEdit();
        await setStateModal({
          ...stateModal,
          usuarios: [],
          asuntos: [""],
          // newusuarios: [],
        });
        setStateReunion({ ...stateReunion, usuariosInvitados: [] });
      } else {
        showAlertConfirm("info", "Información", data.estatus);
      }
    }
  };

  // AÑADIR NUEVO ASUNTO CHAT
  const handleAddAsuntoChat = (newAsunto) => {
    setInputAsunto({
      ...inputAsunto,
      newAsunto: newAsunto,
    });
  };

  const [inputAsunto, setInputAsunto] = useState({ newAsunto: "" });

  const [loadAffair, setLoadAffair] = useState(false);
  // AGREGAR NUEVO ASUNTO
  const agregarAsunto = async (uuidReunion, id) => {
    const { newAsunto } = inputAsunto;
    const formData = new FormData();
    formData.set("asunto", newAsunto);
    if (inputAsunto.newAsunto.trim().length === 0) {
      setLoadAffair(false);
      showToast("error", "El asunto no puede ir vacío", "bottom-end", 1000);
    } else {
      setLoadAffair(true);
      const { execute } = await services(
        "POST",
        `v1/meetings/${uuidReunion}/add-affair`,
        formData
      );
      if (execute) {
        setLoadAffair(false);
        setInputAsunto({
          ...inputAsunto,
          newAsunto: "",
        });
      }
    }
  };

  // OBTENER REUNION
  const obtenerReunion = async (uuidReunion) => {
    const { execute, data } = await services(
      "GET",
      `v1/meetings/${uuidReunion}`
    );
    // console.log("tipodasd", data.data.tipo.id);

    if (execute) {
      setStateReunion({
        ...stateReunion,
        dataReunion: data.data,
        status: data.data.estatus.estatus,
        asuntos: data.data.asuntos,
        usuariosInvitados: data.data.usuarios,
        tipo: data.data.tipo.id,
      });

      return {
        dataReunion: data.data,
        status: data.data.estatus.estatus,
        asuntos: data.data.asuntos,
        usuariosInvitados: data.data.usuarios,
        tipo: data.data.tipo.id,
      };
    } else {
      navigate("/reuniones");
    }
  };

  const [stateMessages, setStateMessages] = useState({
    messages: [],
    idAsuntoActivo: null,
    comentario: "",
    acuerdo: "",
    activePage: 0,
    urlPageMessages: [],
    lastPage: null,
    acuerdos: [],
    idUser: null,
    activo: true,
  });

  const [stateAsuntos, setStateAsuntos] = useState({
    asuntos: [],
    id: 0,
    asunto: "",
    estatus: null,
  });

  const getInfoReunion = async (parametro) => {
    const { dataReunion } = await obtenerReunion(parametro);

    const { asuntos, id } = dataReunion;

    const token = localStorage.getItem("token");
    const pusherConfig = setPusherConfig(token);

    const pusherAffairsChannel = `private-meeting-affair.${id}`;
    const pusherAffairsEvent = "affair-meeting";

    const channelAffairs = pusherConfig.subscribe(pusherAffairsChannel);
    channelAffairs.bind(pusherAffairsEvent, function (data) {
      updateAffairs(data);
    });

    const pusherAgreementsChannel = `private-meeting-agreement.${id}`;
    const pusherAgreementsEvent = "agreement-meeting";

    const channelAgreements = pusherConfig.subscribe(pusherAgreementsChannel);
    channelAgreements.bind(pusherAgreementsEvent, function (data) {
      updateAgreements(data);
    });
    const pusherMessagesChannel = `private-meeting.${id}`;
    const pusherMessagesEvent = "meeting-comment";

    const channel = pusherConfig.subscribe(pusherMessagesChannel);
    channel.bind(pusherMessagesEvent, function (data) {
      updateMessages(data);
    });

    let idAsunto;
    // eslint-disable-next-line
    asuntos.map((asunto) => {
      if (asunto.estatus) {
        idAsunto = asunto.id;
      }
    });

    const { execute, data } = await services(
      "GET",
      `v1/affairs/${idAsunto}/comments`
    );
    if (execute) {
      setStateMessages({
        ...stateMessages,
        idAsuntoActivo: idAsunto,
        messages: data.data.reverse(),
        activePage: 1,
        urlPageMessages: data.data.links,
        lastPage: data.last_page,
        idUser: data.idUser,
      });
    }
  };

  const handleScroll = async (event) => {
    const container = event.currentTarget;
    // if (event.currentTarget.scrollTop === 0) {
    if (container.scrollTop === 0) {
      const page = stateMessages.activePage + 1;
      if (page <= stateMessages.lastPage) {
        const { execute, data } = await services(
          "GET",
          `v1/affairs/${stateMessages.idAsuntoActivo}/comments?page=${
            stateMessages.activePage + 1
          }`
        );
        if (execute) {
          const currentMessages = stateMessages.messages;
          const afterMessages = data.data;
          const allMessages = afterMessages.concat(currentMessages);
          setStateMessages({
            ...stateMessages,
            messages: allMessages,
            activePage: stateMessages.activePage + 1,
          });
          container.scrollTop = 20;
        }
      }
    }
  };

  const containerMessagesRef = useRef(null);

  const updateMessages = (newMessage) => {
    const currentMessage = {
      id: null,
      comentario: newMessage.comment,
      fecha: newMessage.date,
      hora: newMessage.hour,
      idUser: newMessage.idUser,
      nombre: newMessage.nameUser,
    };
    setStateMessages((prevState) => ({
      //Cargar nuevo mensaje
      ...prevState,
      messages: [...prevState.messages, currentMessage],
    }));
    setLoad(false);
  };

  const updateAgreements = (agreement) => {
    const currentAgreement = {
      id: agreement.agreement.id,
      acuerdo: agreement.agreement.acuerdo,
      asunto_id: agreement.agreement.asunto_id,
    };
    setStateAcuerdos((prevState) => ({
      //Cargar nuevo mensaje
      ...prevState,
      acuerdos: [...prevState.acuerdos, currentAgreement],
    }));
  };

  const updateAffairs = (data) => {
    const currentAffair = {
      id: data.affair.id,
      asunto: data.affair.asunto,
      estatus: data.affair.estado,
    };
    setStateReunion((prevState) => ({
      ...prevState,
      asuntos: [...prevState.asuntos, currentAffair],
    }));
  };

  const [load, setLoad] = useState(false);

  const currentUser = localStorage.getItem("id");

  const addComment = async () => {
    const { comentario, idAsuntoActivo } = stateMessages;
    const formData = new FormData();
    formData.set("comentario", comentario);

    if (stateMessages.comentario.trim() === "") {
      showToast("error", "El mensaje no puede ir vacío", "bottom-end", 1000);
      setLoad(false);
    } else {
      setLoad(true);
      const { execute } = await services(
        "POST",
        `v1/affairs/${idAsuntoActivo}/add-comment`,
        formData
      );
      if (execute) {
        setStateMessages({
          ...stateMessages,
          comentario: "",
          idUser: currentUser,
        });
      }
    }
  };

  const [stateAcuerdos, setStateAcuerdos] = useState({
    acuerdos: [],
    acuerdo: "",
  });

  const obtenerAcuerdos = async (idAsunto) => {
    const { execute, data } = await services(
      "GET",
      `v1/affairs/${idAsunto}/agreements`
    );
    if (execute) {
      setStateAcuerdos({ acuerdos: data.data });
    }
  };

  const [loadAgreement, setLoadAgreement] = useState(false);

  const addAgreement = async (params, id) => {
    // Encontrar
    const { idAsuntoActivo } = stateMessages;
    const { acuerdo } = stateAcuerdos;
    const formData = new FormData();
    formData.set("acuerdo", acuerdo);

    if (
      stateAcuerdos.acuerdo?.trim() === "" ||
      stateAcuerdos.acuerdo === undefined
    ) {
      showToast("error", "El acuerdo no puede ir vacío", "bottom-end", 1000);
      setLoadAgreement(false);
    } else {
      setLoadAgreement(true);
      const { execute } = await services(
        "POST",
        `v1/affairs/${idAsuntoActivo}/add-agreement`,
        formData
      );
      setLoadAgreement(false);
      if (execute) {
      }
    }
  };

  // ENVIAR DATOS CARGADOS AL MODAL EDITAR
  const setCurrentReuniones = (nombre) => {
    setStateModal({
      nombreReunion: nombre,
    });
  };

  // CAMBIAR STATUS DE LAS REUNIONES
  const changeStatus = async (uidReunion) => {
    const formData = new FormData();
    formData.set("estatus", 4);
    const confirmation = await showAlertConfirm(
      "question",
      "Confirmación",
      `¿Estás seguro de que desea celebrar la reunión?`,
      true
    );

    if (confirmation) {
      showLoader();
      const { execute } = await services(
        "POST",
        `v1/meetings/${uidReunion}/change-status
      `,
        formData
      );
      if (execute) {
        verReuniones();
        handleShowAddAsunto(false);
        showAlertConfirm(
          "success",
          "El estatus de la reunión ha sido cambiado"
        );
        limpiarFormulario();
      }
    }
  };

  const handleShowAddAsunto = (action, uid) => {
    setStateModal({
      ...stateModal,
      openAddAsunto: action,
      uuid: uid,
    });
  };

  /* OBTENER REUNION DEL DIA */
  const reunionDia = async () => {
    const { execute, data } = await services("GET", "v1/meetings/today");
    if (execute) {
      setStateReunionChat({
        ...stateReunionChat,
        estadoReunion: data.data.estatus.estatus,
        uuidReunion: data.data.uuid,
        hora: data.data.hora,
        id: data.data.id,
        idEstatus: data.data.estatus.id,
      });
    } else {
      setStateReunionChat({
        ...stateReunionChat,
        estadoReunion: "",
        uuidReunion: "",
        hora: "",
      });
    }
  };

  const cerrarAsunto = async (idAsunto, params) => {
    const formData = new FormData();
    formData.set("estatus", 0);
    const confirmation = await showAlertConfirm(
      "question",
      "Confirmación",
      `¿Está seguro de cerrar este asunto?`,
      true
    );

    if (confirmation) {
      showLoader();
      const { execute } = await services(
        "PUT",
        `v1/affairs/${idAsunto}/update-status
      `,
        formData
      );
      if (execute) {
        obtenerReunion(params);
        showAlertConfirm("success", "El asunto ha sido cerrado");
      }
    }
  };

  return {
    stateReuniones,
    setStateReuniones,
    stateModal,
    setStateModal,
    modalOpen,
    handleClose,
    handleNombreReunion,
    handleFechaReunion,
    handleEstatusReunion,
    stateData,
    setStateData,
    filterRows,
    handleShowModal,
    handleArchivo,
    validarDatos,
    handleAddAsunto,
    handleRemoveAsunto,
    asuntos,
    setAsuntos,
    currentDate,
    stateValidate,
    setStateValidate,
    nombreArchivo,
    handleAsuntos,
    handleAsuntoChange,
    handleChange,
    handleShowReagendar,
    verReuniones,
    reagendarReunion,
    limpiarFormulario,
    handleClickBackdrop,
    downloadOrdenDia,
    modal64,
    setModal64,
    handleShowPDF,
    showPDF,
    handleShowModalEdit,
    editarReunion,
    stateModalEdit,
    obtenerReunion,
    setCurrentReuniones,
    stateReunion,
    changeStatus,
    handleShowAddAsunto,
    stateReunionChat,
    setStateReunionChat,
    agregarAsunto,
    handleAddAsuntoChat,
    handleNombreReunionEdit,
    reunionDia,
    limpiarFormularioEdit,
    validarDatosEditar,
    handleAsuntoChangeAdd,
    handleRemoveAsuntoAdd,
    handleAddAsuntoAdd,
    setStateReunion,
    getInfoReunion,
    stateMessages,
    setStateMessages,
    addComment,
    handleComment,
    handleAcuerdos,
    addAgreement,
    containerMessagesRef,
    handleScroll,
    obtenerAcuerdos,
    stateAcuerdos,
    load,
    handleKeyDown,
    cerrarAsunto,
    stateAsuntos,
    isSubscribed,
    alertMeetingExcecute,
    loadAgreement,
    loadAffair,
    inputAsunto,
    setInputAsunto,
    setStateAcuerdos,
    setLoadAgreement,
    setLoadAffair,
    loading,
    hideLegend,
    getUsuariosInvite,
    stateUsuariosInvite,
    handleTipoReunion,
    handleTipoReunionEdit,
    setIsSubscribed,
    setAlertMeetingExcecute,
    setStateAsuntos,
  };
};
export default useReuniones;
