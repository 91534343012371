import React from "react";

export default function NotFound() {
  return (
    <div
      style={{
        height: "calc(80vh - 80px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="container"
    >
      <div>
        <h1 className="mt-5">
          <strong>!Oops! 😢</strong>
        </h1>
        <h2 className="fs-2">
          Lo sentimos, no podemos encontrar la página que solicitaste, por favor
          intente más tarde.
        </h2>
      </div>
    </div>
  );
}
