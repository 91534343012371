import * as React from "react";
import {
  CssBaseline,
  Typography,
  Paper,
  List,
  ListItemText,
  TextField,
  IconButton,
  AccordionSummary,
  TextareaAutosize,
  Dialog,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { Send, PictureAsPdf, Add, Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import LockIcon from "@mui/icons-material/Lock";
import useReunion from "../customHooks/useReunion";
import useReuniones from "../customHooks/useReuniones";
import useAlerts from "../../../services/useAlerts";
import { useParams } from "react-router-dom";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import BlockIcon from "@mui/icons-material/Block";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Menu from "../../general/menu";
import { useNavigate } from "react-router-dom";
import useApi from "../../../services/useApi";
import { useMediaQuery } from "@mui/material";
import echoX from "../../../services/echo";
import Swal from "sweetalert2";

const styleModal = {
  width: "100%",
};
export default function NewReunionView() {
  const isMobile = useMediaQuery("(max-width:768px)");
  const isMobileHeight = useMediaQuery("(max-height:850px)");
  const { parametro } = useParams();
  const navigate = useNavigate();
  const { services } = useApi();
  const {
    stateModal,
    stateReunion,
    handleAddAsuntoChat,
    // obtenerReunion,
    stateMessages,
    setStateMessages,
    setStateReunion,
    handleComment,
    handleAcuerdos,
    handleScroll,
    stateAcuerdos,
    loadAgreement,
    loadAffair,
    setLoadAgreement,
    inputAsunto,
    setInputAsunto,
    setStateAcuerdos,
    setLoadAffair,
  } = useReuniones();

  const makeid = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  const {
    showPDF,
    finalizarReunion,
    modalOpen,
    stateModalReunion,
    stateAcuerdosModal,
    setStateAcuerdosModal,
    setStateModalReunion,
  } = useReunion();

  const rolUser = localStorage.getItem("rol");
  const [currentUser, setCurrentUser] = useState("");
  const [nombreAsunto] = useState("");
  const status = stateReunion.status;
  const { showAlertConfirm, showToast } = useAlerts();

  const [scrollMessages, setScrollMessages] = useState({ messages: [] });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setCurrentUser(localStorage.getItem("id"));
      getInfoReunion2(stateReunion);
    }
    return () => {
      isMounted = false;
    };
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  const listRef = useRef(null);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [stateMessages.activePage]);
  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    const target = document.getElementById("messages");
    const container = target.parentNode;
    const scrollTop =
      target.offsetTop - container.clientHeight + target.clientHeight;

    container.scrollTo({
      top: scrollTop,
      behavior: "smooth",
    });
  }, [scrollMessages.messages]);

  const getInfoAcuerdos = async (data) => {
    let getLastElementOnTrue = data?.asuntos.filter(
      (element) => element.estatus === true
    );
    let ultimoElemento = getLastElementOnTrue[0]?.id;
    if (ultimoElemento !== undefined) {
    }
  };

  // const getMessages = async () => {
  //   const { dataReunion } = await obtenerReunion(parametro);

  //   const { asuntos, id } = dataReunion;

  //   let idAsunto;
  //   asuntos.map((asunto) => {
  //     if (asunto.estatus) {
  //       idAsunto = asunto.id;
  //     }
  //   });

  //   const { execute, data } = await services(
  //     "GET",
  //     `v1/affairs/${idAsunto}/comments`
  //   );
  //   if (execute) {
  //     setStateMessages({
  //       ...stateMessages,
  //       idAsuntoActivo: idAsunto,
  //       messages: data.data.reverse(),
  //       activePage: 1,
  //       urlPageMessages: data.data.links,
  //       lastPage: data.last_page,
  //       idUser: data.idUser,
  //     });
  //   }
  // };

  const getInfoGeneral = async () => {
    const { execute, data } = await services("GET", `v1/meetings/${parametro}`);
    await getInfoAcuerdos(data.data);
    if (execute) {
      setStateReunion({
        ...stateReunion,
        dataReunion: { ...stateReunion.dataReunion, nombre: data.data.nombre },
        asuntos: data.data.asuntos,
      });
    }
  };

  const handleAsuntoAgregar = (value) => {
    handleAddAsuntoChat(value);
  };

  const handleclickAsunto = async () => {
    const formData = {
      asunto: inputAsunto.newAsunto,
    };
    setLoadAffair(true);
    if (inputAsunto.newAsunto.length > 200) {
      showToast(
        "info",
        "El asunto no puede contener más de 200 caracteres.",
        "bottom-end",
        1000
      );
    } else if (inputAsunto.newAsunto === "") {
      showToast("error", "El asunto no puede ir vacío", "bottom-end", 1000);
      setLoadAffair(false);
    } else {
      const { execute } = await services(
        "POST",
        `v1/meetings/${parametro}/add-affair`,
        formData
      );
      if (execute) {
        setLoadAffair(false);
        getInfoGeneral();
        setInputAsunto({ newAsunto: "" });
      } else {
        setLoadAffair(false);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addComment();
    }
  };

  const addComment = async () => {
    let getLastElementOnTrue = stateReunion?.asuntos.filter(
      (element) => element.estatus === true
    );
    let ultimoElemento = getLastElementOnTrue[0]?.id;

    const body = {
      comentario: stateMessages.comentario,
    };

    if (stateMessages.comentario.trim() === "") {
      showToast("error", "El mensaje no puede ir vacío", "bottom-end", 1000);
      setLoad(false);
    } else {
      setLoad(true);
      const { execute } = await services(
        "POST",
        `v1/affairs/${ultimoElemento}/add-comment`,
        body
      );
      if (execute) {
        // console.log(currentUser);
        setLoad(false);
        setStateMessages({
          ...stateMessages,
          comentario: "",
          idUser: currentUser,
        });
      } else {
        setLoad(false);
      }
    }
  };

  const agregarAcuerdo = async (param) => {
    let getLastElementOnTrue = stateReunion?.asuntos.filter(
      (element) => element.estatus === true
    );
    let ultimoElemento = getLastElementOnTrue[0]?.id;

    const formData = {
      acuerdo: stateAcuerdos.acuerdo,
    };

    setLoadAgreement(true);

    if (stateAcuerdos.acuerdo === "") {
      showToast("error", "El asunto no puede ir vacío", "bottom-end", 500);
      setLoadAgreement(false);
    } else {
      const { execute } = await services(
        "POST",
        `v1/affairs/${ultimoElemento}/add-agreement`,
        formData
      );

      if (execute) {
        setLoadAgreement(false);
        setStateAcuerdos({
          ...stateAcuerdos,
          acuerdo: "",
        });
        getInfoGeneral();
      } else {
        setLoadAgreement(false);
      }
    }
  };

  const validationCloseAsunto = async (reunion) => {
    let getLastElementOnTrue = stateReunion?.asuntos.filter(
      (element) => element.estatus === true
    );
    let ultimoElemento = getLastElementOnTrue[0]?.id;
    const formData = {
      estatus: 0,
    };
    if (stateAcuerdos.acuerdos?.length !== 0) {
      const confirmation = await showAlertConfirm(
        "question",
        "Confirmación",
        `¿Está seguro de cerrar este asunto?`,
        true
      );

      if (confirmation) {
        const { execute } = await services(
          "PUT",
          `v1/affairs/${ultimoElemento}/update-status
      `,
          formData,
          true
        );
        if (execute) {
          getInfoGeneral();
          showAlertConfirm("success", "El asunto ha sido cerrado");
        }
      }
    }
  };

  const handleModalOpen = async (reunion) => {
    setStateModalReunion({ ...stateModal, open: true });
    const { execute, data } = await services(
      "GET",
      `v1/affairs/${reunion.id}/agreements`
    );
    if (execute) {
      setStateAcuerdosModal({
        listaAcuerdos: data.data,
      });
      setStateModalReunion({
        ...stateModalReunion,
        open: true,
        nombreDeReunion: reunion.asunto,
      });
    }
  };

  const updateAffairs = (data, asuntos, nombre) => {
    const currentAffair = {
      id: data.affair.id,
      asunto: data.affair.asunto,
      estatus: data.affair.estado,
    };
    // let newArreglo;
    if (data.affair.meeting) {
      asuntos.forEach((element, index) => {
        if (element.id === data.affair.id) {
          if (!data.affair.estado) {
            element.estatus = false;
          } else {
            element.estatus = true;
            asuntos[index - 1].estatus = false;
          }
        }
        return element;
      });

      setStateAcuerdos({
        acuerdos: [],
        acuerdo: "",
      });
      setStateMessages({
        messages: [],
        comentario: "",
      });
    } else {
      asuntos.push(currentAffair);
    }
    asuntos.filter((element) => element.estatus === true);

    setStateReunion((prevState) => ({
      ...stateReunion,
      asuntos: asuntos,
      dataReunion: { nombre: nombre },
    }));
  };

  const updateAgreements = (agreement) => {
    const currentAgreement = {
      id: agreement.agreement.id,
      acuerdo: agreement.agreement.acuerdo,
      asunto_id: agreement.agreement.asunto_id,
    };
    setStateAcuerdos((prevState) => ({
      // Cargar nuevo mensaje
      acuerdo: "",
      acuerdos: [...prevState.acuerdos, currentAgreement],
    }));
  };

  const updateMessages = (newMessage) => {
    const currentMessage = {
      id: null,
      comentario: newMessage.comment,
      fecha: newMessage.date,
      hora: newMessage.hour,
      idUser: newMessage.idUser,
      nombre: newMessage.nameUser,
    };
    setStateMessages((prevState) => ({
      //Cargar nuevo mensaje
      ...prevState,
      messages: [...prevState.messages, currentMessage],
    }));

    setScrollMessages((prevState) => ({
      ...setScrollMessages,
      messages: [],
    }));

    setLoad(false);
  };

  const [load, setLoad] = useState(false);

  const getInfoReunion2 = async () => {
    // Api para rellenar Campos de Asuntos
    const { execute, data } = await services("GET", `v1/meetings/${parametro}`);
    if (data.mensaje) {
      Swal.fire({
        title: "La reunión ha sido finalizada",
        icon: "warning",
        confirmButtonColor: "#A70631",
        confirmButtonText: "Volver a reuniones",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/reuniones");
        }
      });
      return;
    } else if (execute && !data.mensaje) {
      setStateReunion({
        ...stateReunion,
        dataReunion: {
          ...stateReunion.dataReunion,
          nombre: data.data.nombre,
          status: data.data.estatus,
        },
        asuntos: data.data.asuntos,
      });
    }
    // Api para rellenar Campos de Acuerdos
    let getLastElementOnTrue = data.data?.asuntos.filter(
      (element) => element.estatus === true
    );
    let ultimoElemento = getLastElementOnTrue[0]?.id;
    let dataAcuerdos = {
      data: { data: [] },
    };
    if (ultimoElemento !== undefined) {
      dataAcuerdos = await services(
        "GET",
        `v1/affairs/${getLastElementOnTrue[0].id}/agreements`
      );
      if (dataAcuerdos.execute) {
        setStateAcuerdos({
          ...stateAcuerdos,
          acuerdos: dataAcuerdos.data.data,
          acuerdo: "",
        });
      }
    }

    /* AQUI COMIENZA PUSHER */

    // Pusher para Asusntos
    const token = localStorage.getItem("token");
    const echo = echoX(token);
    const { asuntos, id } = data.data;

    const pusherAffairsChannel = `private-meeting-affair.${id}`;

    const pusherAffairsEvent = "affair-meeting";
    const channelAffairs = echo.channel(pusherAffairsChannel);

    channelAffairs.listen(`.${pusherAffairsEvent}`, (dataM) => {
      updateAffairs(dataM, asuntos, data.data.nombre);
    });

    // /* PUsher para acuerdos */
    const pusherAgreementsChannel = `private-meeting-agreement.${id}`;
    const pusherAgreementsEvent = ".agreement-meeting";
    const channelAgreements = echo.channel(pusherAgreementsChannel);
    channelAgreements.listen(pusherAgreementsEvent, function (dataAc) {
      updateAgreements(dataAc);
    });

    const channelName = `private-status-meeting.${id}`;

    const channelSuscription = echo.channel(channelName);
    channelSuscription.listen(".meeting-status", function (data) {
      if (
        data.status === "Finalizada" &&
        window.location.pathname.split("/")[1] === "reunion"
      ) {
        Swal.fire({
          confirmButtonColor: "#A70631",
          title: "El administrador o moderador ha finalizado la reunión",
          icon: "info",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/reuniones");
          }
        });
      }
    });

    const pusherMessagesChannel = `private-meeting.${id}`;
    const pusherMessagesEvent = ".meeting-comment";
    const channelMensajes = echo.channel(pusherMessagesChannel);
    channelMensajes.listen(pusherMessagesEvent, function (data) {
      updateMessages(data);
    });
    const apiComments = await services(
      "GET",
      `v1/affairs/${ultimoElemento}/comments`
    );
    if (apiComments.execute) {
      setStateMessages({
        ...stateMessages,
        idAsuntoActivo: ultimoElemento,
        messages: apiComments.data.data.reverse(),
        activePage: 1,
        urlPageMessages: apiComments.data.data.links,
        lastPage: apiComments.data.last_page,
        idUser: apiComments.data.idUser,
      });
      setScrollMessages((prevState) => ({
        ...setScrollMessages,
        messages: [],
      }));
    }
  };

  return (
    <>
      <Menu status={status} />
      {/* <div ref={parentRef} className="bg-gris-fondo"> */}
      <div className="bg-gris-fondo">
        <Grid container xs={12} className="pe-5">
          <Grid
            item
            xs={8}
            md={7}
            className={
              isMobile
                ? "py-4 position-relative d-flex justify-content-start ps-4"
                : "py-4 position-relative d-flex justify-content-end"
            }
          >
            <h4 className="text-center m-0">
              <strong>{stateReunion.dataReunion.nombre}</strong>
            </h4>
          </Grid>
          <Grid
            item
            xs={4}
            md={5}
            className={
              isMobile
                ? "py-4 position-relative d-flex justify-content-end"
                : "py-4 position-relative d-flex justify-content-center"
            }
          >
            <LoadingButton
              className="position-absolute mt-3 end-0 top-0"
              type="submit"
              variant="contained"
              startIcon={<PictureAsPdf />}
              loading={false}
              sx={{
                mt: 1,
                mb: 2,
                alignItems: "center",
                backgroundColor: "#a70631",
              }}
              onClick={() => showPDF()}
            >
              Orden del Día
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          className={isMobileHeight ? "mt-4 pe-5 px-5" : "mt-3 pe-5 px-5"}
        >
          {/* AÑADIR ASUNTOS EN EL CHAT - LUIS ADRIAN */}
          <Grid item xs={12} md={6} className={isMobile ? "pe-1" : "pe-4"}>
            {/* MOSTRAR ASUNTOS DE LA REUNION - LUIS ADRIAN */}

            {stateReunion.asuntos?.map((reunion, index) => {
              return (
                <div
                  className={`text-break ${
                    reunion.estatus === false || reunion.estatus === null
                      ? "opacity-disabled pe-none "
                      : null
                  } card mt-2 shadow-sm position-relative asuntos`}
                  key={makeid(9)}
                >
                  {reunion.estatus === true ||
                  reunion.estatus === null ? null : (
                    <IconButton
                      title="Ver acuerdos atendidos"
                      className="pe-auto btn-view-acuerdos position-absolute "
                      onClick={() => {
                        handleModalOpen(reunion);
                      }}
                    >
                      <ManageSearchIcon />
                    </IconButton>
                  )}

                  <AccordionSummary
                    aria-controls="panel1-content"
                    id={reunion.id}
                  >
                    <p>
                      {`Asunto no. ${index + 1}`} <br /> {reunion.asunto}
                    </p>
                    {reunion.estatus === false ? (
                      <span className="text-danger position-absolute asunto-cerrado">
                        CERRADO
                      </span>
                    ) : reunion.estatus === true ? (
                      <span className="text-success position-absolute asunto-cerrado">
                        ACTIVO
                      </span>
                    ) : null}
                  </AccordionSummary>

                  {rolUser === "Participante" ||
                  rolUser === "Invitado" ? null : (
                    <IconButton
                      className={`bg-rojo border-0 position-absolute p-1 top-0 end-0 m-2 rounded-circle`}
                      onClick={() => {
                        validationCloseAsunto(reunion);
                      }}
                      disabled={!reunion.estatus}
                    >
                      {reunion.estatus === null ? (
                        <LockIcon />
                      ) : reunion.estatus === false ? (
                        <DoneAllIcon />
                      ) : stateAcuerdos.acuerdos?.length === 0 ? (
                        <BlockIcon
                          className="text-danger"
                          titleAccess="Añade un acuerdo para poder cerrar este asunto"
                        />
                      ) : (
                        <DoneIcon />
                      )}
                    </IconButton>
                  )}
                </div>
              );
            })}

            <br />
            {stateModal.asuntos.map((asunto, index) =>
              rolUser === "Participante" || rolUser === "Invitado" ? null : (
                <>
                  <div className="position-relative">
                    <button
                      className="cta--gris rounded bg-transparent btn-add-asunto-reunion position-absolute"
                      onClick={handleclickAsunto}
                      disabled={loadAffair}
                      load="true"
                    >
                      <Add />
                    </button>
                  </div>
                  <div className="position-relative">
                    <TextField
                      className={
                        isMobile
                          ? "pe-5 mt-3 w-input mb-4"
                          : " pe-3 mt-3 w-input"
                      }
                      // InputProps={{ disableUnderline: true }}
                      fullWidth
                      value={inputAsunto.newAsunto}
                      size="small"
                      id={`asunto-${index}`}
                      label={`Añadir asunto`}
                      inputProps={{ maxLength: 200 }}
                      type="text"
                      variant="filled"
                      onChange={(event) => {
                        handleAsuntoAgregar(event.target.value);
                      }}
                    />
                  </div>
                </>
              )
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ position: "relative", marginBottom: "100px" }}
          >
            <Grid
              item
              xs={12}
              md={12}
              sx={{ overflowY: "auto", height: "650px", background: "white" }}
              // InputProps={{ disableUnderline: true }}
              onScroll={handleScroll}
              className=" d-flex flex-column"
            >
              {/* <div ref={childRef}> */}
              <div ref={listRef} id="messages">
                <React.Fragment>
                  <CssBaseline />
                  <Paper square sx={{ pb: "5px" }} className="box-shadow">
                    <Typography
                      variant="h5"
                      gutterBottom
                      component="div"
                      className="box-shadow"
                      sx={{ p: 2, pb: 0 }}
                    ></Typography>
                    <List sx={{ mb: 2 }}>
                      {stateMessages.messages?.map(
                        ({ id, nombre, comentario, hora, idUser }) => (
                          <React.Fragment key={id}>
                            <div className="mb-2 box-shadow">
                              {currentUser == idUser ? (
                                <Grid container>
                                  <Grid item md={4} xs={4}></Grid>
                                  <Grid item md={8} xs={8}>
                                    <div
                                      className="text-break cardMessages mb-2 bg-rojo text-white chat"
                                      style={{ paddingRight: "20px" }}
                                    >
                                      <ListItemText
                                        style={{ fontWeight: "bold" }}
                                        primary={nombre}
                                        secondary={
                                          <span
                                            style={{
                                              color: "white",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            {comentario}
                                          </span>
                                        }
                                        className="text-white"
                                      />
                                      <div className="row">
                                        <div className="col-md-12 text-end ">
                                          <span style={{ opacity: 0.7 }}>
                                            {hora}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Grid container>
                                  <Grid item md={8} xs={8}>
                                    <div
                                      className="cardMessages mb-2 bg-gray-45 text-white chatLeft"
                                      style={{ marginLeft: "20px" }}
                                    >
                                      <ListItemText
                                        primary={nombre}
                                        secondary={
                                          <span style={{ color: "white" }}>
                                            {comentario}
                                          </span>
                                        }
                                      />
                                      <div className="row">
                                        <div className="col-md-12 text-end text-white">
                                          <span style={{ opacity: 0.4 }}>
                                            {hora}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item md={4} xs={4}></Grid>
                                </Grid>
                              )}
                            </div>
                          </React.Fragment>
                        )
                      )}
                      {/* <div ref={messageEndRef} /> */}
                    </List>
                  </Paper>
                </React.Fragment>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              className="text-right-custom"
              style={{
                position: "sticky",
                bottom: 0,
                left: 0,
                width: "100%",
                zIndex: 999,
              }}
            >
              <TextField
                InputProps={{
                  inputProps: { maxLength: 5000 },
                  // disableUnderline: true,
                  endAdornment: (
                    <LoadingButton
                      type="submit"
                      aria-label="search"
                      loading={
                        load ||
                        !stateReunion.asuntos.some((asunto) => asunto.estatus)
                      }
                      onClick={() => addComment()}
                    >
                      <Send />
                    </LoadingButton>
                  ),
                }}
                fullWidth
                disabled={
                  load || !stateReunion.asuntos.some((asunto) => asunto.estatus)
                }
                className="chat-end-message"
                style={{ background: "white" }}
                size="small"
                id="messages"
                label="Escribe algo.."
                type="text"
                value={stateMessages.comentario}
                variant="outlined"
                onChange={handleComment}
                onKeyDown={handleKeyDown}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={isMobile ? "pe-5 pb-3" : "mt-5 pe-5 pb-3"}>
          <Grid item xs={12} md={7} className="ps-5 pe-3">
            <h2>
              Acuerdos del asunto <strong>{nombreAsunto}</strong>
            </h2>
            {stateAcuerdos.acuerdos.map((acuerdo, index) => {
              return (
                <div
                  className="card mt-2 shadow-sm position-relative mb-3 asuntos"
                  key={index}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id={acuerdo.id}
                  >
                    {`Acuerdo no. ${index + 1}`} <br />
                    {acuerdo.acuerdo}
                  </AccordionSummary>
                </div>
              );
            })}
          </Grid>
          {rolUser === "Participante" || rolUser === "Invitado" ? null : (
            <Grid item xs={12} md={5}>
              <div
                className={isMobile ? "card ms-4" : "card"}
                style={{ height: "250px" }}
              >
                <TextareaAutosize
                  minRows={9}
                  maxRows={9}
                  className={isMobile ? "mt-2 mb-2" : "mt-2 mb-2 mr-3 ml-3"}
                  variant="filled"
                  style={{
                    width: "100%",
                    resize: "none",
                    padding: "8px",
                    border: "none",
                    background: "#DADDE1",
                    color: "black",
                  }}
                  placeholder="Escribe un acuerdo.."
                  value={stateAcuerdos.acuerdo}
                  onChange={(event) => handleAcuerdos(event)}
                />
                <div className="text-right-custom">
                  <LoadingButton
                    // type="submit"
                    size="small"
                    variant="contained"
                    loading={loadAgreement}
                    disabled={
                      loadAgreement ||
                      !stateReunion.asuntos.some((asunto) => asunto.estatus)
                    }
                    sx={{
                      mt: 1,
                      mb: 2,
                      alignItems: "right",
                      backgroundColor: "#A70631",
                      width: isMobile ? "100%" : "40%",
                    }}
                    onClick={() => {
                      agregarAcuerdo(parametro);
                    }}
                  >
                    Guardar Acuerdo
                  </LoadingButton>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
        {rolUser === "Participante" || rolUser === "Invitado" ? null : (
          <Grid
            container
            className={
              isMobile
                ? "mt-5 pe-4 text-center-custom"
                : "mt-5 pe-5 text-center-custom"
            }
          >
            <div className="text-center-custom">
              <LoadingButton
                // type="submit"
                variant="contained"
                loading={false}
                disabled={stateReunion.asuntos.some((asunto) => asunto.estatus)}
                sx={{
                  mt: 1,
                  mb: 2,
                  alignItems: "right",
                  backgroundColor: "#a70631",
                  width: "100%",
                }}
                onClick={() => {
                  finalizarReunion();
                }}
              >
                Finalizar Reunión
              </LoadingButton>
            </div>
          </Grid>
        )}
      </div>

      {/* MODAL CREAR REUNION */}
      {stateModalReunion.open ? (
        <Dialog
          open={() => modalOpen(true, 0, "hola")}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          scroll="paper"
          maxWidth="md"
        >
          <Box
            className="p-2 p-md-4 col-10 col-md-6 pt-4"
            component="form"
            sx={styleModal}
            noValidate
          >
            <h4
              className="mb-4 asuntos"
              style={{ color: "#a70631", fontSize: "25px" }}
            >
              Acuerdos del asunto:
              <strong>{stateModalReunion.nombreDeReunion}</strong>
            </h4>
            {stateAcuerdosModal.listaAcuerdos.length === 0 ? (
              <p>Cargando acuerdos..</p>
            ) : (
              stateAcuerdosModal.listaAcuerdos.map((acuerdo, index) => (
                <ul>
                  <li className="asuntos " key={index}>
                    <strong>{acuerdo.acuerdo}</strong>
                  </li>
                </ul>
              ))
            )}
            <Grid containter>
              <Grid item xs={12} md={12} className="text-center-custom">
                <LoadingButton
                  variant="contained"
                  endIcon={<Close />}
                  loading={false}
                  sx={{
                    mt: 3,
                    mb: 2,
                    mr: 1,
                    ml: 1,
                    alignItems: "center",
                    backgroundColor: "#a70631",
                  }}
                  onClick={() => {
                    modalOpen(false);
                    setStateAcuerdosModal({
                      ...stateAcuerdosModal,
                      listaAcuerdos: [],
                    });
                  }}
                >
                  Cerrar
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      ) : null}
    </>
  );
}
