import useAlerts from "../../../services/useAlerts";
import useApi from "../../../services/useApi";
import { useParams } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";

const useReunion = () => {
  const [stateModalReunion, setStateModalReunion] = useState({
    open: false,
    nombreDeReunion: "Hola",
  });

  // CÓDIGO DANY
  const { showLoader, showToast } = useAlerts();
  const { services } = useApi();
  const { parametro } = useParams();
  const [stateAcuerdosValidation, setStateAcuerdosValidation] = useState({
    dataAcuerdos: [],
  });
  const [stateAcuerdosModal, setStateAcuerdosModal] = useState({
    listaAcuerdos: [],
  });

  /* MODAL PARA VER ACUERDOS */
  const modalOpen = (flag, id, nombreReunion) => {
    setStateModalReunion({
      ...stateModalReunion,
      open: flag,
      nombreDeReunion: nombreReunion,
    });
  };

  // const showPDF = async () => {
  //   showLoader();
  //   const { execute, data } = await services(
  //     "GET",
  //     `v1/meetings/download-order/${parametro}`
  //   );

  //   if (execute) {
  //     await showToast(
  //       "success",
  //       "Archivo cargado correctamente",
  //       "bottom-end",
  //       1000
  //     );
  //     const { data: file } = data;
  //     const src = "data:application/pdf;base64," + file.pdf;
  //     const newWindow = window.open();
  //     newWindow.document.write(
  //       `<embed width="100%" height="100%" src="${src}" type="application/pdf" />`
  //     );
  //   }
  // };

  const showPDF = async () => {
    showLoader();
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
      responseType: "blob",
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URLAPI}v1/meetings/download-order/${parametro}`,
        config
      );

      const url = URL.createObjectURL(response.data);

      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank";

      document.body.appendChild(a);
      a.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 1000);

      showToast("success", "Archivo cargado exitosamente", "bottom-end", 1000);
    } catch (error) {
      showToast(
        "error",
        "Hubo un problema al cargar el archivo, por favor intenta nuevamente.",
        "bottom-end",
        1000
      );
    }
  };

  // AGREGAR NUEVO ASUNTO
  const finalizarReunion = async () => {
    const formData = new FormData();
    formData.set("estatus", 3);

    Swal.fire({
      title: "¿Desea finalizar la reunión?",
      icon: "warning",
      confirmButtonColor: "#A70631",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        const response = services(
          "POST",
          `v1/meetings/${parametro}/change-status`,
          formData
        );

        response
          .then((res) => {
            Swal.fire({
              title: `${res.data.mensaje}`,
              confirmButtonText: "Aceptar",
              icon: "success",
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Ocurrio un problema!",
              text: `${error}`,
              icon: "error",
            });
          });
      }
    });
  };

  return {
    showPDF,
    finalizarReunion,
    stateModalReunion,
    modalOpen,
    stateAcuerdosValidation,
    stateAcuerdosModal,
    setStateAcuerdosModal,
    setStateModalReunion,
    setStateAcuerdosValidation,
  };
};
export default useReunion;
