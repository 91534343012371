import { useState, useEffect } from "react";
import DataTable from "../../general/GlobalComponents/DataTable";
import useReuniones from "../customHooks/useReuniones";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { LoadingButton } from "@mui/lab";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router";

import {
  Edit,
  Add,
  Close,
  ListAlt,
  Save,
  EditCalendar,
  Download,
  RemoveRedEye,
  CheckCircle,
  ErrorOutlineTwoTone,
} from "@mui/icons-material";

import { Card, CardContent } from "@mui/material";
import { Typography } from "@mui/material";
import { MenuItem } from "@mui/material";
import styled from "styled-components";
import useAlerts from "../../../services/useAlerts";
import echoX from "../../../services/echo";
import dayjs from "dayjs";
import { useMediaQuery } from "@mui/material";

const Reuniones = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: isMobile ? "90%" : "75%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
  };

  const styleModalPDF = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: isMobile ? "90%" : "60%",
    height: "80%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
  };

  const styleModalReagend = {
    position: "absolute",
    top: isMobile ? "50%" : "25%",
    left: "50%",
    width: isMobile ? "90%" : "25%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
  };

  const rolUser = localStorage.getItem("rol");

  const InputFile = styled.input``;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //const { getUsuariosInvite, stateUsuariosInvite } = useUsuarios();

  const { showLoader } = useAlerts();

  const {
    stateReuniones,
    stateModal,
    handleClose,
    handleNombreReunion,
    handleFechaReunion,
    handleShowModal,
    handleArchivo,
    validarDatos,
    handleAddAsunto,
    handleRemoveAsunto,
    selectedDate,
    currentDate,
    stateValidate,
    nombreArchivo,
    handleAsuntoChange,
    handleChange,
    handleShowReagendar,
    reagendarReunion,
    verReuniones,
    limpiarFormulario,
    handleClickBackdrop,
    downloadOrdenDia,
    modal64,
    handleShowPDF,
    showPDF,
    handleShowModalEdit,
    editarReunion,
    stateModalEdit,
    stateReunion,
    changeStatus,
    stateReunionChat,
    setStateReunionChat,
    handleNombreReunionEdit,
    reunionDia,
    limpiarFormularioEdit,
    handleAsuntoChangeAdd,
    handleRemoveAsuntoAdd,
    handleAddAsuntoAdd,
    hideLegend,
    getUsuariosInvite,
    stateUsuariosInvite,
    handleTipoReunion,
    handleTipoReunionEdit,
  } = useReuniones();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let isMounted = true;
    showLoader();
    localStorage.setItem("paginaCerrada", "false");
    if (isMounted) {
      if (rolUser !== "Participante" && rolUser !== "Invitado") {
        getUsuariosInvite();
      }
      reunionDia();
      verReuniones();
    }
    return () => {
      isMounted = false;
    };
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  const pusherChannelBase = process.env.REACT_APP_PUSHER_CHANNEL;
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [alertMeetingExcecute, setAlertMeetingExcecute] = useState(false);

  useEffect(() => {
    let nameEvent = "meeting-status";
    if (stateReunionChat.id !== null) {
      const handleEvent = (data, status) => {
        setStateReunionChat((prevState) => ({
          ...prevState,
          idEstatus: status,
          estadoReunion: data.status,
          hora: data.hour,
          uuidReunion: data.uuid,
        }));
      };

      const token = localStorage.getItem("token");
      const echo = echoX(token);
      const channelName = `${pusherChannelBase}.${stateReunionChat.id}`;
      const channel = echo.channel(channelName);
      channel.listen(`.${nameEvent}`, (dataListen) => {
        let fechaReunion = dayjs(dataListen.date + "T" + dataListen.hour);
        let fechaActual = dayjs();
        console.log(
          fechaReunion,
          fechaActual,
          fechaActual < fechaReunion,
          dataListen,
          fechaReunion.format("DD"),
          fechaActual.format("DD")
        );
        // if (fechaActual < fechaReunion === true) {
        if (fechaActual < fechaReunion) {
          handleEvent(dataListen, 1);
          setIsSubscribed(true);
          if (fechaReunion.format("DD") > fechaActual.format("DD")) {
            setIsSubscribed(false);
          }
        } else if (
          dataListen.status === "Finalizada" ||
          dataListen.status === "Celebrada"
        ) {
          setAlertMeetingExcecute(false);
        } else {
          handleEvent(dataListen, 2);
          setAlertMeetingExcecute(true);
          setIsSubscribed(true);
        }
      });
    }
  }, [stateReunionChat.id]);

  const columns = [
    {
      name: "nombre",
      label: "Nombre",
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "tipo", //aqui cambio
      label: "Tipo de Reunión",
      options: {
        download: false,
        customBodyRenderLite: (value) => {
          let status = stateReuniones.solicitudes[value].tipo.tipo;
          return <div>Reunión {status}</div>;
        },
      },
    },
    {
      name: "fecha",
      label: "Fecha",
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "hora",
      label: "Hora",
      options: {
        customBodyRender: (value) => <div>{value} hrs.</div>,
      },
    },
    {
      name: "status",
      label: "Estatus",
      options: {
        download: false,
        customBodyRenderLite: (value) => {
          let status = stateReuniones.solicitudes[value].estatus.estatus;
          return <div>{status}</div>;
        },
      },
    },
    {
      name: "acciones",
      label: "ACCIONES ",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex) => {
          const statusReunion =
            stateReuniones.solicitudes[dataIndex].estatus.estatus;
          return (
            <>
              {rolUser === "Participante" || rolUser === "Invitado" ? null : (
                <Tooltip disableFocusListener title="Reagendar Reunión">
                  <IconButton
                    className={
                      statusReunion === "Finalizada" ||
                      statusReunion === "Celebrada"
                        ? "opacity-25"
                        : null
                    }
                    disabled={
                      statusReunion === "Finalizada" ||
                      statusReunion === "Celebrada"
                        ? true
                        : false
                    }
                    aria-label="Reagendar"
                    onClick={() => {
                      handleShowReagendar(
                        true,
                        stateReuniones.solicitudes[dataIndex].uuid
                      );
                    }}
                  >
                    <EditCalendar color="error" />
                  </IconButton>
                </Tooltip>
              )}

              {rolUser === "Participante" || rolUser === "Invitado" ? null : (
                <Tooltip disableFocusListener title="Editar Reunión">
                  <IconButton
                    className={
                      statusReunion === "Finalizada" ||
                      statusReunion === "Celebrada"
                        ? "opacity-25"
                        : null
                    }
                    disabled={
                      statusReunion === "Finalizada" ||
                      statusReunion === "Celebrada"
                        ? true
                        : false
                    }
                    aria-label="Editar"
                    onClick={() => {
                      handleShowModalEdit(
                        true,
                        stateReuniones.solicitudes[dataIndex].nombre,
                        stateReuniones.solicitudes[dataIndex].uuid
                      );
                    }}
                  >
                    <Edit color="primary" />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip disableFocusListener title="Visualizar Orden del Día">
                <IconButton
                  aria-label="Visualizar"
                  onClick={() => {
                    showPDF(stateReuniones.solicitudes[dataIndex].uuid);
                  }}
                >
                  <RemoveRedEye color="success" />
                </IconButton>
              </Tooltip>

              {rolUser === "Participante" || rolUser === "Invitado" ? null : (
                <Tooltip disableFocusListener title="Celebrar Reunión">
                  <IconButton
                    className={
                      statusReunion === "Agendada" ||
                      statusReunion === "Ejecución" ||
                      statusReunion === "Celebrada"
                        ? "opacity-25"
                        : null
                    }
                    disabled={
                      statusReunion === "Agendada" ||
                      statusReunion === "Ejecución" ||
                      statusReunion === "Celebrada"
                        ? true
                        : false
                    }
                    aria-label="Celebrar Reunión"
                    onClick={() => {
                      changeStatus(stateReuniones.solicitudes[dataIndex].uuid);
                    }}
                  >
                    <CheckCircle color="primary" />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip disableFocusListener title="Descargar Orden del Día">
                <IconButton
                  aria-label="Descargar"
                  onClick={() => {
                    downloadOrdenDia(
                      stateReuniones.solicitudes[dataIndex].uuid
                    );
                  }}
                >
                  <Download color="action" />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      <div className="container">
        <Grid className="mt-5 mb-2 px-5 pt-4 pt-md-0">
          <Grid item xs={12} md={12}>
            <Card>
              <CardContent>
                <Grid container className="mb-2">
                  <Grid item xs={12} md={6} className="text-cebter-custom">
                    {isSubscribed && !alertMeetingExcecute ? (
                      <div class="alert-warning p-3 position-relative rounded">
                        <b> Recordatorio: </b>
                        <br />
                        Tienes una reunión agendada para las{" "}
                        {stateReunionChat.hora} hrs.
                        <ErrorOutlineTwoTone className="position-absolute top-0 end-0 mt-2 me-2 text-danger" />
                      </div>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className="text-right-custom d-flex flex-column flex-md-row"
                  >
                    {/* LOGICA PARA MOSTRAR CUANDO UNA REUNION ESTE EN EJECUCION - LUIS ADRIAN */}
                    {isSubscribed && alertMeetingExcecute ? (
                      <LoadingButton
                        /*  type="submit" */
                        className="background-colorUI"
                        variant="contained"
                        endIcon={<PlayArrowIcon />}
                        loading={false}
                        sx={{
                          mt: 1,
                          mb: 2,
                          alignItems: "center",
                        }}
                        onClick={() =>
                          navigate(`/reunion/${stateReunionChat.uuidReunion}`)
                        }
                      >
                        Reunión en ejecución
                      </LoadingButton>
                    ) : null}

                    <LoadingButton
                      className="ms-md-3 background-colorUI"
                      /*   type="submit" */
                      variant="contained"
                      endIcon={<ListAlt />}
                      loading={false}
                      sx={{
                        mt: 1,
                        mb: 2,
                        alignItems: "center",
                      }}
                      onClick={() => navigate("/bitacora")}
                    >
                      Bitácora
                    </LoadingButton>

                    {rolUser === "Participante" ||
                    rolUser === "Invitado" ? null : (
                      <LoadingButton
                        className="ms-md-3 background-colorUI"
                        /* type="submit" */
                        variant="contained"
                        endIcon={<Add />}
                        loading={false}
                        sx={{
                          mt: 1,
                          mb: 2,
                          alignItems: "center",
                        }}
                        onClick={() => handleShowModal(true)}
                      >
                        Crear Reunión
                      </LoadingButton>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <DataTable
              title={
                <span>
                  <h5>Reuniones</h5>
                </span>
              }
              data={stateReuniones.solicitudes}
              columns={columns}
            />
          </Grid>
        </Grid>
      </div>
      <Grid className="d-lg-none" style={{ margin: "150px" }}></Grid>
      {/* MODAL CREAR REUNION */}
      {stateModal.open ? (
        <Modal
          open={stateModal.open}
          onClose={() => {
            handleShowModal(false);
            limpiarFormulario();
          }}
          disableBackdropClick
          BackdropProps={{
            onClick: handleClickBackdrop,
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="p-2 p-md-4 col-10 col-md-6 pt-4"
            sx={styleModal}
            style={{
              overflowY: "auto",
              maxHeight: "90vh",
            }}
          >
            <Typography id="modal-modal-title" component="h2">
              <h4
                className="text-center fw-bold"
                style={{ color: "#a70631", fontSize: "25px" }}
              >
                Crear reunión
              </h4>
              <IconButton
                className={
                  "border-0 position-absolute p-1 top-0 end-0 m-2 rounded-circle"
                }
                onClick={() => {
                  handleShowModal(false);
                  limpiarFormulario();
                }}
              >
                <Close />
              </IconButton>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 1, mr: 7 }}>
              <Grid container columns={12} spacing={2} m={1} className="mr-4">
                {/* NOMBRE DE LA REUNION */}
                <Grid item xs={12} md={7}>
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                    size="small"
                    id="reunion"
                    label="Nombre de la reunión"
                    inputProps={{ maxLength: 255 }}
                    type="text"
                    error={
                      stateModal.curp === "" && stateValidate ? true : false
                    }
                    value={stateModal.nombreReunion}
                    variant="filled"
                    onChange={handleNombreReunion}
                  />
                </Grid>

                {/* COLOCAR FECHA */}
                <Grid item xs={12} md={5} className="pt-1">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateTimePicker"]}>
                      <DateTimePicker
                        className="bg-date"
                        label="Fecha y Hora"
                        onChange={handleFechaReunion}
                        renderInput={(params) => <TextField {...params} />}
                        minDate={currentDate}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>

              {/* TIPO DE REUNIÓN */}
              <Grid container columns={12} spacing={2} m={1}>
                <Grid item xs={12} md={7}>
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                    size="small"
                    id="tipoReunion"
                    label="Tipo de Reunión"
                    select
                    error={
                      stateModal.tipoReunion === "" && stateValidate
                        ? true
                        : false
                    }
                    value={stateModal.tipoReunion}
                    variant="filled"
                    onChange={handleTipoReunion}
                  >
                    <MenuItem value={1}>Ordinaria</MenuItem>
                    <MenuItem value={2}>Extraordinaria</MenuItem>
                  </TextField>
                </Grid>
              </Grid>

              <Grid container columns={12} spacing={2} m={1}>
                <Grid
                  item
                  xs={12}
                  className="d-flex align-items-center pb-2 pt-4"
                >
                  <p className="m-0 d-inline fs-5">Añadir asuntos</p>
                  <button
                    className="cta--gris rounded-circle ms-2 btn-add-asunto text-white"
                    onClick={() => handleAddAsuntoAdd()}
                  >
                    <AddIcon />
                  </button>
                </Grid>

                {/* AÑADIR ASUNTOS */}
                {stateModal.asuntos.map((asunto, index) => (
                  <Grid key={index} item xs={12} md={6}>
                    <div className="position-relative">
                      <TextField
                        className="p-0"
                        InputProps={{ disableUnderline: true }}
                        fullWidth
                        value={asunto}
                        size="small"
                        id={`asunto-${index}`}
                        label={`Asunto ${index + 1}`}
                        inputProps={{ maxLength: 200 }}
                        type="text"
                        variant="filled"
                        onChange={(event) =>
                          handleAsuntoChangeAdd(event.target.value, index)
                        }
                      />
                      {index > 0 ? (
                        <button
                          className="bg-danger rounded-circle text-white position-absolute btn-remove-asunto "
                          onClick={() => handleRemoveAsuntoAdd(index)}
                          variant="contained"
                          color="secondary"
                          size="small"
                        >
                          <RemoveIcon />
                        </button>
                      ) : null}
                    </div>
                  </Grid>
                ))}
              </Grid>

              {/* Cargar Archivo */}
              <Grid className="ps-4 py-4" item xs={12} md={6}>
                <div className="d-flex align-items-center">
                  <div>
                    <InputFile
                      accept=".pdf"
                      className="input-file"
                      id="archivo"
                      type="file"
                      onChange={handleArchivo}
                    />
                    <label htmlFor="archivo">
                      {nombreArchivo
                        ? `Archivo seleccionado: ${nombreArchivo}`
                        : "Selecciona la orden del día"}
                    </label>
                  </div>
                </div>
                {hideLegend ? null : (
                  <p className="pt-3 m-0 fs-7 text-danger">
                    El archivo no debe superar los 25MB
                  </p>
                )}
              </Grid>

              <Grid container columns={12} spacing={2} m={1}>
                <Grid item xs={12} md={6}>
                  <p className="m-0">
                    Elige a los invitados que <strong>NO</strong> estarán en la
                    reunión
                  </p>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Usuarios
                    </InputLabel>

                    {/* PERSONALIZAR INVITADOS (EXCLUIRLOS) */}
                    <Select
                      className="bg-users"
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={stateModal.usuarios}
                      onChange={(event) => handleChange(event.target.value)}
                      input={<OutlinedInput label="Usuarios" />}
                      renderValue={(all) => {
                        const arrayIniciales = [];
                        // eslint-disable-next-line
                        all.map((element, index) => {
                          const objectUser =
                            stateUsuariosInvite.usuariosInvite.filter(
                              (obj) => obj.id === element
                            );
                          arrayIniciales[
                            index
                          ] = `${objectUser[0].nombre[0]}${objectUser[0].apellidoPaterno[0]}`;
                        });
                        return arrayIniciales.join(", ");
                      }}
                      MenuProps={MenuProps}
                    >
                      {stateUsuariosInvite.usuariosInvite.map((users) => (
                        <MenuItem key={users.id} value={users.id}>
                          <Checkbox
                            checked={stateModal.usuarios.includes(users.id)}
                          />
                          <ListItemText
                            primary={`
                            ${users.nombre} 
                            ${users.apellidoPaterno}
                            ${users.apellidoMaterno}
                            `}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* USUARIOS SELECCIONADOS */}
                <Grid className="pb-4" item xs={12} md={6}>
                  <h6 className="mb-2">
                    Usuarios que <strong>NO</strong> serán invitados a la
                    reunión:
                  </h6>
                  <div className="bg-date rounded p-3">
                    <div className="box-users">
                      {stateModal.usuarios.length > 0 ? (
                        stateModal.usuarios.map((userId) => {
                          const user = stateUsuariosInvite.usuariosInvite.find(
                            (obj) => obj.id === userId
                          );
                          if (user) {
                            return (
                              <>
                                <p className="mb-1" key={userId}>
                                  <span className="text-danger">•</span>{" "}
                                  <strong>{`${user.nombre} ${user.apellidoPaterno} ${user.apellidoMaterno}`}</strong>
                                </p>
                                <p className="mb-3">
                                  Catálago:{" "}
                                  {user.catalago === null
                                    ? `N/A`
                                    : user.catalago.camara === null
                                    ? `${user.catalago.comision.nombre}`
                                    : `${user.catalago.camara.nombre}`}
                                </p>
                              </>
                            );
                          }
                          return null;
                        })
                      ) : (
                        <p>No hay usuarios seleccionados</p>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                columns={12}
                spacing={2}
                m={1}
                className="d-flex flex-column justify-content-center align-items-md-center flex-md-row"
              >
                <Grid item xs={12} md={4} className="text-right-custom">
                  <button
                    className="cta cta--gris cta--medium mt-2 w-100"
                    onClick={() => {
                      handleShowModal(false);
                      limpiarFormulario();
                    }}
                  >
                    Cancelar <Close />
                  </button>
                </Grid>

                <Grid item xs={12} md={4}>
                  <button
                    /* type="submit" */
                    variant="contained"
                    className="cta cta--rojo cta--medium mt-2 w-100"
                    onClick={() => {
                      validarDatos();
                    }}
                  >
                    Guardar <Save />
                  </button>
                </Grid>
              </Grid>
            </Typography>
          </Box>
        </Modal>
      ) : null}
      {/* MODAL REAGENDAR REUNION */}
      {stateModal.openReagendar ? (
        <Modal
          open={stateModal.openReagendar}
          onClose={() => handleShowReagendar(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="p-2 p-md-4 col-10 col-md-6 pt-4"
            sx={styleModalReagend}
            component="form"
            noValidate
          >
            <h4 style={{ color: "#a70631", fontSize: "20px" }}>
              Reagendar Reunión
            </h4>
            <IconButton
              className={
                "border-0 position-absolute p-1 top-0 end-0 m-2 rounded-circle"
              }
              onClick={() => {
                handleShowReagendar(false);
              }}
            >
              <Close />
            </IconButton>
            {/* COLOCAR FECHA */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  label="Fecha y Hora"
                  value={selectedDate}
                  onChange={handleFechaReunion}
                  renderInput={(params) => (
                    <TextField {...params} className="bg-input" />
                  )}
                  minDate={currentDate}
                />
              </DemoContainer>
            </LocalizationProvider>
            <Grid container>
              <Grid item xs={6} md={6} className="text-right-custom ">
                <LoadingButton
                  variant="contained"
                  endIcon={<Close />}
                  loading={false}
                  sx={{
                    mt: 3,
                    mb: 2,
                    mr: 1,
                    ml: 1,
                    alignItems: "center",
                    backgroundColor: "#ABB2B9",
                  }}
                  onClick={() => {
                    handleShowReagendar(false);
                  }}
                >
                  Cancelar
                </LoadingButton>
              </Grid>
              <Grid item xs={6} md={6} className="text-left-custom ">
                <LoadingButton
                  variant="contained"
                  endIcon={<Save />}
                  loading={false}
                  sx={{
                    mt: 3,
                    mb: 2,
                    mr: 1,
                    ml: 1,
                    alignItems: "center",
                    backgroundColor: "#a70631",
                  }}
                  onClick={() => {
                    reagendarReunion();
                    reunionDia();
                  }}
                >
                  Guardar
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      ) : null}
      {/* MODAL PDF */}
      {modal64.showModal ? (
        <Modal
          open={() => handleShowPDF(true)}
          onClose={() => handleShowPDF(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="p-2 p-md-4 col-10 col-md-6 pt-4"
            sx={styleModalPDF}
            component="form"
            // onSubmit={handleSubmit}
            noValidate
          >
            <h4 style={{ color: "#a70631", fontSize: "20px" }}>
              Orden del día
            </h4>
            <IconButton
              className={
                "border-0 position-absolute p-1 top-0 end-0 m-2 rounded-circle"
              }
              onClick={() => {
                handleShowPDF(false);
              }}
            >
              <Close />
            </IconButton>
            <iframe
              src={modal64.file}
              loading="lazy"
              type="application/pdf"
              frameborder="0"
              scrolling="auto"
              style={{ width: "100%", height: "100%" }}
              title={`Documento PDF ${modal64.file}`}
            ></iframe>
            <Grid containter>
              <Grid item xs={12} md={12} className="text-center-custom">
                <LoadingButton
                  variant="contained"
                  endIcon={<Close />}
                  loading={false}
                  sx={{
                    mt: 3,
                    mb: 2,
                    mr: 1,
                    ml: 1,
                    alignItems: "center",
                    backgroundColor: "#a70631",
                  }}
                  onClick={() => {
                    handleShowPDF(false);
                  }}
                >
                  Cerrar
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      ) : null}

      {/* EDITAR REUNION */}
      {stateModalEdit.open ? (
        <Modal
          open={stateModalEdit.open}
          onClose={handleClose}
          disableBackdropClick
          BackdropProps={{
            onClick: handleClickBackdrop,
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="p-2 p-md-4 col-10 col-md-6 pt-4"
            sx={styleModal}
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              maxHeight: "90vh",
            }}
          >
            <Typography id="modal-modal-title" component="h2">
              <h4
                className="text-center fw-bold"
                style={{ color: "#A70631", fontSize: "25px" }}
              >
                Editar reunión
              </h4>
              <IconButton
                className={
                  "border-0 position-absolute p-1 top-0 end-0 m-2 rounded-circle"
                }
                onClick={() => {
                  handleShowModalEdit(false, "cancelar");
                  limpiarFormularioEdit();
                }}
              >
                <Close />
              </IconButton>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 1, mr: 7 }}>
              <Grid container columns={12} spacing={2} m={1} className="mr-4">
                {/* EDITAR NOMBRE DE LA REUNION */ console.log(stateReunion)}
                <Grid item xs={12} md={6}>
                  <p>Editar el nombre de la reunión</p>
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                    size="small"
                    id="reunion_editar"
                    name="reunion_editar"
                    label="Nombre de la reunión"
                    inputProps={{ maxLength: 255 }}
                    type="text"
                    value={stateReunion.dataReunion.nombre}
                    variant="filled"
                    onChange={handleNombreReunionEdit}
                    key={"reunion_editar"}
                  />
                </Grid>
                {/* TIPO DE REUNIÓN*/}
                <Grid item xs={12} md={6} className="pt-5">
                  <TextField
                    className="mt-2"
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                    size="small"
                    id="tipo"
                    label="Tipo de Reunión"
                    select
                    value={stateReunion.tipo}
                    variant="filled"
                    onChange={handleTipoReunionEdit}
                  >
                    <MenuItem value={1}>Ordinaria</MenuItem>
                    <MenuItem value={2}>Extraordinaria</MenuItem>
                  </TextField>
                </Grid>
              </Grid>

              {/* AÑADIR NUEVOS ASUNTOS */}
              <Grid container columns={12} spacing={2} m={1}>
                <Grid
                  item
                  xs={12}
                  className="d-flex align-items-center pb-2 pt-4"
                >
                  <p className="m-0 d-inline fs-5">
                    Añadir asuntos y/o editarlos
                  </p>
                  <button
                    className="cta--gris rounded-circle ms-2 btn-add-asunto text-white"
                    onClick={() => handleAddAsunto()}
                  >
                    <AddIcon />
                  </button>
                </Grid>

                {/* AÑADIR ASUNTOS */}
                {stateReunion.dataReunion.asuntos?.map((asunto, index) => (
                  <Grid key={index} item xs={12} md={6}>
                    <div className="position-relative">
                      <TextField
                        className="p-0"
                        InputProps={{ disableUnderline: true }}
                        fullWidth
                        value={asunto.asunto}
                        size="small"
                        id={`asunto-${index}`}
                        label={`Asunto ${index + 1}`}
                        inputProps={{ maxLength: 200 }}
                        type="text"
                        variant="filled"
                        onChange={(event) =>
                          handleAsuntoChange(event.target.value, index)
                        }
                      />
                      {index > 0 ? (
                        <button
                          className="bg-danger rounded-circle text-white position-absolute btn-remove-asunto "
                          onClick={() => handleRemoveAsunto(index)}
                          variant="contained"
                          color="secondary"
                          size="small"
                        >
                          <RemoveIcon />
                        </button>
                      ) : null}
                    </div>
                  </Grid>
                ))}
              </Grid>

              {/* EDITAR CARGAR ARCHIVO */}
              <Grid className="ps-4 py-4" item xs={12} md={6}>
                {stateReunion.dataReunion.nombre_archivo && (
                  <>
                    <p className="m-0">
                      Archivo Cargado Anteriormente:
                      {stateReunion.dataReunion.nombre_archivo}
                    </p>
                  </>
                )}
                <div className="d-flex align-items-center">
                  <div>
                    <InputFile
                      accept=".pdf"
                      key={"archivo-editar"}
                      className="input-file"
                      id="archivo"
                      type="file"
                      onChange={handleArchivo}
                    />
                    <label htmlFor="archivo">
                      {nombreArchivo
                        ? `Archivo seleccionado: ${nombreArchivo}`
                        : "Selecciona la orden del día"}
                    </label>
                  </div>
                </div>
                {hideLegend ? null : (
                  <p className="pt-3 m-0 fs-7 text-danger">
                    El archivo no debe superar los 25MB
                  </p>
                )}
              </Grid>

              <Grid container columns={12} spacing={2} m={1}>
                {/* EDITAR PERSONALIZAR INVITADOS (EXCLUIRLOS) */}
                <Grid item xs={12} md={6}>
                  {/* aqui */}
                  <p className="m-0 ">
                    Elige a los invitados que <b>NO</b> estarán en la reunión
                  </p>

                  <FormControl
                    className="ms-3 pe-3"
                    sx={{ m: 1, width: "100%" }}
                  >
                    <InputLabel id="demo-multiple-checkbox-label">
                      Usuarios
                    </InputLabel>
                    {/* {console.log(stateModal.usuarios, stateUsuariosInvite)} */}
                    <Select
                      key={"usuarios-editar"}
                      className="bg-users"
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={stateModal.usuarios}
                      onChange={(event) => handleChange(event.target.value)}
                      input={<OutlinedInput label="Usuarios" />}
                      renderValue={(selected) => selected.join(", ")}
                      // renderValue={(all) => {
                      //   const arrayIniciales = [];
                      //   all.map((element, index) => {
                      //     const objectUser =
                      //       stateUsuariosInvite.usuariosInvite.filter(
                      //         (obj) => obj.id === element
                      //       );
                      //     arrayIniciales[
                      //       index
                      //     ] = `${objectUser[0].nombre[0]}${objectUser[0].apellidoPaterno[0]}`;
                      //   });
                      //   return arrayIniciales.join(", ");
                      // }}
                      MenuProps={MenuProps}
                    >
                      {stateModal.newusuarios.map((element) => (
                        <MenuItem key={element} value={element}>
                          <Checkbox
                            // checked={stateModal.usuarios.indexOf(element) > -1}
                            checked={stateModal.usuarios.includes(element)}
                          />
                          <ListItemText primary={element} />
                        </MenuItem>
                      ))}
                      {
                        //   stateUsuariosInvite.usuariosInvite.map((users) => (
                        //   <MenuItem key={users.id} value={users.id}>
                        //     <Checkbox
                        //       checked={
                        //         !stateReunion.usuariosInvitados.find(
                        //           (objUsuario) => objUsuario.id === users.id
                        //         )
                        //       }
                        //       // checked={stateModal.usuarios.includes(users.id)}
                        //     />
                        //     {/* {console.log(stateModal.usuarios, "usuarios")} */}
                        //     {console.log(
                        //       stateReunion.usuariosInvitados,
                        //       "usuarios"
                        //     )}
                        //     <ListItemText
                        //       primary={`
                        //       ${users.id}
                        //       ${users.nombre}
                        //       ${users.apellidoPaterno}
                        //       ${users.apellidoMaterno}
                        //       `}
                        //     />
                        //   </MenuItem>
                        // ))
                      }
                    </Select>
                  </FormControl>
                </Grid>

                {/* USUARIOS SELECCIONADOS */}
                <Grid className=" pb-4" item xs={12} md={6}>
                  <h6 className="mb-2">
                    Usuarios que <strong>NO</strong> serán invitados a la
                    reunión:
                  </h6>
                  <div className="bg-date rounded p-3">
                    <div className="box-users">
                      {stateModal.usuarios.length > 0 ? (
                        /* eslint-disable react-hooks/exhaustive-deps */
                        // eslint-disable-next-line
                        stateModal.usuarios.map((subelement) => {
                          // console.log(subelement);
                          let userId = subelement.split(" ")[0];
                          // {
                          //   console.log(
                          //     // stateModal.usuarios,
                          //     // "usuarios",
                          //     // stateModal.newusuarios,
                          //     // "newusuarios",
                          //     // stateUsuariosInvite.usuariosInvite,
                          //     // "usuarios invite",
                          //     userId,
                          //     "userid"
                          //   );
                          // }
                          // const user =
                          const invitados =
                            stateUsuariosInvite.usuariosInvite.find(
                              // eslint-disable-next-line
                              (obj) => obj.id == userId
                            );
                          // console.log(invitados);
                          // console.log("si hay alguno", invitados[0].nombre);
                          if (invitados) {
                            return (
                              <>
                                <p className="mb-1" key={userId}>
                                  <span className="text-danger">•</span>{" "}
                                  <strong>{`${invitados.nombre} ${invitados.apellidoPaterno} ${invitados.apellidoMaterno}`}</strong>
                                </p>
                                <p className="mb-3">
                                  Catálogo:{" "}
                                  {invitados.catalago === null
                                    ? `N/A`
                                    : invitados.catalago.camara === null
                                    ? `${invitados.catalago.comision.nombre}`
                                    : `${invitados.catalago.camara.nombre}`}
                                </p>
                              </>
                            );
                          }
                          // return null;
                          // if (user) {
                          // }
                        }) /* eslint-disable react-hooks/exhaustive-deps */
                      ) : (
                        <p>No hay usuarios seleccionados</p>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                columns={12}
                spacing={2}
                m={1}
                className="d-flex flex-column justify-content-center flex-md-row"
              >
                <Grid item xs={12} md={4} className="text-right-custom">
                  <button
                    className="cta cta--gris cta--medium mt-2 w-100"
                    onClick={() => {
                      handleShowModalEdit(false, "cancelar");
                      limpiarFormularioEdit();
                    }}
                  >
                    Cancelar <Close />
                  </button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <button
                    variant="contained"
                    className="cta cta--rojo cta--medium mt-2 w-100"
                    onClick={() => {
                      editarReunion();
                    }}
                  >
                    Guardar <Save />
                  </button>
                </Grid>
              </Grid>
            </Typography>
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

export default Reuniones;
