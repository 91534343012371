import { useEffect } from "react";
import DataTable from "../../general/GlobalComponents/DataTable";
import Calendario from "@mui/icons-material/CalendarMonth";

const ReunionesUser = () => {
  const columns = [
    {
      name: "nombre",
      label: "Nombre",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "fecha",
      label: "Fecha",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "estatus",
      label: "Estatus",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "acciones",
      label: "Acciones",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
  ];

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // verSolicitudesReuniones()
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div
      className="container-fluid bk-img bg-gray-100"
      style={{ height: "90vh" }}
    >
      <br></br>
      <br></br>
      <div className="container ">
        <div>
          <div className="d-flex flex-row justify-content-between">
            <div>
              <button
                className="reunionesBtn cta--blanco cta--medium "
                onClick={() => {
                  //
                }}
              >
                <Calendario />
                Reunión en Ejecución
                <p className="partbtn">Participantes</p>
              </button>
            </div>
            <div>
              <button
                className="reunionesBtn cta--blanco-gris cta--medium mb-3 "
                onClick={() => {
                  //
                }}
              >
                <Calendario />
                Bitácora de Reuniones
              </button>
            </div>
          </div>
          <div className="col mt-5 mx-5">
            <div>
              <DataTable
                title={
                  <span style={{ color: "#a70631", fontSize: "40px" }}>
                    Reuniones
                  </span>
                }
                /* data={stateReuniones.solicitudes} */
                columns={columns}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReunionesUser;
