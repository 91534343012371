import React, { useContext, useEffect } from "react";
import logo from "../../assets/images/CONACO-TMEC HRZ.png";
import { GlobalContext } from "../../context/globalContext";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Menu from "./menu";
import PersonIcon from "@mui/icons-material/Person";

export const shouldShowMenu = (pathname) => {
  const isReunionRoute = /\/reunion\/[\w-]+/.test(pathname);
  return !isReunionRoute;
};

function HeaderComponent(props) {
  const location = useLocation();
  const ocultar = useLocation();

  /*   const shouldShowMenu = () => {
    const isReunionRoute = /\/reunion\/[\w-]+/.test(location.pathname);
    return !isReunionRoute;
  };
 */

  const shouldShow = shouldShowMenu(location.pathname);

  const usuario = localStorage.getItem("nombre");

  const { globalState, setGlobalState } = useContext(GlobalContext);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setGlobalState({ ...globalState, hamburger: true });
    }

    return () => {
      isMounted = false;
    };
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  return (
    <div className="shadow-sm boderBottom--Gris py-1 fondo">
      <nav className="navbar navbar-ligth navar-expand-lg">
        {ocultar.pathname === "/" ||
        ocultar.pathname === "/login" ||
        ocultar.pathname.startsWith("/restablecerContrasena") ? (
          <Grid container>
            <Grid item className="text-left-custom mt-1" xs={12} md={6}>
              <img className="logo ms-3" src={logo} alt="logo" />
            </Grid>
            <Grid item className="text-right-custom" xs={12} md={6}>
              <div
                className="textHeadCel tmec-color-gold ms-3 mr-2"
                style={{ paddingRight: "50px" }}
              >
                <b >Comisión T-MEC</b>
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container className="align-items-center">
            <Grid item className="text-left-custom" xs={4} md={5}>
              <img className="logo ms-3" src={logo} alt="logo" />
            </Grid>
            <Grid item className="text-left-custom" xs={3} md={4}>
              <div className="textHead tmec-color-gold ms-3 mr-2 d-none d-sm-block">
                <b className="pt-2">Comisión T-MEC</b>
              </div>
            </Grid>
            <Grid
              item
              xs={5}
              md={3}
              className="text-right-custom d-flex align-items-center"
            >
              <div className="tmec-color-gold pe-5 d-flex justify-content-center align-items-center gap-2">
                <strong>
                  <h4 className="pt-2 textHead">{usuario}</h4>
                </strong>

                <PersonIcon className="textHead"/>
              </div>
            </Grid>
            <Grid item md={12} xs={12} className="mt-2 mb-5">
              {shouldShow && <Menu />}
            </Grid>
          </Grid>
        )}
      </nav>
    </div>
  );
}

export default HeaderComponent;
