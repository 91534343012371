export function onlyNumber(event) {
  if (event.target.value.length > 6) {
    event.preventDefault();
  }
  if (
    event.which !== 8 &&
    event.which !== 0 &&
    (event.which < 48 || event.which > 57)
  ) {
    event.preventDefault();
  }
}

export function onlyDecimalNumber(inputText, event) {
  let str = String(inputText);
  let con = -1;

  if (event.which === 46) {
    for (let i = 0; i < str.length; i++) {
      if (str.charAt(i) === ".") {
        con++;
      }
    }

    if (con === 0) {
      event.preventDefault();
    }
  }
  if (event.target.value.length > 6) {
    event.preventDefault();
  }

  if (
    event.which !== 8 &&
    event.which !== 0 &&
    (event.which < 48 || event.which > 57) &&
    event.which !== 46
  ) {
    event.preventDefault();
  }
}
export function isDecimal(inputText, event) {
  let regex = /^[-+]?[0-9]+\.[0-9]+$/;
  return regex.test(inputText);
}

export function onlyLetter(event, max) {
  let key = event.keyCode || event.which;
  let tecla = String.fromCharCode(key).toLowerCase();
  let letras = `\u00e1\u00e9\u00ed\u00f3\u00fa\u00f1abcdefghijklmnopqrstuvwxyz `;
  let especiales = "8-37-39-46-199-42-229-221-16-94-168";

  let tecla_especial = false;
  for (var i in especiales) {
    if (key === especiales[i]) {
      tecla_especial = true;
      break;
    }
  }

  if (letras.indexOf(tecla) === -1 && !tecla_especial) {
    event.preventDefault();
  }

  if (event.target.value.length > max) {
    event.preventDefault();
  }
}

export function onlyLetterNumber(event) {
  let key = event.keyCode || event.which;
  let tecla = String.fromCharCode(key).toLowerCase();
  let letras = `\u00e1\u00e9\u00ed\u00f3\u00fa\u00f1abcdefghijklmnopqrstuvwxyz0123456789`;
  let especiales = "8-37-39-46-199-42-229-221-16-94-168";

  let tecla_especial = false;
  for (var i in especiales) {
    if (key === especiales[i]) {
      tecla_especial = true;
      break;
    }
  }

  if (letras.indexOf(tecla) === -1 && !tecla_especial) {
    event.preventDefault();
  }
}

export function alfaNumericoSinAcentos(event) {
  let key = event.keyCode || event.which;
  let tecla = String.fromCharCode(key).toLowerCase();
  let letras = `aeiounabcdefghijklmnopqrstuvwxyz0123456789`;
  let especiales = "8-37-39-46-199-42-229-221-16-94-168";

  let tecla_especial = false;
  for (var i in especiales) {
    if (key === especiales[i]) {
      tecla_especial = true;
      break;
    }
  }

  if (letras.indexOf(tecla) === -1 && !tecla_especial) {
    event.preventDefault();
  }
}

export function maxLengthLimitInput(event, max, type = "") {
  let key = event.keyCode || event.which;
  let tecla = String.fromCharCode(key).toLowerCase();
  let letras = "";

  switch (type) {
    case "email":
      letras = `\u00e1\u00e9\u00ed\u00f3\u00fa\u00f1abcdefghijklmnopqrstuvwxyz0123456789.@!#$%&'*/=?^_+-|{~}`;
      break;
    case "curp":
      letras = `\u00e1\u00e9\u00ed\u00f3\u00fa\u00f1abcdefghijklmnopqrstuvwxyz0123456789`;
      break;
    case "rfc":
      letras = `\u00e1\u00e9\u00ed\u00f3\u00fa\u00f1abcdefghijklmnopqrstuvwxyz0123456789`;
      break;
    case "razon_social":
      letras = `\u00e1\u00e9\u00ed\u00f3\u00fa\u00f1abcdefghijklmnopqrstuvwxyz0123456789,. `;

      break;
    default:
  }

  let especiales = "8-37-39-46-199-42-229-221-16-94-168";

  let tecla_especial = false;
  for (var i in especiales) {
    if (key === especiales[i]) {
      tecla_especial = true;
      break;
    }
  }

  if (event.target.value.length > max) {
    event.preventDefault();
  }

  if (letras.indexOf(tecla) === -1 && !tecla_especial) {
    event.preventDefault();
  }
}

export function onlyNumberLinea(event) {
  if (event.target.value.length > 19) {
    event.preventDefault();
  }
  if (
    event.which !== 8 &&
    event.which !== 0 &&
    (event.which < 48 || event.which > 57)
  ) {
    event.preventDefault();
  }
}

export const emailValidation = (email) => {
  const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  /* /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g */
  return regEx.test(email.toLowerCase());
};

export function onlyNumberPhone(event) {
  if (event.target.value.length > 9) {
    event.preventDefault();
  }
  if (
    event.which !== 8 &&
    event.which !== 0 &&
    (event.which < 48 || event.which > 57)
  ) {
    event.preventDefault();
  }
}

export const toMexico = (number) => {
  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = "$1,";
  let num_clear = number
    .toString()
    .replaceAll("$", "")
    .replaceAll(" ", "")
    .replaceAll(",", "");
  let arr = num_clear.split(".");
  arr[0] = arr[0].replace(exp, rep);
  if (arr[1]) {
    if (arr[1] === "0") {
      arr[1] = "00";
    }
    return arr.join(".");
  } else {
    return arr[0] + ".00";
  }
};
