import { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    email: "",
    emailError: false,
    nombre: "",
    nombreError: false,
    password: "",
    passwordError: false,
    curp: "",
    curpError: false,
    sexo: "",
    sexoError: false,
    apePaterno: "",
    apePaternoError: false,
    apeMaterno: "",
    apeMaternoError: false,
    emailConfirm: "",
    emailConfirmError: false,
    telefono: "",
    rolUsuario: "",
    rolUsuarioError: false,
    tituloUsuario: "",
    tituloUsuarioError: false,
    estadoUsuario: "",
    estadoUsuarioError: false,
    telefonoError: false,
    nombreCamara: "",
    nombreCamaraError: false,
    estadoCamara: "",
    estadoCamaraError: false,
    representanteCamara: "",
    representanteCamaraError: false,
    contactoCamara: "",
    contactoCamaraError: false,
    descripcionCamara: "",
    descripcionCamaraError: false,
    nombreReunion: "",
    nombreReunionError: false,
    fechaReunion: "",
    fechaReunionError: false,
    estatusReunion: "",
    estatusReunionError: false,
    navBarOpen: false,
    hamburger: false,
  });

  return (
    <GlobalContext.Provider
      value={{
        globalState,
        setGlobalState,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
