import { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../../context/globalContext";
import { useNavigate } from "react-router-dom";

const useMenu = () => {
  const [stateModalMenu, setStateModalMenu] = useState({
    open: false,
    openEdit: false,
    contrasenaActual: "",
    contrasenaActualError: false,
    contrasenaNueva: "",
    contrasenaNuevaError: false,
    confirmarContrasena: "",
    confirmarContrasenaError: false,
  });

  const handleContrasenaActual = (e) => {
    setStateModalMenu({ ...stateModalMenu, contrasenaActual: e.target.value });
  };

  const handleContrasenaNueva = (e) => {
    setStateModalMenu({ ...stateModalMenu, contrasenaNueva: e.target.value });
  };

  const handleConfirmarContrasena = (e) => {
    setStateModalMenu({
      ...stateModalMenu,
      confirmarContrasena: e.target.value,
    });
  };

  const navigate = useNavigate();
  const { globalState, setGlobalState } = useContext(GlobalContext);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setGlobalState({ ...globalState, navBarOpen: open, hamburger: true });
  };

  const modalOpen = (id) => {
    setStateModalMenu({ ...stateModalMenu, open: true });
  };

  const handleClose = () => {
    setStateModalMenu({ ...stateModalMenu, open: false });
  };

  const [settings, setsetting] = useState({
    anchor: "",
    rol: "",
    items: [],
  });

  const setItemsMenu = async () => {
    const rol = localStorage.getItem("rol");
    const arrayItems = await validateRolUser();
    setsetting({ ...settings, anchor: "left", items: arrayItems, rol });
    return arrayItems;
  };

  const validateRolUser = () => {
    const rolUser = localStorage.getItem("roles_id");
    switch (rolUser) {
      case "1": // Admin
        return [
          { name: "Reuniones", route: "/reuniones", icon: "PeopleAlt" },
          {
            name: "Catálogo de Comisiones",
            route: "/comisiones",
            icon: "Source",
          },
          {
            name: "Catálogo de Cámaras",
            route: "/camaras",
            icon: "SnippetFolder",
          },
          {
            name: "Catálogo de Títulos",
            route: "/titulos",
            icon: "FolderSpecial",
          },
          { name: "Usuarios", route: "/usuarios", icon: "GroupAdd" },
          { name: "Bitácora", route: "/bitacora", icon: "PlaylistAddCheck" },
          { name: "Mi Usuario", route: "/miUsuario", icon: "AccountBox" },
        ];

      case "2": // Moderador
        return [
          { name: "Reuniones", route: "/reuniones", icon: "PeopleAlt" },
          /*    {
            name: "Catálogo de Comisiones",
            route: "/comisiones",
            icon: "Source",
          }, */
          /*  {
            name: "Catálogo de Cámaras",
            route: "/camaras",
            icon: "SnippetFolder",
          }, */
          /* {
            name: "Catálogo de Títulos",
            route: "/titulos",
            icon: "FolderSpecial",
          }, */
          /* { name: "Usuarios", route: "/usuarios", icon: "GroupAdd" }, */
          { name: "Bitácora", route: "/bitacora", icon: "PlaylistAddCheck" },
          { name: "Mi Usuario", route: "/miUsuario", icon: "AccountBox" },
        ];

      case "3": // Participante
        return [
          { name: "Reuniones", route: "/reuniones", icon: "PeopleAlt" },
          { name: "Bitácora", route: "/bitacora", icon: "PlaylistAddCheck" },
          { name: "Mi Usuario", route: "/miUsuario", icon: "AccountBox" },
        ];

      case "4": // Invitado
        return [
          { name: "Reuniones", route: "/reuniones", icon: "PeopleAlt" },
          { name: "Bitácora", route: "/bitacora", icon: "PlaylistAddCheck" },
          { name: "Mi Usuario", route: "/miUsuario", icon: "AccountBox" },
        ];
      default:
        return [];
    }
  };

  return {
    navigate,
    toggleDrawer,
    settings,
    setsetting,
    setItemsMenu,
    validateRolUser,
    useEffect,
    globalState,
    setGlobalState,
    handleConfirmarContrasena,
    handleContrasenaActual,
    handleContrasenaNueva,
    handleClose,
    modalOpen,
    stateModalMenu,
    setStateModalMenu,
  };
};

export default useMenu;
