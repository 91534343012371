import { useState } from "react";
import useApi from "../../../services/useApi";
import useAlerts from "../../../services/useAlerts";

const useCamaras = () => {
  const [stateModal, setStateModal] = useState({
    open: false,
    openEdit: false,
    nombre: "",
    nombreError: false,
    id: "",
  });

  const [stateModalEdit, setStateModalEdit] = useState({
    open: false,
    openEdit: false,
  });

  const { showAlertConfirm, showLoader, showToast } = useAlerts();

  const handleShowModal = (action) => {
    setStateModal({ ...stateModal, open: action, nombre: "" });
  };

  const handleShowModalEdit = (action) => {
    setStateModalEdit({ ...stateModalEdit, open: action });
  };

  const [error, setError] = useState({});

  const handleNombre = (e) => {
    setStateModal({ ...stateModal, nombre: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateNombre(stateModal.nombre);
  };

  const handleKeyDown = (e, id) => {
    if (e.key === "Enter") {
      e.preventDefault();
      editCommission(id); // Llama a la función para enviar la petición
    }
  };

  const sendData = async (nombre) => {
    const formData = new FormData();

    formData.set("nombre", nombre);

    showLoader();
    const { execute, data } = await services(
      "POST",
      "v1/commissions",
      formData
    );

    if (execute) {
      showAlertConfirm("success", data.mensaje);
    }
  };

  const validateNombre = async () => {
    const { nombre } = stateModal;

    if (nombre.trim() !== "" && nombre.length >= 4 && nombre.length <= 100) {
      handleShowModal(false);
      await sendData(nombre);
      setStateModal({ stateModal, nombre: "" });
      await verSolicitudes();
    } else if (nombre.length < 4) {
      // setStateModal({ ...stateModal, sendFlag: true });
      showAlertConfirm("info", "No puede contener menos de 4 caracteres");
    } else if (nombre.length > 100) {
      // setStateModal({ ...stateModal, sendFlag: true });
      showAlertConfirm("info", "No puede contener más de 100 caracteres");
    } else {
      // setStateModal({ ...stateModal, sendFlag: true });
      showAlertConfirm("info", "Nombre requerido");
    }
  };

  const editCommission = async (id) => {
    const formData = new FormData();
    formData.append("nombre", stateModal.nombre);
    const { nombre } = stateModal;

    showLoader();
    if (nombre.trim() !== "" && nombre.length >= 4 && nombre.length <= 100) {
      const { execute, data } = await services(
        "PUT",
        `v1/commissions/${id}`,
        formData
      );
      if (execute) {
        await verSolicitudes();
        handleShowModalEdit(false);
        showAlertConfirm("success", data.mensaje);
        setStateModal({ stateModal, nombre: "" });
      }
    } else if (nombre.trim() === "" || nombre === "") {
      // setStateModal({ ...stateModal, sendFlag: true });
      showAlertConfirm("info", "Nombre requerido");
    } else if (nombre.length < 4) {
      // setStateModal({ ...stateModal, sendFlag: true });
      showAlertConfirm("info", "No puede contener menos de 4 caracteres");
    } else if (nombre.length > 100) {
      // setStateModal({ ...stateModal, sendFlag: true });
      showAlertConfirm("info", "No puede contener más de 100 caracteres");
    } else {
      // setStateModal({ ...stateModal, sendFlag: true });
      showAlertConfirm("info", "Nombre requerido");
    }
  };

  const setCurrentCommission = (id, nombre) => {
    handleShowModalEdit(true);
    setStateModal({ stateModal, nombre: nombre, id: id });
  };

  const deleteCommission = async (id) => {
    const confirmation = await showAlertConfirm(
      "question",
      "Confirmación",
      "¿Estás seguro de eliminar esta comisión?",
      true
    );

    if (confirmation) {
      showLoader();
      const { execute, data } = await services(
        "DELETE",
        `v1/commissions/${id}`
      );
      if (execute) {
        await verSolicitudes();
        showAlertConfirm("success", data.mensaje);
      }
    }
  };

  const { services } = useApi();

  const [stateComisiones, setStateComisiones] = useState({
    solicitudes: [],
  });

  const verSolicitudes = async () => {
    const { execute, data } = await services("GET", "v1/commissions");
    if (execute) {
      setStateComisiones({ ...stateComisiones, solicitudes: data.data });
      showToast("success", "Carga de información completa", "bottom-end", 2000);
    }
  };

  const loadComisiones = async () => {
    const { execute, data } = await services("GET", "v1/commissions");
    if (execute) {
      setStateComisiones({ ...stateComisiones, solicitudes: data.data });
      showToast("success", "Carga de información completa", "bottom-end", 2000);
    }
  };

  return {
    stateComisiones,
    stateModal,
    setStateModal,
    verSolicitudes,
    handleNombre,
    handleSubmit,
    deleteCommission,
    handleShowModal,
    editCommission,
    stateModalEdit,
    handleShowModalEdit,
    setCurrentCommission,
    handleKeyDown,
    error,
    loadComisiones,
    setError,
  };
};
export default useCamaras;
