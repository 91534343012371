import { useState } from "react";
import useApi from "../../../services/useApi";
import useAlerts from "../../../services/useAlerts";

const useUsuarios = () => {
  const [stateModal, setStateModal] = useState({
    open: false,
    openEdit: false,
    curp: "",
    curpError: false,
    sexo: "",
    sexoError: false,
    nombre: "",
    nombreError: false,
    apePaterno: "",
    apePaternoError: false,
    apeMaterno: "",
    apeMaternoError: false,
    email: "",
    emailError: false,
    emailConfirm: "",
    emailConfirmError: false,
    telefono: "",
    telefonoError: false,
    rolUsuario: 0,
    rolUsuarioError: false,
    tituloUsuario: "",
    tituloUsuarioError: false,
    estadoUsuario: "",
    estadoUsuarioError: false,
    procedenciaUsuario: 0, //id camara/comision
    procedenciaUsuarioError: false,
    catalago: {},
    origen: "", // comision o camara
    status: true, //activo o inactivo
    id: 0,
  });

  const { showAlertConfirm, showLoader, showToast } = useAlerts();

  // VALIDAR CAMPOS VACIOS EN LOS INPUTS DEL MODAL AÑADIR USUARIOS
  const [stateValidate, setStateValidate] = useState(false);

  // CARGAR DATOS EN EL MODAL AÑADIR USUARIO
  const [loadData, setLoadData] = useState(null);
  const [loadDataEdit, setLoadDataEdit] = useState(null);

  const handleShowModal = (action) => {
    setStateModal({ ...stateModal, open: action });
  };

  const handleShowModalEdit = (action) => {
    setStateModalEdit({ ...stateModalEdit, open: action });
  };

  const [stateModalEdit, setStateModalEdit] = useState({
    open: false,
    openEdit: false,
  });

  // USUARIOS
  const [stateUsuarios, setStateUsuarios] = useState({
    usuarios: [],
  });

  // INVITAR USUARIOS
  // const [stateUsuariosInvite, setStateUsuariosInvite] = useState({
  //   usuariosInvite: [],
  // });

  // ID'S DE USUARIOS ALMACENADOS
  // const [idUsersInvite, setIdUsersInvite] = useState({
  //   idUsers: [],
  // });

  const handleClose = () => {
    setStateModal({
      ...stateModal,
      open: false,
      curp: "",
      nombre: "",
      email: "",
      emailConfirm: "",
      telefono: "",
      apePaterno: "",
      apeMaterno: "",
      sexo: "",
      rolUsuario: "",
      tituloUsuario: "",
      estadoUsuario: "",
      procedenciaUsuario: "",
    });
    setStateModalEdit({ ...stateModal, open: false });
  };

  const handleClickBackdrop = (event) => {
    event.stopPropagation();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validarDatos(
      stateModal.curp,
      stateModal.email,
      stateModal.emailConfirm,
      stateModal.telefono,
      stateModal.nombre,
      stateModal.apePaterno,
      stateModal.apeMaterno,
      stateModal.sexo,
      stateModal.rolUsuario,
      stateModal.tituloUsuario,
      stateModal.estadoUsuario,
      stateModal.procedenciaUsuario
    );
  };

  const formatText = (text) => {
    // Dividir el texto en palabras
    const words = text.split(" ");
    // Aplicar formato a cada palabra
    const formattedWords = words.map((word) => {
      // Convertir la primera letra de cada palabra a mayúscula y el resto a minúscula
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    // Unir las palabras formateadas de nuevo en un solo texto
    return formattedWords.join(" ");
  };

  const handleCurp = async (e) => {
    const curpValue = e.target.value.toUpperCase();

    // REGEX VALIDAR FORMULARIO
    const regexCurp =
      /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/i;
    const validCurp = regexCurp.test(e.target.value);
    setStateModal({ ...stateModal, curp: curpValue });
    // if (e === null) {
    // } else

    if (curpValue.length === 18) {
      // VALIDAR FORMULARIO ANTES DE HACER LA PETICION
      if (!validCurp) {
        showAlertConfirm(
          "info",
          "Información",
          "Por favor ingresa una CURP válida."
        );
        return;
      } else {
        // ENTRAR EN LA PETICION SI LA CURP ES VALIDA
        showLoader();
        const { execute, data } = await services(
          "GET",
          `v1/services/curp/${curpValue}`
        );

        if (execute) {
          setStateModal({
            ...stateModal,
            curp: curpValue,
            nombre: data.data.nombre,
            apePaterno: data.data.apellidoPaterno,
            apeMaterno: data.data.apellidoMaterno,
            sexo: data.data.sexo,
          });
          showToast("success", "CURP encontrada", "bottom-end", 2000);
        } else {
          showToast("error", "CURP no encontrada", "bottom-end", 2000);
          setStateModal({
            ...stateModal,
            curp: curpValue,
            nombre: "",
            sexo: "",
            apeMaterno: "",
            apePaterno: "",
          });
        }
      }
    }
  };

  // ENVIAR DATOS CARGADOS AL MODAL EDITAR
  const setCurrentUsuarios = (
    curp,
    sexo,
    nombre,
    apePaterno,
    apeMaterno,
    rolUsuario,
    tituloUsuario,
    email,
    telefono,
    catalago,
    id,
    idCatalago,
    origen
  ) => {
    if (catalago === null) {
      handleShowModalEdit(true);
      setStateModal({
        stateModal,
        curp,
        sexo,
        nombre,
        apePaterno,
        apeMaterno,
        rolUsuario,
        tituloUsuario,
        email,
        telefono,
        catalago,
        id,
      });
    } else {
      // const procedenciaUsuario =
      //   catalago.camara !== null ? catalago.camara.id : catalago.comision.id;
      const procedenciaUsuario = idCatalago;
      // const origen = catalago.camara !== null ? "chamber" : "commission";

      valorRadioEdit(origen);
      handleShowModalEdit(true);
      setStateModal({
        stateModal,
        curp,
        sexo,
        nombre,
        apePaterno,
        apeMaterno,
        rolUsuario,
        tituloUsuario,
        email,
        telefono,
        catalago,
        origen,
        id,
        procedenciaUsuario,
      });
    }
  };

  const handleSexo = (e) => {
    // console.log(typeof e);
    setStateModal({ ...stateModal, sexo: e.target.value });
  };

  const handleNombre = (e) => {
    const nombreValue = e.target.value.toUpperCase();
    setStateModal({ ...stateModal, nombre: nombreValue });
  };

  const handleApePaterno = (e) => {
    const apePaternoValue = e.target.value.toUpperCase();
    setStateModal({ ...stateModal, apePaterno: apePaternoValue });
  };

  const handleApeMaterno = (e) => {
    const apeMaternoValue = e.target.value.toUpperCase();
    setStateModal({ ...stateModal, apeMaterno: apeMaternoValue });
  };

  const handleEmail = (e) => {
    setStateModal({ ...stateModal, email: e.target.value });
  };

  const handleEmailConfirm = (e) => {
    setStateModal({ ...stateModal, emailConfirm: e.target.value });
  };

  const handleTelefono = (e) => {
    setStateModal({ ...stateModal, telefono: e.target.value });
  };

  const handlePassword = (e) => {
    setStateModal({ ...stateModal, password: e.target.value });
  };

  const [disableInput, setDisableInput] = useState(true);

  const [selectedRole, setSelectedRole] = useState("");

  const handleRol = (e) => {
    setStateModal({ ...stateModal, rolUsuario: parseInt(e.target.value) });
    setSelectedRole(e.target.value);
  };

  const invitadoSelected = selectedRole === 4;

  const handleTitulo = (e) => {
    setStateModal({ ...stateModal, tituloUsuario: e.target.value });
  };
  const handleEstado = (e) => {
    setStateModal({ ...stateModal, estadoUsuario: e.target.value });
  };

  const handleProcedencia = (e) => {
    setStateModal({ ...stateModal, procedenciaUsuario: e.target.value });
  };

  const handleEditClose = () => {
    setStateModal({ ...stateModal, openEdit: false });
    verSolicitudes();
  };

  // DESCARTAR CAMBIOS
  const limpiarFormulario = () => {
    setStateModal({
      curp: "",
      nombre: "",
      sexo: "",
      apeMaterno: "",
      apePaterno: "",
      rol: 0,
      tituloUsuario: "",
      estadoUsuario: "",
      telefono: "",
      email: "",
      emailConfirm: "",
      procedenciaUsuario: "",
    });
    setDisableInput(true);
    setStateValidate(false);
  };

  // LOGICA DEL CARGADO DE DATOS DE LA PROCEDENCIA
  const valorRadio = (value) => {
    if (value === "commission") {
      setDisableInput(false);
      setLoadData(false);
      setStateModal({ ...stateModal, origen: value });
      return;
    } else setStateModal({ ...stateModal, origen: value });
    setDisableInput(false);
    setLoadData(true);
  };

  const valorRadioEdit = (value) => {
    if (value === "commission") {
      //setDisableInput(false);
      setLoadDataEdit(false);
      setStateModal({ ...stateModal, origen: value });
      return;
    } else setStateModal({ ...stateModal, origen: value });
    //setDisableInput(false);
    setLoadDataEdit(true);
  };

  // VALIDAR DATOS GENERALES DE LA VISTA MODAL DE AÑADIR USUARIOS DEL COMPONENTE usuariosView
  const validarDatos = async () => {
    // const regexCurp =
    //   /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/i;
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setStateValidate(true);

    const {
      curp,
      email,
      emailConfirm,
      telefono,
      nombre,
      apePaterno,
      apeMaterno,
      sexo,
      rolUsuario,
      tituloUsuario,
      estadoUsuario,
      procedenciaUsuario,
      origen,
    } = stateModal;

    const validEmail = regexEmail.test(email);
    // const validCurp = regexCurp.test(curp);

    // Validacion CURP VACIA
    if (curp.trim() === "") {
      showAlertConfirm("info", "Información", "Por favor ingresa la CURP.");
    } else if (sexo === "") {
      showAlertConfirm("info", "Información", "Por favor ingresa el género.");
      // Validacion NOMBRE
    } else if (nombre.trim() === "") {
      showAlertConfirm("info", "Información", "Por favor ingresa el nombre.");
      // Validacion APELLIDO PATERNO
    } else if (apePaterno.trim() === "") {
      showAlertConfirm(
        "info",
        "Información",
        "Por favor ingresa el apellido paterno."
      );
      // Validacion ROL DEL USUARIO
    } else if (rolUsuario === 0) {
      showAlertConfirm("info", "Información", "Por favor el rol del usuario.");
      // Validacion TITULO DEL USUARIO
    } else if (tituloUsuario === "") {
      showAlertConfirm(
        "info",
        "Información",
        "Por favor selecciona el título del usuario."
      );
      // Validacion ESTADO DEL USUARIO
    } else if (estadoUsuario === "") {
      showAlertConfirm(
        "info",
        "Información",
        "Por favor selecciona el estado del usuario."
      );
      // Validacion NUMERO DE TELEFONO
    } else if (telefono.trim() === "") {
      showAlertConfirm("info", "Información", "Por favor ingresa el teléfono.");
      // Validacion EMAIL
    } else if (email.trim() === "") {
      showAlertConfirm("info", "Información", "Por favor ingresa el email.");
    } else if (!validEmail) {
      showAlertConfirm("error", "Información", "El email es incorrecto");
    } else if (emailConfirm === "") {
      showAlertConfirm(
        "error",
        "Información",
        "Por favor coloca el email de confirmación."
      );
    } else if (email.trim() !== emailConfirm.trim()) {
      showAlertConfirm("error", "Información", "Los emails no coinciden.");
      // Validacion PROCEDENCIA DEL USUARIO
    } else if (selectedRole !== 4) {
      if (procedenciaUsuario === 0) {
        // (procedenciaUsuario === 0) {
        showAlertConfirm(
          "info",
          "Información",
          "Por favor ingresa la procedencia del usuario."
        );

        // ENVIAR DATA
      } else {
        await sendData(
          origen,
          curp,
          nombre,
          apePaterno,
          apeMaterno,
          sexo,
          rolUsuario,
          tituloUsuario,
          estadoUsuario,
          email,
          telefono,
          procedenciaUsuario
        );
        setStateModal({
          stateModal,
          curp: "",
          nombre: "",
          apePaterno: "",
          apeMaterno: "",
          sexo: "",
          rol: 0,
          titulo: "",
          estado: "",
          correo: "",
          telefono: "",
        });
        setStateValidate(false);
      }
    } else {
      await sendDataInv(
        curp,
        nombre,
        apePaterno,
        apeMaterno,
        sexo,
        rolUsuario,
        tituloUsuario,
        estadoUsuario,
        email,
        telefono
      );
      setStateModal({
        stateModal,
        curp: "",
        nombre: "",
        apePaterno: "",
        apeMaterno: "",
        sexo: "",
        rol: 0,
        titulo: "",
        estado: "",
        correo: "",
        telefono: "",
      });
      setStateValidate(false);
    }
  };

  const { services } = useApi();

  const returnCatalogo = (valor) => {
    let catalago = valor?.camara;
    let catalagoC = valor?.comision;
    if (catalago) {
      let catalago = valor?.camara.nombre;
      return `Cámara: ${catalago}`;
    } else if (catalagoC) {
      let catalago = valor?.comision.nombre;
      return `Comisión: ${catalago}`;
    } else {
      return `N/A`;
    }
  };

  const returnCatalogoId = (valor) => {
    let catalago = valor?.camara;
    let catalagoC = valor?.comision;
    if (catalago) {
      let catalagoId = valor?.camara.id;
      return catalagoId;
    } else if (catalagoC) {
      let catalagoId = valor?.comision.id;
      return catalagoId;
    }
  };

  const returnCatalogoOrigen = (valor) => {
    if (valor === null) {
      return;
    } else if (valor.camara !== null) {
      return "chamber";
    } else {
      return "commission";
    }
  };

  // OBTENER USUARIOS GET
  const verSolicitudes = async () => {
    const { execute, data } = await services("GET", "v1/users");
    if (execute) {
      let newArray = [];
      data.data.map((element) =>
        newArray.push({
          nombre: element.nombre,
          apellidoPaterno: element.apellidoPaterno,
          apeMaterno: element.apellidoMaterno,
          estado: element.estado.estado,
          rol: element.rol.rol,
          rolId: element.rol.id,
          catalago: returnCatalogo(element.catalago),
          catalagoId: returnCatalogoId(element.catalago),
          catalagoOrigen: returnCatalogoOrigen(element.catalago),
          curp: element.curp,
          sexo: element.sexo,
          tituloId: element.titulo.id,
          correo: element.correo,
          telefono: element.telefono,
          status: element.activo ? "ACTIVO" : "INACTIVO",
          id: element.id,
        })
      );

      setStateUsuarios({ ...stateUsuarios, usuarios: newArray });
      showToast("success", "Carga de información completa", "bottom-end", 2000);
    }
  };

  // OBTENER USUARIOS PARA INVITAR A REUNIONES GET
  // const getUsuariosInvite = async () => {
  //   const { execute, data } = await services("GET", "v1/users/invite");
  //   if (execute) {
  //     setStateUsuariosInvite({
  //       ...stateUsuariosInvite,
  //       usuariosInvite: data.data,
  //     });
  //     showToast("success", "Carga de información completa", "bottom-end", 2000);
  //   }
  // };

  // const getUsuariosId = async () => {
  //   const { execute, data } = await services("GET", "v1/users/invite");
  //   if (execute) {
  //     setIdUsersInvite({
  //       ...idUsersInvite,
  //       idUsers: data.data.map((idUser) => idUser.id),
  //     });
  //   }
  // };

  const [password, setPassword] = useState("");
  const [modalPassword, setModalPassword] = useState(false);

  // CREAR USUARIO POST
  const sendData = async (
    origen,
    curp,
    nombre,
    apePaterno,
    apeMaterno,
    sexo,
    rol,
    titulo,
    estado,
    correo,
    telefono,
    procedenciaUsuario
  ) => {
    const formData = new FormData();

    formData.set("curp", curp);
    formData.set("nombre", nombre);
    formData.set("apellidoPaterno", apePaterno);
    formData.set("apellidoMaterno", apeMaterno);
    formData.set("sexo", sexo);
    formData.set("rol", rol);
    formData.set("origen", origen);
    formData.set("id", procedenciaUsuario);
    formData.set("titulo", titulo);
    formData.set("estado", estado);
    formData.set("correo", correo);
    formData.set("telefono", telefono);

    showLoader();

    const { execute, data } = await services("POST", "v1/users", formData);

    if (execute) {
      const passwordUser = data.data.password;
      setPassword(passwordUser);
      limpiarFormulario();
      setModalPassword(true);
      /*  await alert(`La contraseña del usuario es: ${passwordUser}`); */
      /*   await showAlertCopy(
        "success",
        data.mensaje,
        `${passwordUser}`,
        "Copiar Contraseña"
      ); */
      /*  await showToast(
        "success",
        "Contraseña copiada correctamente",
        "bottom-end",
        2000
      ); */
      await verSolicitudes();
    }
  };

  const sendDataInv = async (
    curp,
    nombre,
    apePaterno,
    apeMaterno,
    sexo,
    rol,
    titulo,
    estado,
    correo,
    telefono
  ) => {
    const formData = new FormData();

    formData.set("curp", curp);
    formData.set("nombre", nombre);
    formData.set("apellidoPaterno", apePaterno);
    formData.set("apellidoMaterno", apeMaterno);
    formData.set("sexo", sexo);
    formData.append("rol", rol);
    formData.set("titulo", titulo);
    formData.set("estado", estado);
    formData.set("correo", correo);
    formData.set("telefono", telefono);

    showLoader();

    const { execute, data } = await services("POST", "v1/users", formData);

    if (execute) {
      const password = data.data.password;
      setPassword(password);
      setModalPassword(true);

      /*   await showAlertCopy(
        "success",
        data.mensaje,
        `${password}`,
        "Copiar Contraseña"
      );
      await showToast(
        "success",
        "Contraseña copiada correctamente",
        "bottom-end",
        2000
      ); */
      await verSolicitudes();
    }
  };

  // EDITAR USUARIO
  const editarUsuario = async () => {
    const dataUser = {
      curp: stateModal.curp,
      nombre: stateModal.nombre,
      apellidoPaterno: stateModal.apePaterno,
      apellidoMaterno: stateModal.apeMaterno,
      sexo: stateModal.sexo,
      rol: stateModal.rolUsuario,
      titulo: stateModal.tituloUsuario,
      estado: stateModal.estadoUsuario,
      correo: stateModal.email,
      telefono: stateModal.telefono,
      origen: stateModal.origen,
      id: stateModal.procedenciaUsuario,
    };

    showLoader();

    if (
      stateModal.curp.trim() === "" ||
      stateModal.nombre.trim() === "" ||
      stateModal.apePaterno.trim() === "" ||
      stateModal.apeMaterno.trim() === "" ||
      stateModal.email.trim() === "" ||
      stateModal.telefono.trim() === "" ||
      stateModal.origen.trim() === ""
    ) {
      showAlertConfirm("info", "Todos los campos son obligatorios");
    } else {
      const { execute, data } = await services(
        "PUT",
        `v1/users/${stateModal.id}`,
        dataUser
      );

      if (execute) {
        verSolicitudes();
        limpiarFormulario();
        handleShowModalEdit(false);
        showAlertConfirm("success", data && data.mensaje);
      }
    }
  };

  // METODO DELETE (INHABILITAR)
  const deleteUser = async (id, status) => {
    const confirmation = await showAlertConfirm(
      "question",
      "Confirmación",
      `¿Estás seguro de desea ${
        status === "ACTIVO" ? "INHABILITAR" : "HABILITAR"
      }  este usuario?`,
      true
    );

    if (confirmation) {
      showLoader();
      const { execute, data } = await services("DELETE", `v1/users/${id}`);
      if (execute) {
        await verSolicitudes();
        showAlertConfirm(
          "success",
          `Usuario ${
            status === "ACTIVO" ? "INHABILITADO" : "HABILITADO"
          } exitosamente`
        );
      } else {
        showAlertConfirm("error", data.mensaje);
      }
    }
  };

  return {
    stateUsuarios,
    setStateUsuarios,
    stateModal,
    setStateModal,
    verSolicitudes,
    handleShowModal,
    handleClose,
    handleEditClose,
    handleCurp,
    handleSexo,
    handleNombre,
    handleApePaterno,
    handleApeMaterno,
    handleEmail,
    handleEmailConfirm,
    handleTelefono,
    handlePassword,
    handleRol,
    handleEstado,
    handleTitulo,
    handleProcedencia,
    handleShowModalEdit,
    stateModalEdit,
    setStateModalEdit,
    validarDatos,
    limpiarFormulario,
    stateValidate,
    setStateValidate,
    loadData,
    setLoadData,
    valorRadio,
    handleSubmit,
    deleteUser,
    disableInput,
    setCurrentUsuarios,
    valorRadioEdit,
    loadDataEdit,
    editarUsuario,
    formatText,
    handleClickBackdrop,
    invitadoSelected,
    //getUsuariosInvite,
    //stateUsuariosInvite,
    password,
    modalPassword,
    setModalPassword,
    // idUsersInvite,
    // getUsuariosId,
  };
};
export default useUsuarios;
