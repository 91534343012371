import imageLogin from "../../../assets/images/roundedConago.png";
import CheckIcon from "@mui/icons-material/Check";
import LockResetIcon from "@mui/icons-material/LockReset";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import hookForgot from "../customHooks/useForgotPassword";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect } from "react";

import {
  Button,
  Typography,
  Grid,
  TextField,
  Container,
  Card,
  CardContent,
  CardHeader,
  Box,
  CssBaseline,
  Alert,
} from "@mui/material";

const defaultTheme = createTheme();
const ForgetPassword = () => {
  const {
    stateForgot,
    navigate,
    handleState,
    handleForget,
    parametro,
    handleStatePass,
    handleStateConfirmPass,
    load,
    setLoad,
    validarRecovery,
  } = hookForgot();

  const token = localStorage.getItem("token");

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (token !== null) {
        navigate("/reuniones");
      }
    }
    return () => {
      isMounted = false;
    };
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  return (
    <>
      {parametro ? (
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Card
                  sx={{
                    marginTop: 16,
                    marginBottom: 15,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                  }}
                >
                  <CardHeader
                    title={
                      <img
                        src={imageLogin}
                        alt="Tu imagen alternativa"
                        style={{
                          width: "30%",
                          height: "auto",
                          display: "block",
                          margin: "auto",
                          verticalAlign: "middle",
                        }}
                      />
                    }
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <b className="mb-1">Restablecimiento de contraseña</b>
                      <Alert
                        icon={<LockResetIcon fontSize="inherit" />}
                        severity="warning"
                      >
                        Ingresa tu nueva contraseña y su confirmación.{" "}
                        <b>
                          Recuerda que tu contraseña debe tener al menos 8
                          caracteres.
                        </b>
                      </Alert>
                      <Grid container>
                        <Grid item xs={12} md={12}>
                          <TextField
                            type="password"
                            fullWidth
                            margin="normal"
                            required
                            id="email"
                            label="Contraseña"
                            name="email"
                            autoComplete="email"
                            value={stateForgot.password}
                            onChange={handleStatePass}
                          />
                          {stateForgot.sendNewPassword &
                          !stateForgot.password ? (
                            <Typography
                              sx={{ fontSize: "14px" }}
                              variant="span"
                              color="error"
                            >
                              Nueva contraseña requerida*
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            type="password"
                            fullWidth
                            margin="normal"
                            required
                            id="confirmPwd"
                            label="Confirmación de contraseña"
                            name="email"
                            autoComplete="email"
                            value={stateForgot.confirmPassword}
                            onChange={handleStateConfirmPass}
                          />
                          {stateForgot.sendNewPassword &
                          !stateForgot.confirmPassword ? (
                            <Typography
                              sx={{ fontSize: "14px" }}
                              variant="span"
                              color="error"
                            >
                              Confirmación de contraseña requerida*
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                      <div>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Button
                              onClick={() => navigate("/")}
                              variant="contained"
                              endIcon={<KeyboardReturnIcon />}
                              loading={load}
                              sx={{
                                mr: 4,
                                mt: 1,
                                alignItems: "center",
                                backgroundColor: "#606770",
                              }}
                            >
                              Regresar
                            </Button>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Button
                              onClick={validarRecovery}
                              variant="contained"
                              endIcon={<CheckIcon />}
                              loading={load}
                              sx={{
                                mt: 1,
                                mb: 2,
                                alignItems: "center",
                                backgroundColor: "#a70631",
                              }}
                            >
                              Enviar
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" maxWidth="md">
            <CssBaseline />
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Card
                  sx={{
                    marginTop: 16,
                    marginBottom: 15,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                  }}
                >
                  <CardHeader
                    title={
                      <img
                        src={imageLogin}
                        alt="Tu imagen alternativa"
                        style={{
                          width: "30%",
                          height: "auto",
                          display: "block",
                          margin: "auto",
                          verticalAlign: "middle",
                        }}
                      />
                    }
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <b className="mb-1">
                        Solicitud de restablecimiento de contraseña
                      </b>
                      <Alert
                        icon={<LockResetIcon fontSize="inherit" />}
                        severity="warning"
                      >
                        Por favor ingresa el correo electrónico que utilizas
                        para ingresar a esta plataforma. Se te enviará un correo
                        con el enlace de restablecimiento de contraseña.
                      </Alert>
                      <TextField
                        fullWidth
                        margin="normal"
                        required
                        id="email"
                        label="Correo"
                        name="email"
                        autoComplete="email"
                        onChange={handleState}
                        value={stateForgot.email}
                      />
                      {!stateForgot.email && stateForgot.sendMail ? (
                        <Typography
                          sx={{ fontSize: "14px" }}
                          variant="span"
                          color="error"
                        >
                          Correo para recuperación de contraseña es
                          obligatorio.*
                        </Typography>
                      ) : (
                        ""
                      )}
                      <div>
                        <Grid container>
                          <Grid item xs={6} md={6}>
                            <Button
                              onClick={() => navigate("/")}
                              variant="contained"
                              endIcon={<KeyboardReturnIcon />}
                              loading={load}
                              sx={{
                                mr: 4,
                                mt: 1,
                                mb: 2,
                                alignItems: "center",
                                backgroundColor: "#606770",
                              }}
                            >
                              Regresar
                            </Button>
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <Button
                              onClick={() => {
                                setLoad(true);
                                handleForget();
                              }}
                              variant="contained"
                              endIcon={<CheckIcon />}
                              loading={load}
                              sx={{
                                mt: 1,
                                mb: 2,
                                alignItems: "center",
                                backgroundColor: "#a70631",
                              }}
                            >
                              Enviar
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </ThemeProvider>
      )}
    </>
  );
};
export default ForgetPassword;
