import { useState } from "react";
import useApi from "../../../services/useApi";
import useAlerts from "../../../services/useAlerts";

const useTitulos = () => {
  const [stateModal, setStateModal] = useState({
    open: false,
    openEdit: false,
    nombre: "",
    nombreError: false,
    id: "",
  });

  const [stateModalEdit, setStateModalEdit] = useState({
    open: false,
    openEdit: false,
  });

  const { showAlertConfirm, showLoader, showToast } = useAlerts();
  const [error, setError] = useState({});

  const handleShowModal = (action) => {
    setStateModal({ ...stateModal, open: action, nombre: "" });
  };

  const handleShowModalEdit = (action) => {
    setStateModalEdit({ ...stateModalEdit, open: action });
  };

  const handleNombre = (e) => {
    setStateModal({ ...stateModal, nombre: e.target.value });
  };

  const { services } = useApi();

  const handleSubmit = (e) => {
    e.preventDefault();
    validateNombre(stateModal.nombre);
  };

  const handleKeyDown = (e, id) => {
    if (e.key === "Enter") {
      e.preventDefault();
      editTitle(id); // Llama a la función para enviar la petición
    }
  };

  const sendData = async (nombre) => {
    const formData = new FormData();

    formData.set("nombre", nombre);

    showLoader();
    const { execute, data } = await services("POST", "v1/titles", formData);

    if (execute) {
      showAlertConfirm("success", data.mensaje);
    }
  };

  const validateNombre = async () => {
    let regexNombre = /^[A-Z][a-z]*\.$/;

    const { nombre } = stateModal;

    const valido = nombre.match(regexNombre);

    if (nombre.trim() !== "" && nombre.length <= 10 && valido) {
      handleShowModal(false);
      await sendData(nombre);
      await setStateModal({ stateModal, nombre: "" });
      await verSolicitudes();
    } else if (nombre.trim() === "") {
      // setStateModal({ ...stateModal, sendFlag: true });
      showAlertConfirm("info", "Nombre requerido");
    } else if (!valido) {
      // setStateModal({ ...stateModal, sendFlag: true });
      showAlertConfirm(
        "info",
        "El título debe de iniciar con una letra mayúscula y terminar con un punto"
      );
    } else if (nombre.length > 10) {
      // setStateModal({ ...stateModal, sendFlag: true });
      showAlertConfirm("info", "No puede contener más de 10 caracteres");
    }
  };

  const editTitle = async (id) => {
    let regexNombre = /^[A-Z][a-z]*\.$/;
    const formData = new FormData();
    formData.append("nombre", stateModal.nombre);
    const { nombre } = stateModal;

    const valido = nombre.match(regexNombre);

    showLoader();
    if (nombre.trim() !== "" && nombre.length <= 10 && valido) {
      const { execute, data } = await services(
        "PUT",
        `v1/titles/${id}`,
        formData
      );
      if (execute) {
        await verSolicitudes();
        handleShowModalEdit(false);
        showAlertConfirm("success", data.mensaje);
        setStateModal({ stateModal, nombre: "" });
      }
    } else if (nombre.trim() === "") {
      // setStateModal({ ...stateModal, sendFlag: true });
      showAlertConfirm("info", "Nombre requerido");
    } else if (!valido) {
      // setStateModal({ ...stateModal, sendFlag: true });
      showAlertConfirm(
        "info",
        "El título debe de iniciar con una letra mayúscula y terminar con un punto"
      );
    } else if (nombre.length > 10) {
      // setStateModal({ ...stateModal, sendFlag: true });
      showAlertConfirm("info", "No puede contener más de 10 caracteres");
    }
  };

  const setCurrentTitle = (id, nombre) => {
    handleShowModalEdit(true);
    setStateModal({ stateModal, nombre: nombre, id: id });
  };

  const deleteTitle = async (id) => {
    const confirmation = await showAlertConfirm(
      "question",
      "Confirmación",
      "¿Estás seguro de eliminar este título?",
      true
    );
    if (confirmation) {
      showLoader();
      const { execute, data } = await services("DELETE", `v1/titles/${id}`);
      if (execute) {
        await verSolicitudes();
        showAlertConfirm("success", data.mensaje);
      }
    }
  };

  const [stateTitulos, setStateTitulos] = useState({
    solicitudes: [],
  });

  const verSolicitudes = async () => {
    const { execute, data } = await services("GET", "v1/titles");
    if (execute) {
      setStateTitulos({ ...stateTitulos, solicitudes: data.data });
      showToast("success", "Carga de información completa", "bottom-end", 2000);
    }
  };

  const loadTitulos = async () => {
    const { execute, data } = await services("GET", "v1/titles");
    if (execute) {
      setStateTitulos({ ...stateTitulos, solicitudes: data.data });
    }
  };

  const validarCreacion = () => {
    let newErrors = {};
    if (stateModal.nombre.trim() === "") {
      newErrors.nombreError = "Este campo es requerido";
    }

    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return {
    stateTitulos,
    setStateTitulos,
    stateModal,
    setStateModal,
    verSolicitudes,
    handleNombre,
    validarCreacion,
    error,
    handleSubmit,
    deleteTitle,
    handleShowModal,
    editTitle,
    stateModalEdit,
    setStateModalEdit,
    handleShowModalEdit,
    setCurrentTitle,
    handleKeyDown,
    loadTitulos,
  };
};
export default useTitulos;
