import { useEffect } from "react";
import DataTable from "../../general/GlobalComponents/DataTable";
import useCamaras from "../customHooks/useCamaras";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { DeleteForever, Edit, Add, Close, Save } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAlerts from "../../../services/useAlerts";
import { useMediaQuery } from '@mui/material';

const Camaras = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
  };

  const {
    stateCamaras,
    stateModal,
    setStateModal,
    verSolicitudes,
    handleShowModal,
    handleNombre,
    handleSubmit,
    deleteChamber,
    editChamber,
    stateModalEdit,
    handleShowModalEdit,
    setCurrentCamera,
    handleKeyDown,
  } = useCamaras();

  const { showLoader } = useAlerts();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let isMounted = true;
    showLoader();
    if (isMounted) {
      verSolicitudes();
      isMounted = false;
    }
    return () => {
      isMounted = false;
    };
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  const columns = [
    {
      name: "nombre",
      label: "Nombre",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "left" }}>{value}</div>
        ),
      },
    },
    {
      name: "acciones",
      label: "ACCIONES",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <>
            <Tooltip disableFocusListener title="Eliminar Cámara">
              <IconButton
                aria-label="Eliminar"
                onClick={() => {
                  deleteChamber(stateCamaras.solicitudes[dataIndex].id);
                }}
              >
                <DeleteForever color="error" />
              </IconButton>
            </Tooltip>
            <Tooltip disableFocusListener title="Editar Cámara">
              <IconButton
                aria-label="Editar"
                onClick={() => {
                  setCurrentCamera(
                    stateCamaras.solicitudes[dataIndex].id,
                    stateCamaras.solicitudes[dataIndex].nombre
                  );
                }}
              >
                <Edit color="primary" />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },
  ];

  return (
    <>
      <Grid className="container mt-5 mb-2">
        <Grid item xs={12} md={6} className="text-right-custom pe-5">
          <LoadingButton
            className="background-colorUI"
            type="submit"
            variant="contained"
            endIcon={<Add />}
            loading={false}
            value={stateModal.nombre}
            sx={{
              mt: 1,
              mb: 2,
              alignItems: "center",
            }}
            onClick={(e) => {
              e.preventDefault();
              handleShowModal(true);
            }}
          >
            Añadir Cámara
          </LoadingButton>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <DataTable
              title={
                <span>
                  <h5>Cámaras de Comercio</h5>
                </span>
              }
              data={stateCamaras.solicitudes}
              columns={columns}
            />
          </Grid>
        </Grid>
      </Grid>

      {stateModal.open ? (
        <Modal
          open={stateModal.open}
          onClose={() => handleShowModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={styleModal}
            component="form"
            onSubmit={handleSubmit}
            noValidate
          >
            <h4 style={{ color: "#a70631", fontSize: "20px" }}>
              Añadir Cámara
            </h4>
            <IconButton
              className={
                "border-0 position-absolute p-1 top-0 end-0 m-2 rounded-circle"
              }
              onClick={() => handleShowModal(false)}
            >
              <Close />
            </IconButton>
            <Grid container columns={12} spacing={1}>
              <Grid item xs={12}>
                <TextField
                  {...(stateModal.nombre.trim() === "" &&
                  stateModal.sendFlag ? (
                    <Typography
                      sx={{ fontSize: "14px" }}
                      variant="span"
                      color="error"
                    >
                      Nombre requerido*
                    </Typography>
                  ) : (
                    ""
                  ))}
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                  size="small"
                  id="nombreCamara"
                  label="Nombre"
                  type="text"
                  error={stateModal.nombreError}
                  value={stateModal.nombre}
                  variant="filled"
                  onChange={handleNombre}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6} className="text-right-custom ">
                <LoadingButton
                  variant="contained"
                  endIcon={<Close />}
                  loading={false}
                  sx={{
                    mt: 3,
                    mb: 2,
                    mr: 1,
                    ml: 1,
                    alignItems: "center",
                    backgroundColor: "#ABB2B9",
                  }}
                  onClick={() => {
                    handleShowModal(false);
                    setStateModal({ stateModal, nombre: "" });
                  }}
                >
                  Cancelar
                </LoadingButton>
              </Grid>
              <Grid item xs={6} md={6} className="text-left-custom ">
                <LoadingButton
                  type="submit"
                  variant="contained"
                  endIcon={<Save />}
                  loading={false}
                  sx={{
                    mt: 3,
                    mb: 2,
                    ml: 1,
                    mr: 1,
                    alignItems: "center",
                    backgroundColor: "#a70631",
                  }}
                >
                  Guardar
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      ) : null}

      {stateModalEdit.open ? (
        <Modal
          open={stateModalEdit.open}
          onClose={() => handleShowModalEdit(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal} component="form" noValidate>
            <h4 style={{ color: "#a70631", fontSize: "20px" }}>
              Editar Cámara
            </h4>
            <IconButton
              className={
                "border-0 position-absolute p-1 top-0 end-0 m-2 rounded-circle"
              }
              onClick={() => handleShowModalEdit(false)}
            >
              <Close />
            </IconButton>
            <Grid container columns={12} spacing={1}>
              <Grid item xs={12}>
                <TextField
                  {...(stateModal.nombre.trim() === "" &&
                  stateModal.sendFlag ? (
                    <Typography
                      sx={{ fontSize: "14px" }}
                      variant="span"
                      color="error"
                    >
                      Nombre requerido*
                    </Typography>
                  ) : (
                    ""
                  ))}
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                  size="small"
                  id="nombreCamara"
                  label="Nombre"
                  type="text"
                  error={stateModal.nombreError}
                  value={stateModal.nombre}
                  variant="filled"
                  onChange={handleNombre}
                  onKeyDown={(e) => handleKeyDown(e, stateModal.id)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6} className="text-right-custom ">
                <LoadingButton
                  variant="contained"
                  endIcon={<Close />}
                  loading={false}
                  sx={{
                    mt: 3,
                    mb: 2,
                    mr: 1,
                    ml: 1,
                    alignItems: "center",
                    backgroundColor: "#ABB2B9",
                  }}
                  onClick={() => {
                    handleShowModalEdit(false);
                    setStateModal({ stateModal, nombre: "" });
                  }}
                >
                  Cancelar
                </LoadingButton>
              </Grid>
              <Grid item xs={6} md={6} className="text-left-custom ">
                <LoadingButton
                  variant="contained"
                  endIcon={<Save />}
                  loading={false}
                  sx={{
                    mt: 3,
                    mb: 2,
                    ml: 1,
                    mr: 1,
                    alignItems: "center",
                    backgroundColor: "#a70631",
                  }}
                  onClick={() => editChamber(stateModal.id)}
                >
                  Guardar
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      ) : null}
      <Grid className="d-lg-none" style={{ margin: "150px" }}></Grid>
    </>
  );
};

export default Camaras;
