import React, { useEffect } from "react";
import hookLogin from "../customHooks/useLogin";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoginIcon from "@mui/icons-material/Login";
import imageLogin from "../../../assets/images/roundedConago.png";

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Container,
  Box,
  Typography,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

const defaultTheme = createTheme();
const Login = () => {
  const {
    stateLogin,
    handleStateEmail,
    handleStatePass,
    navigate,
    handleSubmit,
    validateLogin,
    loadLogin,
    handleKeyDown,
  } = hookLogin();

  const token = localStorage.getItem("token");

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (token !== null) {
        navigate("/reuniones");
      }
    }
    return () => {
      isMounted = false;
    };
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Card
              sx={{
                marginTop: 16,
                marginBottom: 15,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
              }}
            >
              <CardHeader
                title={
                  <img
                    src={imageLogin}
                    alt="Tu imagen alternativa"
                    style={{
                      width: "25%",
                      height: "auto",
                      display: "block",
                      margin: "auto",
                      verticalAlign: "middle",
                    }}
                  />
                }
              />
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography component="h4" variant="h6">
                    ¡Bienvenido/a!
                  </Typography>
                  Inicia sesión
                  <Box
                    // component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                  >
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Correo"
                      name="email"
                      autoComplete="email"
                      onChange={handleStateEmail}
                      value={stateLogin.email}
                      onKeyDown={handleKeyDown}
                    />
                    {!stateLogin.email && stateLogin.sendFlag ? (
                      <Typography
                        sx={{ fontSize: "14px" }}
                        variant="span"
                        color="error"
                      >
                        Correo requerido*
                      </Typography>
                    ) : (
                      ""
                    )}
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Contraseña"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      onChange={handleStatePass}
                      value={stateLogin.password}
                      onKeyDown={handleKeyDown}
                    />
                    {!stateLogin.password && stateLogin.sendFlag ? (
                      <Typography
                        sx={{ fontSize: "14px" }}
                        variant="span"
                        color="error"
                      >
                        Contraseña requerida*
                      </Typography>
                    ) : (
                      ""
                    )}
                    <div>
                      <LoadingButton
                        // type="submit"
                        onClick={() => {
                          validateLogin();
                        }}
                        variant="contained"
                        fullWidth
                        endIcon={<LoginIcon />}
                        loading={loadLogin}
                        sx={{
                          mt: 1,
                          mb: 2,
                          alignItems: "center",
                          backgroundColor: "#a70631",
                        }}
                      >
                        Acceder
                      </LoadingButton>
                    </div>
                    <Grid container>
                      <Grid item xs={12} md={12}>
                        <p
                          onClick={() => navigate("/restablecerContrasena")}
                          href="#"
                          variant="body2"
                          className="text-href-custom"
                        >
                          ¿Olvidaste tu contraseña? Da clic aquí
                        </p>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
