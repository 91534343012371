import { useState } from "react";
import Swal from "sweetalert2";
import useApi from "../../../services/useApi";
import { useNavigate, useParams } from "react-router-dom";
import imageLogin from "../../../assets/images/roundedConago.png";
import { LoaderComponent } from "../views/LoaderComponent";
import useAlerts from "../../../services/useAlerts";

const useForgotPassword = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const { services } = useApi();

  const { showAlertConfirm, showToast, showLoader } = useAlerts();

  const emailMask = "*{1,}@*{1,}.*{1,}";

  const [stateForgot, setStateForgot] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    sendMail: false,
  });

  const handleState = ({ target }) => {
    const { value, name } = target;
    setStateForgot({ ...stateForgot, [name]: value });
  };

  const handleStatePass = (e) => {
    setStateForgot({ ...stateForgot, password: e.target.value });
  };

  const handleStateConfirmPass = (e) => {
    setStateForgot({ ...stateForgot, confirmPassword: e.target.value });
  };

  const handleForget = () => {
    validarCorreo();
  };

  const [deshabilitar, setDeshabilitar] = useState(false);

  const [load, setLoad] = useState(false);

  const { parametro } = useParams();

  const validarCorreo = async () => {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const { email } = stateForgot;
    setDeshabilitar(true);

    if (email.trim() === "") {
      showAlertConfirm("info", "Información", "Por favor ingresa un correo.");
      setStateForgot({ ...stateForgot, sendMail: true });
      setLoad(true);
      setDeshabilitar(false);
    } else {
      const valido = email.match(regexEmail);

      if (!valido) {
        Swal.fire({
          icon: "info",
          title: "Información",
          text: "Ingresa un correo válido",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#a70631",
        }).then(() => {
          setDeshabilitar(false);
          setLoad(true);
        });
      } else {
        const formData = new FormData();
        formData.set("correo", email);
        setLoader(true);
        new Swal({
          html: `<img src="${imageLogin}" style="max-width: 10%;"><br><br><b>Procesando solicitud...</b>`,
          allowOutsideClick: false,
        });
        Swal.showLoading();
        const { execute, data } = await services(
          "POST",
          "auth/password-recovery",
          formData
        );

        if (execute) {
          Swal.close();
          setLoader(false);
          showAlertConfirm("success", "", data.mensaje);
          navigate("/");
        }
      }
    }
  };

  const validarRecovery = async () => {
    const { password, confirmPassword } = stateForgot;
    setDeshabilitar(true);

    if (password.trim() !== "" && confirmPassword.trim() !== "") {
      setLoad(true);
      if (password.length >= 8 && password.length <= 12) {
        if (password.trim() === confirmPassword.trim()) {
          showLoader();
          const { execute } = await services(
            "PUT",
            `auth/password-recovery/${parametro}`,
            { password }
          );

          if (execute) {
            showAlertConfirm(
              "success",
              "Operación exitosa",
              "Contraseña actualizada correctamente"
            );
            setLoader(false);
          } else {
            showAlertConfirm(
              "info",
              "",
              "Acción no realizada. Vuelve a solicitar correo de recuperación."
            );
          }

          navigate("/");
        } else {
          showAlertConfirm(
            "info",
            "Información",
            "Las contraseñas no coinciden."
          );
          setDeshabilitar(false);
          setLoad(false);
        }
      } else {
        Swal.fire({
          icon: "info",
          title: "Información",
          text: "La contraseña debe tener al menos 8 caracteres y no pasar de 12",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#a70631",
        }).then(() => {
          setDeshabilitar(false);
          setLoad(false);
        });
      }
    } else {
      showToast(
        "info",
        "Todos los campos son obligatorios",
        "bottom-end",
        1000
      );
    }
  };

  return {
    stateForgot,
    setStateForgot,
    navigate,
    handleState,
    handleForget,
    parametro,
    handleStatePass,
    handleStateConfirmPass,
    load,
    setLoad,
    validarRecovery,
    loader,
    setLoader,
    LoaderComponent,
    emailMask,
    deshabilitar,
  };
};

export default useForgotPassword;
